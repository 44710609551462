import React, {
    useCallback, useMemo, useState,
} from 'react';
import {LayoutContainer} from 'assets/theme/layout/LayoutContainer/LayoutContainer';
import {TimePeriodFormular} from 'applications/timebuddy/settings/forms/timeperiod/TimePeriodFormular';
import {
    generatePath,
    useNavigate,
    useParams,
} from 'react-router-dom';
import {useCurrentRoute} from 'routes';

import {createTimePeriod, updateTimePeriod} from 'graphql/timeBuddy/TimePeriod/mutations';
import {getTimePeriod} from 'graphql/timeBuddy/TimePeriod/queries';
import _ from 'lodash';
import {PermissionFormular} from 'applications/beyondbuddy/settings/forms/permissions/PermissionFormular';
import {createPermissionsObject} from 'applications/configs';
import {organizationEntityKeys} from 'applications/beyondbuddy/config';
import {readUpdateDeletePermissionTemplate} from 'components/Form/FormElements/FormElementEntityLinkPermissions';
import {
    BlurOnOutlined, LockOutlined,
} from '@mui/icons-material';
import {TabFormsContainer} from 'components/Form/TabFormsContainer';
import {Alert} from '@mui/material';
import {extendMoment} from 'moment-range';
import momentWithoutRange from 'moment';
import 'moment-timezone';

const tz = 'Europe/Vienna';
// @ts-ignore
const moment = extendMoment(momentWithoutRange);

const mask = {
    id: true,
    tenantId: true,
    startDate: true,
    endDate: true,
    open: false,
};

/**
 * @param {Partial<import('applications/configuration').EntityFormularProps>} props the properties
 * @returns {React.ReactElement} the timePeriod formular
 */
const getTimePeriodFormular = (props) => (
    <>
        <Alert severity="info">Abrechnungsperioden dürfen sich nicht überlagern</Alert>
        <TimePeriodFormular {...props} />
    </>
);

/**
 * @param {Partial<import('applications/configuration').EntityFormularProps>} props the properties
 * @returns {React.ReactElement} the permissions formular
 */
const getPermissionFormular = (props) => (
    <PermissionFormular
        disabled={props.readonly}
        isIncoming
        entityTypeId={`TimePeriod#${props.id}`}
        actionButtonProps={{portalAnchorSelector: `#TimePeriodForm${props.id}action-button-frame-permissions-actions`}}
        entityChooserProps={{
            guideId: '',
            availablePermissions: createPermissionsObject(organizationEntityKeys, ['read', 'updateGroup', 'permissionGroup']),
            defaultPermissions: {
                ...createPermissionsObject(organizationEntityKeys, ['read']),
                Tenant: ['read', 'permissionGroup'],
            },
            permissionDependencies: {
                User: readUpdateDeletePermissionTemplate,
                Group: readUpdateDeletePermissionTemplate,
                OrganizationalUnit: readUpdateDeletePermissionTemplate,
                Tenant: readUpdateDeletePermissionTemplate,
            },
        }}
        {...props}
    />
);

/**
 * This page shows a create and update form for a timePeriod.
 * Also other information as authorized customers, links and permissions are available.
 * @returns {React.ReactElement} The CemeteryPage component.
 */
function TimePeriodPage() {
    const {id} = useParams();
    const isNewItem = id === 'create';
    const navigate = useNavigate();
    const [currentTab, setCurrentTab] = useState(0);

    /** @type {import('routeinfo').RoutePathInfo} */
    const route = useCurrentRoute();

    const onSave = useCallback((result) => {
        if (id === 'create' && result?.id) {
            navigate(`/${generatePath(route.path, {id: result?.id})}`);
        }
    }, [id, route, navigate]);

    /** @type {import('components/Form/form').ItemLoadConfig} */
    const loadConfig = useMemo(() => ({
        query: getTimePeriod,
        variables: {direct: {id}},
        mask: {id: true},
    }), [id]);

    /** @type {import('components/Form/form').ItemSaveConfig} */
    const saveConfig = useMemo(() => ({
        variables: {global: {tenantId: 'tenantId'}},
        preProcess: (variables) => {
            const startDate = moment(variables.startDate).tz(tz).startOf('day').toISOString();
            const endDate = moment(variables.endDate).tz(tz).endOf('day').toISOString();
            return {...variables, startDate, endDate};
        },
        ...!isNewItem ? {
            mutation: updateTimePeriod,
            mask,
        } : {
            mutation: createTimePeriod,
            mask: _.omit(mask, 'id'),
        },
    }), [id]);

    // eslint-disable-next-line function-paren-newline
    const tabFormsContainerProps = useMemo(
        /** @returns {import('components/Form/form').TabFormsContainerProps} container properties */
        () => ({
            identifier: `TimePeriodForm${id}`,
            itemDataProps: {
                ...(!isNewItem) ? {loadConfig} : {},
                saveConfig,
            },
            tabs: [{
                id: 'base',
                label: 'Basisdaten',
                icon: <BlurOnOutlined />,
                getChildren: () => getTimePeriodFormular({id, onSave}),
                formWrapperId: `TimePeriod#${id}Base`,
            }, {
                id: 'permissions',
                label: 'Berechtigungen',
                icon: <LockOutlined />,
                enabledCondition: 'permissionsReadable',
                // id !== 'create' is important, instead it will be some error shown when switching from a list view (e.g. permissions tab) to create
                getChildren: (grants) => id !== 'create' && getPermissionFormular({id, readonly: !grants.permissionsUpdatable}),
                formWrapperId: `TimePeriod#${id}Permissions`,
                quickGuideId: 'beyondbuddy_general_permissions',
            },
            ],
            currentTab,
            setCurrentTab,
        }), [id, isNewItem, loadConfig, saveConfig, currentTab, setCurrentTab, onSave]);

    return (
        <LayoutContainer>
            <TabFormsContainer {...tabFormsContainerProps} />
        </LayoutContainer>
    );
}
export {TimePeriodPage};
