import React, {useMemo, useState} from 'react';
import _ from 'lodash';
import {FormWrapper} from 'components/Form/FormWrapper';
import {FormElementLoadingButton} from 'components/Form/FormElements/FormElementLoadingButton';
import {FormElementTextField} from 'components/Form/FormElements/FormElementTextField';
import {Grid} from '@mui/material';
import {ItemData} from 'components/Form/ItemData';
import {FormReset} from 'components/Form/FormReset';
import {schema as validatorSchema} from 'beyond-validators/beyondBuddy/Feedback';
import {createFeedback} from 'graphql/beyondBuddy/Feedback/mutations';
import {FormElementSwitch} from 'components/Form/FormElements/FormElementSwitch';
import {FormElementFilesUpload} from 'components/Form/FormElements/FormElementFilesUpload';
import {Send} from '@mui/icons-material';
import {FormElementAutocomplete} from 'components/Form/FormElements/FormElementAutocomplete';
import {Messages} from 'messages/Messages';

/**
 * @type {import('components/FilterElements/FilterElementAutocomplete').DataSchema}
 */
export const kindDataSchema = {
    dataKey: 'value',
    options: [{
        value: 'REQUEST',
        de: 'Wunsch',
    }, {
        value: 'ERROR',
        de: 'Fehler',
    }, {
        value: 'OTHER',
        de: 'Sonstiges',
    }],
    getOptionLabel: (option) => option.de,
    getOptionValue: (option) => option.value,
};

/** @type {import('components/Form/form').ItemSaveConfig} */
const saveConfig = {
    mutation: createFeedback,
    // variables: {global: {location: window.location.href}},
    mask: {
        anonymous: true,
        kind: true,
        message: true,
        attachmentKeys: false,
    },
    successMessage: Messages.API_SEND_SUCCESSFUL,
};

/**
 * The Feedback formular for creating a feedback
 * @param {import('applications/configuration').EntityFormularProps} props - props passed to the component
 * @returns {React.ReactElement} The FeedbackFormular component
 */
function FeedbackFormular({onSave, ...rest}) {
    // state for resetting the form
    const [tick, tack] = useState(false);

    const initialData = useMemo(() => ({kind: 'REQUEST', location: window.location.href}), []);

    return (
        <ItemData saveConfig={saveConfig} initialData={initialData}>
            <FormWrapper
                {...rest}
                isNewItem
                validatorSchema={{
                    schema: validatorSchema,
                    type: 'create',
                }}
                onSaveCallback={(result) => {
                    if (_.isFunction(onSave)) {
                        onSave(result);
                    }
                    tack(true);
                    setTimeout(() => tack(false));
                }}
                messageKey="Feedback_Create"
                noChangeTrack
            >
                <FormReset shouldClear={tick} />
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <FormElementSwitch
                            attribute="anonymous"
                            label="Anonym"
                            defaultTrue
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormElementAutocomplete
                            attribute="kind"
                            label="Art der Rückmeldung"
                            dataSchema={kindDataSchema}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormElementTextField
                            label="Feedback"
                            attribute="message"
                            rows={10}
                        />
                    </Grid>
                    <Grid item xs={23}>
                        <FormElementTextField
                            label="Aktuelle URL"
                            attribute="location"
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormElementFilesUpload />
                    </Grid>
                    <Grid item xs={12}>
                        <FormElementLoadingButton label="Senden" startIcon={<Send />} />
                    </Grid>
                </Grid>
            </FormWrapper>
        </ItemData>
    );
}

export {FeedbackFormular};
