/**
 * @type {import("./types").FileExtensionImage[]}
 */
const FILEEXTENSIONS_IMAGE = [
    // Common image formats
    'jpg', 'jpeg', 'png', 'gif', 'tiff',

    // Additional image formats
    'svg',
];
/**
 * @type {import("./types").FileExtensionDocument[]}
 */
const FILEEXTENSIONS_DOCUMENT = [
    // Word processing formats
    'pdf', 'doc', 'docx', 'odt', 'rtf', 'txt',

    // Spreadsheet formats
    'xls', 'xlsx', 'ods', 'csv',

    // Presentation formats
    'ppt', 'pptx', 'odp',

    // Mail formats
    'eml', 'msg',

    // Other document formats
    'epub', 'pages', 'tex', 'md', 'xml', 'json',
];

/**
 * @type {import("./types").CountryCodes[]}
 */
const COUNTRY_CODES = [
    'AT', 'DE', 'CH', 'IT', 'CZ', 'SK', 'HU', 'SI', 'LI', 'FR',
    'PL', 'DK', 'NL', 'BE', 'LU', 'GB', 'IE', 'ES', 'PT', 'SE',
    'NO', 'FI', 'EE', 'LV', 'LT', 'BY', 'RU', 'UA', 'GR', 'BG',
    'RO', 'AL', 'MK', 'ME', 'XK', 'RS', 'HR', 'BA', 'TR', 'CY',
    'MT', 'IS', 'GL', 'FO', 'US', 'CA', 'MX', 'BR', 'AR', 'CL',
    'AU', 'NZ', 'JP', 'KR', 'CN', 'IN', 'ZA', 'EG', 'NG', 'KE',
];

module.exports = {
    FILEEXTENSIONS_IMAGE, FILEEXTENSIONS_DOCUMENT, COUNTRY_CODES,
};
