import React from 'react';
import {FormContext, FormWrapper} from 'components/Form/FormWrapper';
import {FormElementLoadingButton} from 'components/Form/FormElements/FormElementLoadingButton';
import {FormElementTextField} from 'components/Form/FormElements/FormElementTextField';
import {FormElementImageContainer} from 'components/Form/FormElements/FormElementImageContainer';
import {
    Box, Grid,
} from '@mui/material';
import {schema as validatorSchema} from 'beyond-validators/driveBuddy/Vehicle';

import carImg from 'applications/drivebuddy/settings/pages/images/AdobeStock_453925539.jpg';
import {
    AddCircleOutline, Refresh,
} from '@mui/icons-material';
import {FormElementInfoChips} from 'components/Form/FormElements/FormElementInfoChips';
import {FormElementActionButton} from 'components/Form/FormElements/FormElementActionButton';
import {useCanAccess} from 'hooks/useCanAccess';
import {FormReset} from 'components/Form/FormReset';

/**
 * The VehicleFormular formular for creating and updating a vehicle
 * @param {import('applications/configuration').EntityFormularProps} props - props passed to the component
 * @returns {React.ReactElement} The VehicleFormular component.
 */
function VehicleFormular({id, onSave, ...rest}) {
    const isNewItem = id === 'create';
    const canCreateVehicles = useCanAccess('createVehicle');

    return (
        <FormWrapper
            {...rest}
            isNewItem={isNewItem}
            validatorSchema={{
                schema: validatorSchema,
                type: (id !== 'create') ? 'update' : 'create',
            }}
            onSaveCallback={onSave}
            messageKey={!isNewItem ? 'Vehicle_Update' : 'Vehicle_Create'}
            context={`Vehicle#${id}Base`}
        >
            <FormReset shouldClear={id === 'create'} keepInitial />
            <Box sx={{
                display: 'flex',
                gap: '1rem',
                flexWrap: 'wrap',
                justifyContent: 'center',
            }}
            >

                <FormElementActionButton
                    routeId="drivebuddy_drive_settings_vehicle_route"
                    routeParams={{id: 'create'}}
                    portalAnchorSelector={`#VehicleForm${id}action-button-frame-base-actions`}
                    formWrapperIdPattern={`Vehicle#${id}`}
                    disabled={isNewItem || !canCreateVehicles}
                    context={FormContext}
                >
                    <AddCircleOutline />
                </FormElementActionButton>
                <FormElementActionButton
                    reload
                    portalAnchorSelector={`#VehicleForm${id}action-button-frame-base-actions`}
                    formWrapperIdPattern={`Vehicle#${id}`}
                    disabled={isNewItem}
                    context={FormContext}
                >
                    <Refresh />
                </FormElementActionButton>
                <FormElementImageContainer defaultImg={carImg} alt="Vehicle" edit />
                <Box style={{flexGrow: 1, flexShrink: 1, flexBasis: '450px'}}>
                    <Grid container spacing={2}>
                        <FormElementInfoChips showReadonly />
                        <Grid item xs={12}>
                            <FormElementTextField label="Name" attribute="name" />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormElementTextField label="Hersteller" attribute="manufacturer" />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormElementTextField label="Modell" attribute="model" />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormElementTextField label="Kennzeichen" attribute="licensenumber" />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormElementTextField label="Fahrgestellnummer" attribute="vin" />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormElementLoadingButton label="Speichern" />
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </FormWrapper>
    );
}

export {VehicleFormular};
