import React, {
    useCallback, useContext, useLayoutEffect, useMemo,
} from 'react';
import {FormContext, FormWrapper} from 'components/Form/FormWrapper';
import {
    Box, Grid,
} from '@mui/material';
import {ItemData} from 'components/Form/ItemData';
import {FormElementLoadingButton} from 'components/Form/FormElements/FormElementLoadingButton';

import {FormElementInfoChips} from 'components/Form/FormElements/FormElementInfoChips';
import {FormElementActionButton} from 'components/Form/FormElements/FormElementActionButton';
import {Refresh} from '@mui/icons-material';
import {getNotification} from 'graphql/beyondBuddy/Notification/queries';
import {AWSAppSyncProvider} from 'helper/bb-graphql-provider';
import {viewNotification} from 'graphql/beyondBuddy/Notification/mutations';
import {FormElementContainer} from 'components/Form/FormElements/FormElementContainer';
import {reloadNotificationLists} from 'helper/useNotificationList';
import {marked} from 'marked';
import {sanitize} from 'dompurify';
import {useNavigate} from 'react-router-dom';

const markedOptions = {
    headerIds: false,
    mangle: false,
};

/**
 * Displays the message of a notification. Loads viable links via react router
 * @returns {React.ReactElement} display
 */
function MessageDisplay() {
    const {get} = useContext(FormContext);
    const {value} = get('message');
    const htmlData = {
        __html: (value && sanitize(marked(value, markedOptions))) ?? '',
    };
    const navigate = useNavigate();

    useLayoutEffect(() => {
        /** @type {NodeListOf<HTMLAnchorElement>}  */
        const anchors = document.querySelectorAll('[data-message] a[href*=".beyondbuddy.at"]');
        const listenerRemovers = Array.from(anchors).map((a) => {
            const url = new URL(a.href);
            if (url.host !== window.location.host) {
                url.host = window.location.host;
                url.protocol = window.location.protocol;
                a.setAttribute('href', url.toString());
            }

            const handler = (evt) => {
                evt.preventDefault();
                navigate(url.pathname);
            };
            a.addEventListener('click', handler);
            return () => {
                a.removeEventListener('click', handler);
            };
        });
        return () => listenerRemovers.forEach((fn) => fn());
    }, [value, navigate]);

    return (
        <Box
            data-message={value}
            dangerouslySetInnerHTML={htmlData}
            sx={{
                '& p': {marginTop: '1rem'},
                a: {
                    color: '#e99156',
                    textDecoration: 'none',
                    textDecorationColor: '#e99156',
                },
            }}
        />
    );
}

/**
 * The notification formular for viewing a notification
 * @param {import('applications/configuration').EntityFormularProps} props - props passed to the component
 * @returns {React.ReactElement} The NotificationFormular component.
 */
function NotificationFormular({id, onSave, ...rest}) {
    const loadConfig = useMemo(() => ({
        query: getNotification,
        variables: {direct: {id}},
        mask: {id: true},
    }), [id]);

    const {editItem} = AWSAppSyncProvider();

    const processChange = useCallback(async (data) => {
        if (!data.viewed) {
            await editItem(viewNotification, {id}).then(() => {
                reloadNotificationLists();
            });
        }
    }, [id]);

    return (
        <ItemData onChange={processChange} loadConfig={loadConfig}>
            <FormWrapper
                {...rest}
                readonly
                messageKey="Notification"
                context={`Notification${id}`}
            >
                <Box style={{
                    display: 'flex',
                    gap: '1rem',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                }}
                >
                    <FormElementActionButton
                        reload
                        portalAnchorSelector="#action-button-frame"
                        context={FormContext}
                    >
                        <Refresh />
                    </FormElementActionButton>
                    <Box style={{flexGrow: 1, flexShrink: 1, flexBasis: '450px'}}>
                        <Grid container spacing={2}>
                            <FormElementInfoChips showReadonly />

                            <FormElementContainer
                                attribute="subject"
                                conditionalRender={({value}) => Boolean(value)}
                            >
                                <Grid item xs={12}>
                                    <FormElementContainer
                                        attribute="subject"
                                        propsMapping={({value, isLoading}) => (isLoading.load ? {} : {children: value})}
                                    >
                                        <h3 data-test="Subject"> </h3>
                                    </FormElementContainer>
                                </Grid>
                            </FormElementContainer>
                            <Grid item xs={12}>
                                <MessageDisplay />
                            </Grid>
                            <Grid item xs={12}>
                                <FormElementLoadingButton />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </FormWrapper>
        </ItemData>
    );
}

export {NotificationFormular};
