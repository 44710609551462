import _ from 'lodash';
import React, {useMemo} from 'react';
import {FormContext, FormWrapper} from 'components/Form/FormWrapper';
import {FormElementTextField} from 'components/Form/FormElements/FormElementTextField';
import {FormElementLoadingButton} from 'components/Form/FormElements/FormElementLoadingButton';
import {
    Alert,
    Box, Grid, IconButton, Link, Typography,
} from '@mui/material';
import {ItemData} from 'components/Form/ItemData';
import {createTenant, updateTenant} from 'graphql/beyondBuddy/Tenant/mutations';
import {getTenant} from 'graphql/beyondBuddy/Tenant/queries';
import {FormElementCheckbox} from 'components/Form/FormElements/FormElementCheckbox';
import {FormElementInfoChips} from 'components/Form/FormElements/FormElementInfoChips';
import {FormElementActionButton} from 'components/Form/FormElements/FormElementActionButton';
import {CopyAllOutlined, Refresh} from '@mui/icons-material';
import {FormElementContainer} from 'components/Form/FormElements/FormElementContainer';
import {useClipboard} from 'hooks/useClipboard';
import tenantImg from 'applications/beyondbuddy/settings/pages/images/AdobeStock_104972785.jpg';
import {FormElementImageContainer} from 'components/Form/FormElements/FormElementImageContainer';
import {FormElementText} from 'components/Form/FormElements/FormElementText';
import {useGlobalState} from 'hooks/useGlobalState';
import {FormReset} from 'components/Form/FormReset';

const {schema: validatorSchema} = require('beyond-validators/beyondBuddy/Tenant');

const postProcess = (tenant) => {
    if (tenant && 'identityProviders' in tenant && tenant.identityProviders) {
        return {
            ...tenant,
            samlMetadataUrl: _.get(tenant, 'identityProviders.0.metadataURL'),
        };
    }
    return tenant;
};

/**
 * The tenant Formular for creating and updating a tenant
 * @param {import('applications/configuration').EntityFormularProps} props - props passed to the component
 * @returns {React.ReactElement} The TenantFormular component.
 */
function TenantFormular({id, onSave, ...rest}) {
    const {copyToClipboard} = useClipboard();

    const {setGlobal} = useGlobalState();

    /** @type {import('components/Form/form').ItemSaveConfig} */
    const saveConfig = useMemo(() => ({
        ...(id !== 'create') ? {
            mutation: updateTenant,
            mask: {
                id: true,
                name: false,
                contactFirstName: true,
                contactLastName: false,
                contactEMailAddress: true,
                companyName: false,
                imageKey: false,
                identityProviders: false,
                companyRegisterNumber: false,
                companyTaxIdentificationId: false,
                technicalContactEMailAddress: false,
            },
            postProcess,
            preProcess: (tenant) => {
                if ('samlMetadataUrl' in tenant) {
                    return {
                        ...tenant,
                        identityProviders: tenant.samlMetadataUrl
                            ? [{type: 'saml', metadataURL: tenant.samlMetadataUrl}]
                            : [],
                    };
                }
                return tenant;
            },
        } : {
            mutation: createTenant,
            mask: {
                contactFirstName: true,
                contactLastName: false,
                companyName: false,
                contactEMailAddress: true,
            },
        },
    }), [id]);

    /** @type {import('components/Form/form').ItemLoadConfig} */
    const loadConfig = useMemo(() => ({
        query: getTenant,
        variables: {direct: {id}},
        mask: {id: true},
        postProcess,
    }), [id]);

    const AGBLabel = useMemo(() => (
        <>
            Ich habe die
            {' '}
            <Link href="https://www.beyondbuddy.at/allgemeine-geschaeftsbedingungen" target="_blank" rel="noreferrer" data-test="FormElementCheckbox_tosAccepted_link">AGB</Link>
            {' '}
            gelesen und akzeptiere diese
        </>
    ), []);

    const getTenantURL = (subDomain) => _.join([
        window.location.protocol,
        '//',
        subDomain ? `${subDomain}.` : '',
        window.location.host === 'localhost:3000'
            ? 'localhost:3000'
            : window.location.host.split('.').slice(1).join('.'),
    ], '');

    return (
        <ItemData {...(id !== 'create') ? {loadConfig} : {}} saveConfig={saveConfig}>
            <FormWrapper
                {...rest}
                isNewItem={id === 'create'}
                validatorSchema={{
                    schema: validatorSchema,
                    type: (id !== 'create') ? 'update' : 'create',
                }}
                onSaveCallback={(result) => {
                    setGlobal('authProviders', result?.samlMetadataUrl ? ['saml'] : [], true);
                    if (_.isFunction(onSave)) {
                        onSave(result);
                    }
                }}
                messageKey={(id !== 'create') ? 'Tenant_Update' : 'Tenant_Create'}
                context={`Tenant${id}`}
            >
                <FormReset shouldClear={id === 'create'} />
                <Box style={{
                    display: 'flex',
                    gap: '1rem',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                }}
                >
                    {id !== 'create' && <FormElementImageContainer defaultImg={tenantImg} alt="Tenant" edit />}
                    <Box style={{flexGrow: 1, flexShrink: 1, flexBasis: '450px'}}>
                        <Grid container spacing={2}>
                            { id !== 'create' ? (
                                <>
                                    <FormElementActionButton
                                        reload
                                        portalAnchorSelector="#action-button-frame"
                                        context={FormContext}
                                    >
                                        <Refresh />
                                    </FormElementActionButton>
                                    <FormElementInfoChips showReadonly />
                                    <Grid item xs={12} sm={6}>
                                        <FormElementTextField label="Vorname" attribute="contactFirstName" />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormElementTextField label="Familienname" attribute="contactLastName" />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormElementTextField label="Email" attribute="contactEMailAddress" />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormElementTextField label="Firmen Name" attribute="companyName" />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormElementTextField label="Firmenbuchnummer" attribute="companyRegisterNumber" />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormElementTextField label="Umsatzsteuer-IdNr." attribute="companyTaxIdentificationId" />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormElementTextField label="Email des Technischen Kontaktes" attribute="technicalContactEMailAddress" />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <hr />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormElementTextField label="Kurzbezeichnung" attribute="name" />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Box style={{display: 'flex', alignItems: 'center', gap: '0.5rem'}}>
                                            <Typography>Deine URL lautet</Typography>
                                            <Typography color="info.main"><FormElementText valueMapping={(get) => getTenantURL(get('name')?.value)} /></Typography>
                                            <FormElementContainer
                                                attribute="name"
                                                propsMapping={(att) => ({onClick: () => copyToClipboard(getTenantURL(att.value), true)})}
                                            >
                                                <IconButton><CopyAllOutlined /></IconButton>
                                            </FormElementContainer>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <hr />
                                        <Typography variant="h2" marginTop="1rem">SAML Authentication</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box style={{display: 'flex', alignItems: 'center', gap: '1rem'}}>
                                            <Typography>Identifier (Entity ID):</Typography>
                                            <Typography color="info.main">
                                                urn:amazon:cognito:sp:
                                                <FormElementText attribute="userPoolId" />
                                            </Typography>
                                            <FormElementContainer
                                                attribute="userPoolId"
                                                propsMapping={(att) => ({onClick: () => copyToClipboard(`urn:amazon:cognito:sp:${att.value}`, true)})}
                                            >
                                                <IconButton><CopyAllOutlined /></IconButton>
                                            </FormElementContainer>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box style={{display: 'flex', alignItems: 'center', gap: '1rem'}}>
                                            <Typography>Reply URL:</Typography>
                                            <Typography color="info.main">
                                                https://
                                                <FormElementText attribute="userPoolDomain" />
                                                /saml2/idpresponse

                                            </Typography>
                                            <FormElementContainer
                                                attribute="userPoolDomain"
                                                propsMapping={(att) => ({onClick: () => copyToClipboard(`https://${att.value}/saml2/idpresponse`, true)})}
                                            >
                                                <IconButton><CopyAllOutlined /></IconButton>
                                            </FormElementContainer>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormElementTextField label="SAML Metadata URL" attribute="samlMetadataUrl" />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormElementContainer
                                            attribute="samlMetadataUrl"
                                            conditionalRender={(att) => !att.value}
                                        >
                                            <Alert severity="info">
                                                SAML SSO wurde noch nicht aktiviert! Nach dem Eintragen der SAML Metadata URL dauert es einige Minuten bis der Service aktiviert wurde.
                                            </Alert>
                                        </FormElementContainer>
                                    </Grid>
                                </>
                            ) : (
                                <>
                                    <Grid item xs={12} sm={6}>
                                        <FormElementTextField label="Vorname" attribute="contactFirstName" />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormElementTextField label="Familienname" attribute="contactLastName" />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormElementTextField label="Firmen Name" attribute="companyName" />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormElementTextField label="Email" attribute="contactEMailAddress" />
                                    </Grid>
                                    <Grid item xs={12} style={{display: 'flex', alignItems: 'center'}}>
                                        <FormElementCheckbox label={AGBLabel} attribute="tosAccepted" />
                                    </Grid>
                                </>
                            )}
                            <Grid item xs={12}>
                                <FormElementLoadingButton
                                    label={(id !== 'create') ? 'Speichern' : 'Registrieren'}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </FormWrapper>
        </ItemData>
    );
}

export {TenantFormular};
