/* eslint-disable no-useless-escape */

const UUID_REGEX_PATTERN = '[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}';
const UUID_REGEX = new RegExp(UUID_REGEX_PATTERN);
const ISO8601_REGEX = /^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}(.[0-9]+)?([zZ]|([+-])([01]\d|2[0-3]):?([0-5]\d)?)$/iu;

// eslint-disable-next-line max-len, no-control-regex
const EMAIL_REGEX = /^[\w\-+\.]+@([\w\-]+\.)+[a-z\-]{2,4}$/i;
const NAME_REGEX = /^\D[^!"§$%()=?#*@~π•…∞¡¶¢\[\]|\{\}≠«∑€®†Ω¨ø∂©ªº∆¥≈ç√∫~µ]*$/;
const GENDER_REGEX = /^MALE|FEMALE|DIVERSE$/;

// eslint-disable-next-line max-len
const EUROPEAN_VAT_REGEX = /^((AT)?U[0-9]{8}|(BE)?0[0-9]{9}|(BG)?[0-9]{9,10}|(CY)?[0-9]{8}L|(CZ)?[0-9]{8,10}|(DE)?[0-9]{9}|(DK)?[0-9]{8}|(EE)?[0-9]{9}|(EL|GR)?[0-9]{9}|(ES)?[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)?[0-9]{8}|(FR)?[0-9A-Z]{2}[0-9]{9}|(GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})|(HU)?[0-9]{8}|(IE)?[0-9]S[0-9]{5}L|(IT)?[0-9]{11}|(LT)?([0-9]{9}|[0-9]{12})|(LU)?[0-9]{8}|(LV)?[0-9]{11}|(MT)?[0-9]{8}|(NL)?[0-9]{9}B[0-9]{2}|(PL)?[0-9]{10}|(PT)?[0-9]{9}|(RO)?[0-9]{2,10}|(SE)?[0-9]{12}|(SI)?[0-9]{8}|(SK)?[0-9]{10})$/;

// includes a tenantId direct without import -> cycle import
const IMAGE_KEY_REGEX_PATTERN = `${UUID_REGEX_PATTERN}\/${UUID_REGEX_PATTERN}\/.*\.[a-zA-Z0-9]{2,5}`;
const FILE_KEY_REGEX = new RegExp(IMAGE_KEY_REGEX_PATTERN);

// eslint-disable-next-line max-len
const COUNTRY_CODE = /^(AT|DE|CH|IT|CZ|SK|HU|SI|LI|FR|PL|DK|NL|BE|LU|GB|IE|ES|PT|SE|NO|FI|EE|LV|LT|BY|RU|UA|GR|BG|RO|AL|MK|ME|XK|RS|HR|BA|TR|CY|MT|IS|GL|FO|US|CA|MX|BR|AR|CL|AU|NZ|JP|KR|CN|IN|ZA|EG|NG|KE)$/;

module.exports = {
    EMAIL_REGEX, NAME_REGEX, UUID_REGEX, UUID_REGEX_PATTERN, ISO8601_REGEX, FILE_KEY_REGEX, IMAGE_KEY_REGEX_PATTERN, EUROPEAN_VAT_REGEX, GENDER_REGEX, COUNTRY_CODE,
};
