import React, {
    forwardRef,
    useCallback, useImperativeHandle, useMemo, useState,
} from 'react';
import _ from 'lodash';
import {Exceptions} from 'messages/Exceptions';
import {useMessage} from 'hooks/useMessage';
import {AWSAppSyncProvider, ConnectionClosedException} from 'helper/bb-graphql-provider';
import {Messages} from 'messages/Messages';
import {useVariables} from 'hooks/useVariables';
import {CancelException} from 'hooks/useCancellablePromise';
import {maskVariables} from 'helper/mask-variables';

/**
 * @type {React.Context<import('./form').TListDataContext>}
 */
const ListDataContext = React.createContext(Object());

/**
 * ## LoadingVariables for ListLoadConfig
 * Variables that may be passed as part of ListLoadConfig.
 *
 * Parent variables can only be used in the non top level load config
 * @typedef {object} LoadingVariables
 * @property {Record<string, string>=} global variables that are loaded from global state
 * @property {Record<string, any>=} direct variables that are passed directly
 * @property {Record<string, string|object>=} parent variables that are extracted from parent loadConfig variables
 * These parent variables will be extracted from the parent as a list. Values can be any valid inputs to `_.map`
 */

/**
 * ## List Load configuration
 *
 * LoadConfigs in postLoads will be executed concurrently, and can be internally chained.
 * They have access to the parent loads variables. The data loaded by them will directly
 * be placed at the name the LoadConfig is listed in
 *
 * Potential postProcess functions will be executed once all other postLoad configs
 * have loaded
 * @typedef {object} ListLoadConfig
 * @property {string} query Query to be executed
 * @property {LoadingVariables=} variables variables that are passed to the query
 * @property {Record<string, boolean>} mask Mask for variables to the query
 * @property {Record<string, ListLoadConfig>=} postLoads post load config(s). Variables in
 * these configs may use parent type variables
 * @property {function(Array): object=} postProcess function that will run after all data has loaded
 * @property {function(object): object=} preProcess function that will process the
 * data before the delete will occur
 */

/**
 * Recursively loads list data according to provided configs
 * @param {ListLoadConfig} config Load configuration to start loading
 * @param {function(string, object): Promise<Array>} queryFn function to query for items
 * @param {function(object): object} getVariables function to resolve global/direct variables
 * @param {Array} parentObjects list of parent objects that are ready to be processed
 * @returns {Promise<Array>} processed lists
 */
const chainListLoad = async (config, queryFn, getVariables, parentObjects) => {
    const parentVariables = _.mapValues(config.variables?.parent, (name) => _.map(parentObjects, name));

    const {variables, missingObligatoryVariables} = maskVariables({
        ...getVariables(config.variables),
        ...parentVariables,
    }, config.mask);

    if (!_.isEmpty(missingObligatoryVariables)) {
        // eslint-disable-next-line no-console
        console.error('Missing obligatory variables in ', config);
        // eslint-disable-next-line no-console
        console.table(missingObligatoryVariables);
        throw Exceptions.MISSING_ATTRIBUTES_ERROR;
    }
    let results;
    try {
        results = await queryFn(config.query, variables);
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        throw Exceptions.API_LOAD_ERROR;
    }

    const loadResults = _.fromPairs(
        await Promise.all(
            _.map(config.postLoads, async (value, attribute) => {
                const loadResult = await chainListLoad(value, queryFn, getVariables, results);
                return [attribute, loadResult];
            }),
        ),
    );
    // We presume that the number of items in all of the collected results is the same
    results = _.map(
        results,
        (attributes, index) => ({
            ...attributes,
            ..._.mapValues(
                loadResults,
                (list) => _.at(list, index),
            ),
        }),
    );
    if (_.isFunction(config.postProcess)) {
        return config.postProcess(results);
    }
    return results;
};

/**
 * Executes a provided asynchronous function with a retry mechanism.
 * Retries the function a specified number of times in case of a `ConnectionClosedException`,
 * waiting for a specified delay between each retry.
 * @async
 * @function executeWithRetry
 * @param {Function} fn - The asynchronous function to execute. It should return a Promise.
 * @param {number} retries - The number of times to retry the function if it fails with a `ConnectionClosedException`.
 * @param {number} delay - The delay in milliseconds between each retry attempt.
 * @returns {Promise<any>} - The result of the asynchronous function if successful.
 * @throws {Error} - Throws the error if all retry attempts are exhausted or if the error is not a `ConnectionClosedException`.
 */
const executeWithRetry = async (fn, retries, delay) => {
    for (let attempt = 0; attempt < retries; attempt += 1) {
        try {
            return fn();
        } catch (error) {
            if (error instanceof ConnectionClosedException) {
                if (attempt < retries - 1) {
                    // Wait before retrying
                    // eslint-disable-next-line no-await-in-loop
                    await new Promise((resolve) => { setTimeout(resolve, delay); });
                } else {
                    throw error; // Exhausted retries, throw the error
                }
            } else {
                throw error; // Non-retriable error, throw immediately
            }
        }
    }
    return fn();
};

/**
 * ## Delete Configuration
 *
 * Config object that holds all required information to delete single items out of
 * a listing.
 *
 * The mask will be applied to the item in question, not to the whole list of available data
 * @typedef {object} DeleteConfig
 * @property {string} mutation Mutation that will delete an item
 * @property {Record<string, boolean>} mask Mask for variables to the mutation
 * @property {Omit<LoadingVariables, "parent">=} variables instructions on which global and direct
 * variables will be passed to the mutation
 * @property {function(object): object=} preProcess function that will process the
 * data before the delete will occur
 */

const ListData = forwardRef(
    /**
     * ## ListData
     *
     * A context to provide loading behavior for listings, as well as deletion behavior
     * for items that are within the list
     * @param {object} props - props to configure the ListDataContext with
     * @param {React.ReactNode} [props.children] - children to be displayed within the context
     * @param {ListLoadConfig} [props.loadConfig] - load configuration that the list will use to load data
     * @param {DeleteConfig} [props.deleteConfig] - delete configuration that will be used to delete items
     * @param {object} ref - reference to set the reload function to
     * @returns {React.ReactElement} - react element that may be rendered
     */
    ({
        children, loadConfig, deleteConfig,
    }, ref) => {
        const {getVariables} = useVariables(); // get the function from the hook
        const {
            call, listItems, editItem,
        } = AWSAppSyncProvider();
        const {enqueueMessage} = useMessage();

        const [continuationVariables, setContinuationVariables] = useState(Object);
        const [data, setData] = useState([]);
        const [isLoading, setIsLoading] = useState(false);
        const [lastCall, setLastCall] = useState({messageKey: undefined, variables: undefined});

        /**
         * Loads data and may display warning snackbars if problems occur.
         *
         * The return value indicates that everything went as expected (true), or that
         * there were problems (false)
         * @param {object} options the options passed to the load operation
         * @param {string} options.messageKey a message key to identify messages
         * @param {object=} options.variables a set of variables that should be sent
         * as part of the query
         */
        const load = useCallback(async ({messageKey, variables}) => {
            /**
             * Indicator that the call to load is not continuing a previous listing
             */
            const isNewListing = !_.has(variables, 'nextToken');

            const {variables: maskedVariables, missingObligatoryVariables} = maskVariables(_.merge(
                variables,
                getVariables(loadConfig.variables),
            ), loadConfig.mask);

            const processed = await loadConfig?.preProcess?.(maskedVariables) ?? maskedVariables;

            if (!_.isEmpty(missingObligatoryVariables)) {
            // eslint-disable-next-line no-console
                console.warn('Missing obligatory variables: ', missingObligatoryVariables);
                enqueueMessage(messageKey, Exceptions.MISSING_ATTRIBUTES_ERROR);
                return;
            }
            if (!isNewListing) {
                processed.nextToken = variables.nextToken;
            }
            setIsLoading(true);
            setLastCall((current) => {
                if (_.isEqual(current, {messageKey, variables})) {
                    return current;
                }
                return {messageKey, variables};
            });

            executeWithRetry(
                () => call(loadConfig.query, processed),
                3, // Number of retries
                30, // Delay in milliseconds
            ).then(async (result) => {
                const {
                    nextToken,
                    items,
                } = _.chain(result?.data).values().first().value();

                const chainLoadResults = _.fromPairs(
                    await Promise.all(_.map(loadConfig.postLoads, async (config, attribute) => {
                        const results = await chainListLoad(config, listItems, getVariables, items);
                        return [attribute, results];
                    })),
                );
                const postChainItems = _.map(items, (item, index) => ({
                    ...item,
                    ..._.mapValues(chainLoadResults, (list) => _.at(list, index)),
                }));

                const processedItems = loadConfig.postProcess?.(postChainItems) ?? postChainItems;
                if (isNewListing) {
                    setData(processedItems);
                } else {
                    setData((oldData) => _.concat(oldData, processedItems));
                }
                setContinuationVariables({
                    ...variables,
                    nextToken,
                });
            }).catch((error) => {
                if (_.includes(Exceptions, error)) {
                    enqueueMessage(messageKey, error);
                } else if (error instanceof ConnectionClosedException) {
                    // eslint-disable-next-line no-console
                    console.error('Problem during list load', error);
                    enqueueMessage(messageKey, Exceptions.API_CONNECTION_ERROR, {error, processed});
                } else if (!(error instanceof CancelException)) {
                    // eslint-disable-next-line no-console
                    console.error('Problem during list load', error);
                    enqueueMessage(messageKey, Exceptions.API_LOAD_ERROR, {error, processed});
                }
            }).finally(() => {
                setIsLoading(false);
            });
        }, [loadConfig, setIsLoading, enqueueMessage, call, listItems, getVariables, setContinuationVariables]);

        /**
         * An indicator that there are more items to be loaded
         */
        const hasMore = useMemo(
            () => _.isString(continuationVariables?.nextToken),
            [continuationVariables?.nextToken],
        );

        /**
         * Executes the last loading call again
         */
        const reload = useCallback(() => load(lastCall), [lastCall, load]);
        useImperativeHandle(ref, () => ({reload}), [reload]);

        /**
         * Function that will load more items by continuing the last listing,
         * if possible.
         * @param {object} options options that are forwarded to load
         * @param {string} messageKey key to identify messages by
         */
        const loadMore = useCallback(({messageKey}) => {
            if (!hasMore) {
                return false;
            }
            return load({
                variables: continuationVariables,
                messageKey,
            });
        }, [hasMore, load, continuationVariables]);

        /**
         * Deletion function, that may take an index or a direct item
         * @param {object} options options that are relevant for deletions
         * @param {string} options.messageKey key to identify the messages by
         * @param {number=} options.index optional index of the item to be deleted
         * @param {object=} options.item item to be removed
         * @returns {Promise<boolean>} true if the deletion was successful
         */
        const deleteItem = useCallback(async ({index, item, messageKey}) => {
            if (_.isEmpty(deleteConfig)) {
                return false;
            }
            const toBeDeletedItem = item ?? _.at(data, index);
            if (_.isEmpty(toBeDeletedItem)) {
            // eslint-disable-next-line no-console
                console.warn('Deletion was given no item and no index');
                return false;
            }

            const processed = await deleteConfig.preProcess?.(toBeDeletedItem) ?? toBeDeletedItem;

            const {variables, missingObligatoryVariables} = maskVariables({
                ...processed,
                ...getVariables(deleteConfig.variables),
            }, deleteConfig.mask);

            if (!_.isEmpty(missingObligatoryVariables)) {
            // eslint-disable-next-line no-console
                console.error('Missing obligatory variables: ', missingObligatoryVariables);
                enqueueMessage(messageKey, Exceptions.MISSING_ATTRIBUTES_ERROR);
                return false;
            }
            try {
            // find the item to be deleted and add inDeletion to it
                setData((oldData) => _.map(oldData, (oldItem) => (oldItem === toBeDeletedItem ? {...oldItem, inDeletion: true} : oldItem)));
                // call the delete mutation
                await editItem(deleteConfig.mutation, variables);
                // display a success message
                enqueueMessage(messageKey, Messages.API_DELETE_SUCCESSFUL);
                // remove the item from the list
                setData((oldData) => _.filter(oldData, (oldItem) => !_.isEqual(oldItem, {...oldItem, inDeletion: true})));
                return true;
            } catch (error) {
                if (error instanceof ConnectionClosedException) {
                    // eslint-disable-next-line no-console
                    console.error('Problem during list load', error);
                    enqueueMessage(messageKey, Exceptions.API_CONNECTION_ERROR, {error, toBeDeletedItem});
                } else if (!(error instanceof CancelException)) {
                    enqueueMessage(messageKey, Exceptions.API_DELETE_ERROR, {toBeDeletedItem});
                }
                // remove inDeletion from the item that was being deleted
                setData((oldData) => _.map(oldData, (oldItem) => (oldItem.inDeletion ? _.omit(toBeDeletedItem, 'inDeletion') : oldItem)));
                return false;
            }
        }, [deleteConfig, enqueueMessage, setData, getVariables]);

        /**
         * Function to append an item to the listData.
         * TODO: Decide if this item should go through the same post processing steps
         * @param {object} item item to be appended to the listData
         */
        const addItem = useCallback((item) => {
            setData((oldData) => {
                if (_.includes(oldData, item)) {
                    return oldData;
                }
                return [...oldData, item];
            });
        }, []);

        const value = useMemo(() => ({
            load,
            reload,
            loadMore,
            data,
            hasMore,
            isLoading,
            deleteItem,
            addItem,
        }), [load, reload, loadMore, isLoading, data, hasMore, deleteItem, addItem]);

        return (
            <ListDataContext.Provider value={value}>
                {children}
            </ListDataContext.Provider>
        );
    },
);
ListData.displayName = 'ListData';

export {ListData, ListDataContext};
