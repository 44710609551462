const {FILEEXTENSIONS_IMAGE, FILEEXTENSIONS_DOCUMENT} = require('../constants');
const {
    UUID_REGEX,
    FILE_KEY_REGEX,
    GENDER_REGEX,
    NAME_REGEX,
    ISO8601_REGEX,
    COUNTRY_CODE,
} = require('../regex');

/**
 * @type {import('beyond-validators').ValidationSchema<import('../../cloud/lib/services/lambda/_layer/BLLLayer/BusinessLogicLayer/app/peaceBuddy/types').DeceasedPersonEntity>}
 */
const schema = {
    frontend: {
        gender: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['type', 'format', 'required'],
            },
        ],
        titlePrefixed: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['type', 'length'],
            },
        ],
        firstName: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['type', 'length', 'format', 'required'],
            },
        ],
        lastName: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['type', 'length', 'format', 'required'],
            },
        ],
        titlePostfixed: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['type', 'length'],
            },
        ],
        nationalityCountryCode: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['type', 'required', 'format'],
            },
        ],
        dateOfBirth: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['required', 'type', 'format'],
            },
        ],
        placeOfBirth: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['type'],
            },
        ],
        placeOfBirthCountryCode: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['type', 'format'],
            },
        ],
        dateOfDeath: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['required', 'type', 'format'],
            },
        ],
        placeOfDeath: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['type'],
            },
        ],
        placeOfDeathCountryCode: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['type', 'format'],
            },
        ],
        stillbirth: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['type'],
            },
        ],
        cremation: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['type'],
            },
        ],
        ashCapsuleNr: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['type', 'dependencyRequired'],
            },
        ],
        homeStorage: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['type'],
            },
        ],
        ashCapsuleLocation: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['type', 'dependencyRequired'],
            },
        ],
    },
    backend: {
        id: [
            {
                actions: ['update'],
                configurations: ['required', 'format'],
            },
        ],
        gender: [
            {
                actions: ['create', 'update'],
                configurations: ['type', 'format', 'required'],
            },
        ],
        titlePrefixed: [
            {
                actions: ['create', 'update'],
                configurations: ['type', 'length'],
            },
        ],
        firstName: [
            {
                actions: ['create', 'update'],
                configurations: ['type', 'length', 'format', 'required'],
            },
        ],
        lastName: [
            {
                actions: ['create', 'update'],
                configurations: ['type', 'length', 'format', 'required'],
            },
        ],
        titlePostfixed: [
            {
                actions: ['create', 'update'],
                configurations: ['type', 'length'],
            },
        ],
        nationalityCountryCode: [
            {
                actions: ['create', 'update'],
                configurations: ['type', 'required', 'format'],
            },
        ],
        dateOfBirth: [
            {
                actions: ['create', 'update'],
                configurations: ['required', 'type', 'format'],
            },
        ],
        placeOfBirth: [
            {
                actions: ['create', 'update'],
                configurations: ['type'],
            },
        ],
        placeOfBirthCountryCode: [
            {
                actions: ['create', 'update'],
                configurations: ['type', 'format'],
            },
        ],
        dateOfDeath: [
            {
                actions: ['create', 'update'],
                configurations: ['required', 'type', 'format'],
            },
        ],
        placeOfDeath: [
            {
                actions: ['create', 'update'],
                configurations: ['type'],
            },
        ],
        placeOfDeathCountryCode: [
            {
                actions: ['create', 'update'],
                configurations: ['type', 'format'],
            },
        ],
        stillbirth: [
            {
                actions: ['create', 'update'],
                configurations: ['type'],
            },
        ],
        cremation: [
            {
                actions: ['create', 'update'],
                configurations: ['type'],
            },
        ],
        ashCapsuleNr: [
            {
                actions: ['create', 'update'],
                configurations: ['type', 'dependencyRequired'],
            },
        ],
        homeStorage: [
            {
                actions: ['create', 'update'],
                configurations: ['type'],
            },
        ],
        ashCapsuleLocation: [
            {
                actions: ['create', 'update'],
                configurations: ['type', 'dependencyRequired'],
            },
        ],
        attachmentKeys: [
            {
                actions: ['create', 'update'],
                configurations: ['type', 'format', 'fileExtensions'],
            },
        ],
    },

    attributes: {
        id: {
            format: UUID_REGEX,
            required: true,
        },
        gender: {
            type: 'String',
            format: GENDER_REGEX,
            required: true,
        },
        titlePrefixed: {
            type: 'String',
            length: {lte: 50},
        },
        firstName: {
            type: 'String',
            length: {lte: 50},
            format: NAME_REGEX,
            required: true,
        },
        lastName: {
            type: 'String',
            length: {lte: 50},
            format: NAME_REGEX,
            required: true,
        },
        titlePostfixed: {
            type: 'String',
            length: {lte: 50},
        },
        nationalityCountryCode: {
            type: 'String',
            required: true,
            format: COUNTRY_CODE,
        },
        dateOfBirth: {
            type: 'DateTime',
            format: ISO8601_REGEX,
            required: true,
        },
        placeOfBirth: {
            type: 'String',
        },
        placeOfBirthCountryCode: {
            type: 'String',
            format: COUNTRY_CODE,
        },
        dateOfDeath: {
            type: 'DateTime',
            format: ISO8601_REGEX,
            required: true,
        },
        placeOfDeath: {
            type: 'String',
        },
        placeOfDeathCountryCode: {
            type: 'String',
            format: COUNTRY_CODE,
        },
        stillbirth: {
            type: 'Boolean',
        },
        cremation: {
            type: 'Boolean',
        },
        ashCapsuleNr: {
            type: 'String',
            dependencyRequired: ['cremation'],
        },
        homeStorage: {
            type: 'Boolean',
        },
        ashCapsuleLocation: {
            type: 'String',
            dependencyRequired: ['homeStorage'],
        },
        attachmentKeys: {
            type: 'Array',
            format: FILE_KEY_REGEX,
            fileExtensions: [...FILEEXTENSIONS_IMAGE, ...FILEEXTENSIONS_DOCUMENT],
        },
    },
};

module.exports = {schema};
