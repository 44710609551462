import React, {
    useMemo,
} from 'react';
import _ from 'lodash';
import {FormContext, FormWrapper} from 'components/Form/FormWrapper';
import {
    Box, Grid,
} from '@mui/material';
import {FormElementTextField} from 'components/Form/FormElements/FormElementTextField';
import {FormElementLoadingButton} from 'components/Form/FormElements/FormElementLoadingButton';

import {FormElementInfoChips} from 'components/Form/FormElements/FormElementInfoChips';
import {FormElementActionButton} from 'components/Form/FormElements/FormElementActionButton';
import {
    AddCircleOutline, CopyAll, DownloadOutlined, Refresh,
} from '@mui/icons-material';
import {useCanAccess} from 'hooks/useCanAccess';
import {FormReset} from 'components/Form/FormReset';
import {QRCodeSVG} from 'qrcode.react';
import qrCodeDemo from 'applications/timebuddy/modules/workingtime/pages/images/qr_code.png';
import classes from 'components/Form/FormElements/formElementImageContainer.module.scss';
import {useFindRoute} from 'hooks/useFindRoute';
import {getRoutePath} from 'routes';
import {useGlobalState} from 'hooks/useGlobalState';
import {useClipboard} from 'hooks/useClipboard';
import {FormElementContainer} from 'components/Form/FormElements/FormElementContainer';
import {LoadingButton} from '@mui/lab';
import {downloadSVG} from 'helper/download';
import {useMessage} from 'hooks/useMessage';
import {Messages} from 'messages/Messages';

const {schema: validatorSchema} = require('beyond-validators/beyondBuddy/EvacuationPoint');

/**
 * The tenant formular for creating and updating a tenant
 * @param {import('applications/configuration').EntityFormularProps} props - props passed to the component
 * @returns {React.ReactElement} The EvacuationPointFormular component.
 */
function EvacuationPointFormular({id, onSave, ...rest}) {
    const {enqueueMessage} = useMessage();
    const isCreate = id === 'create';
    const {copyToClipboard} = useClipboard();
    const {getGlobal} = useGlobalState();
    const tenantId = getGlobal('tenantId');
    const canCreateEvacuationPoints = useCanAccess('createEvacuationPoint');

    const findRoute = useFindRoute();
    const checkPointRoute = findRoute('beyondbuddy_settings_evacuation_point_checkpoint_route');
    const checkPointURL = useMemo(() => {
        const url = `${window.location.origin}/${getRoutePath(checkPointRoute, {id})}?tenantid=${tenantId}`;
        return url;
    }, [id, tenantId, checkPointRoute]);

    return (
        <FormWrapper
            {...rest}
            isNewItem={id === 'create'}
            validatorSchema={{
                schema: validatorSchema,
                type: !isCreate ? 'update' : 'create',
            }}
            onSaveCallback={(result) => {
                if (_.isFunction(onSave)) {
                    onSave(result);
                }
            }}
            messageKey={(id !== 'create') ? 'EvacuationPoint_Update' : 'EvacuationPoint_Create'}
            context={`EvacuationPoint#${id}Base`}
        >
            <FormReset shouldClear={id === 'create'} />
            <Box style={{
                display: 'flex',
                gap: '1rem',
                flexWrap: 'wrap',
                justifyContent: 'center',
            }}
            >
                <FormElementActionButton
                    routeId="beyondbuddy_settings_evacuation_point_route"
                    routeParams={{id: 'create'}}
                    portalAnchorSelector={`#EvacuationPointForm${id}action-button-frame-base-actions`}
                    formWrapperIdPattern={`EvacuationPoint#${id}`}
                    disabled={id === 'create' || !canCreateEvacuationPoints}
                    context={FormContext}
                >
                    <AddCircleOutline />
                </FormElementActionButton>
                <FormElementActionButton
                    reload
                    portalAnchorSelector={`#EvacuationPointForm${id}action-button-frame-base-actions`}
                    formWrapperIdPattern={`EvacuationPoint#${id}`}
                    disabled={id === 'create'}
                    context={FormContext}
                >
                    <Refresh />
                </FormElementActionButton>

                <Box data-test="FormElementImageContainer">
                    <Box
                        className={classes.imageContainer}
                        style={{
                            width: 255,
                            height: 255,
                        }}
                    >
                        {isCreate
                    && (
                        <Box
                            component="img"
                            src={qrCodeDemo}
                            alt="QR-Demo"
                            width={255}
                            height={255}
                        />
                    )}
                        {!isCreate && (
                            <QRCodeSVG
                                id={`QRCode_${id}`}
                                size={256}
                                style={{
                                    height: '255px',
                                    width: '255px',
                                }}
                                level="H"
                                value={checkPointURL}
                            />
                        ) }
                    </Box>
                </Box>
                <Box style={{flexGrow: 1, flexShrink: 1, flexBasis: '450px'}}>
                    <Grid container spacing={2}>
                        <FormElementInfoChips showReadonly />
                        <Grid item xs={12}>
                            <FormElementTextField label="Name" attribute="name" />
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <FormElementLoadingButton />
                        </Grid>
                        <Grid item xs={6} sm={4} visibility={isCreate ? 'hidden' : 'initial'}>
                            <FormElementContainer
                                attribute="name"
                                propsMapping={(props) => ({
                                    onClick: () => {
                                        const qr = document.getElementById(`QRCode_${id}`);
                                        if (qr) {
                                            downloadSVG(qr, `EPQR-${props?.value}.svg`);
                                            enqueueMessage('template-download', Messages.DOWNLOAD_SUCCESSFUL);
                                        } else {
                                            throw new Error(`QR Code not found! ID: QRCode_${id}`);
                                        }
                                    },
                                    disabled: !props.value || props.isLoading?.load,
                                })}
                            >
                                <LoadingButton
                                    variant="contained"
                                    startIcon={<DownloadOutlined />}
                                    color="info"
                                >
                                        Download
                                </LoadingButton>
                            </FormElementContainer>
                        </Grid>
                        <Grid item xs={6} sm={4} visibility={isCreate ? 'hidden' : 'initial'}>
                            <FormElementContainer
                                attribute="*"
                                propsMapping={(props) => ({
                                    disabled: props.isLoading?.load,
                                })}
                            >
                                <LoadingButton
                                    variant="contained"
                                    startIcon={<CopyAll />}
                                    color="info"
                                    onClick={() => copyToClipboard(checkPointURL, true)}
                                >
                                Link
                                </LoadingButton>
                            </FormElementContainer>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </FormWrapper>
    );
}

export {EvacuationPointFormular};
