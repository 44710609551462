import React from 'react';
import {
    Tile, TileIconBackground, TileLabel, TileRouteButton,
} from 'assets/theme/components/Tile/Tile';
import {AirlineSeatFlatOutlined, ChurchOutlined, Inventory2Outlined} from '@mui/icons-material';
import {DashboardTilesContainer} from 'components/Dashboard/DashboardTilesContainer';
import {useCanAccess} from 'hooks/useCanAccess';

const cemeteryTile = (
    <Tile>
        <TileLabel label="Friedhöfe" />
        <TileRouteButton routeId="peacebuddy_settings_cemeteries_route" />
        <TileIconBackground icon={<ChurchOutlined color="primary" />} />
    </Tile>
);
const graveTile = (
    <Tile>
        <TileLabel label="Gräber" />
        <TileRouteButton routeId="peacebuddy_settings_graves_route" />
        <TileIconBackground icon={<Inventory2Outlined color="primary" />} />
    </Tile>
);
const deceasedPersonTile = (
    <Tile>
        <TileLabel label="Verstorbene" />
        <TileRouteButton routeId="peacebuddy_settings_deceasedPersons_route" />
        <TileIconBackground icon={<AirlineSeatFlatOutlined color="primary" />} />
    </Tile>
);

/**
 * This page shows a dashboard for the PeaceBuddy module
 * .PeaceBuddy.Pages
 * @returns {React.ReactElement} The PeaceBuddy DashBoard component
 */
function PeaceBuddyTileDashboard() {
    const elements = [
        useCanAccess('readGrave') && {
            routeId: 'peacebuddy_settings_graves_route',
            tile: graveTile,
        },
        useCanAccess('readDeceasedPerson') && {
            routeId: 'peacebuddy_settings_deceasedPersons_route',
            tile: deceasedPersonTile,
        },
        useCanAccess('readCemetery') && {
            routeId: 'peacebuddy_settings_cemeteries_route',
            tile: cemeteryTile,
        },
    ];
    /** @type {{severity: import('@mui/material').AlertColor, message:string}} */
    const notification = {severity: 'success', message: 'Alles im grünen Bereich! Es liegen keine Notifizierungen vor.'};

    return (
        <DashboardTilesContainer elements={elements} notification={notification} />
    );
}
export {PeaceBuddyTileDashboard};
