/* eslint-disable max-len */
import React from 'react';
import {
    Alert,
    Box, Divider, List, Typography,
} from '@mui/material';
// eslint-disable-next-line import/no-cycle
import {createQuickGuideListItem} from 'applications/beyondbuddy/settings/pages/quickguides/GuideGeneralPage';
import {QuickGuideLink} from 'components/QuickGuide/QuickGuideLink';
import {Check} from '@mui/icons-material';

/**
 *
 * @returns {React.ReactElement} the guide page
 */
function GuideGraveCustomerSynchronizationPage() {
    return (
        <>
            <Typography variant="h1" color="primary">Nutzungsberechtigte synchronisieren</Typography>
            <hr />
            <Box>
                <Typography textAlign="justify">
                    Die Konfiguration der Nutztungsberechtigten können auf weiteren Gräber synchronisiert werden. Hierbei ist es jedoch essenziell,
                    dass die Gräber entsprechend zu einem
                    <QuickGuideLink id="peacebuddy_general_grave_connections"> Familiengrab verknüpft </QuickGuideLink>
                    wurden.
                </Typography>
                <br />
                <Alert severity="warning">
                    <Typography textAlign="justify">
                        Nach einer Verknüpfung und jeder Änderung von Verknüpfungen, muss das Formular vor einer Synchronisierung neu geladen werden,
                        da sonst alte Werte für die Synchronisierung herangezogen werden!
                    </Typography>
                </Alert>
                <br />
                <Typography textAlign="justify">
                    Die Synchronisierung erfolgt durch betätigen der Schaltfläche
                    <b> SYNCHRONISIEREN</b>
                    . Es muss gewartet werden, bis eine entsprechende Bestätigung über den Status der Synchronisierung angezeigt wird.
                </Typography>
                <br />
                <Alert severity="success" icon={<Check />}>Die Synchronisierung wurde erfolgreich durchgeführt.</Alert>

                <br />
                <Typography variant="h2" color="primary">Weiterführende Kurzanleitungen</Typography>
                <Divider />
                <List dense>
                    {createQuickGuideListItem({id: 'peacebuddy_general_grave_authorized_customers', label: 'Nutzungsberechtigte'})}
                    {createQuickGuideListItem({id: 'peacebuddy_general_grave_connections', label: 'Grabverknüpfungen'})}
                </List>
            </Box>
        </>
    );
}
export {GuideGraveCustomerSynchronizationPage};
