import React from 'react';
import {
    Alert, Box, Button, List, Typography,
} from '@mui/material';
import {LayoutContainer} from 'assets/theme/layout/LayoutContainer/LayoutContainer';
import peaceBuddyLogo from 'applications/peacebuddy/pages/images/PEACEBUDDY_LOGO_V1.png';
import {Dashboards} from 'components/Dashboard/Dashboards';
import {Feedback} from '@mui/icons-material';
import {useGlobalState} from 'hooks/useGlobalState';
import {DashboardContainer} from 'components/Dashboard/DashboardContainer';
import {createQuickGuideListItem} from 'applications/beyondbuddy/settings/pages/quickguides/GuideGeneralPage';

/**
 * This page shows a dashboard for the peacebuddy application
 * @returns {React.ReactElement} The OrganizationalUnitListPage component
 */
function PeaceBuddyDashboard() {
    const {setGlobal} = useGlobalState();

    const generalGuides = [
        {id: 'peacebuddy_general_cemetery', label: 'Friedhöfe anlegen'},
        {id: 'peacebuddy_general_grave', label: 'Gräber anlegen und verwalten'},
        {id: 'peacebuddy_general_grave_connections', label: 'Gräber miteinander verknüpfen'},
        {id: 'peacebuddy_general_graveRecord', label: 'Beisetzung protokollieren'},
        {id: 'peacebuddy_general_deceasedPerson', label: 'Verstorbene Person anlegen'},
        {id: 'peacebuddy_general_grave_authorized_customers', label: 'Nutzungsberechtigten hinterlegen'},
    ];

    return (
        <LayoutContainer>
            <Box
                component="img"
                src={peaceBuddyLogo}
                style={{display: 'block', margin: '2rem auto 2rem auto', maxHeight: '8rem'}}
            />

            <Dashboards applicationId="peacebuddy" />
            <DashboardContainer head={<Typography variant="h2">Hilfe & Dokumentation</Typography>}>
                <List dense style={{width: '100%'}}>
                    {generalGuides.map(createQuickGuideListItem)}
                </List>
            </DashboardContainer>
            <hr />
            <Alert severity="success" icon={<Feedback />}>
                Du nutzt die erste Version unsere Fahrtenbuchs. Hast Du Anmerkungen, Wünsche oder gar einen Fehler entdeckt?
                <br />
                <Button onClick={() => setGlobal('showFeedback', true)}>Feedback erfassen</Button>
            </Alert>
            <hr style={{marginBottom: '2rem'}} />
        </LayoutContainer>
    );
}
export {PeaceBuddyDashboard};
