import React, {
    useCallback, useContext,
} from 'react';
import _ from 'lodash';
import {
    Alert,
    Autocomplete,
    Checkbox,
    FormControlLabel,
    TableCell,
    TextField,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';

import {AttributesFormular} from 'applications/beyondbuddy/settings/forms/permissions/AttributesFormular';
import {ItemDataContext} from 'components/Form/ItemData';
import {useMessage} from 'hooks/useMessage';
import {Exceptions as PeaceBuddyExceptions} from 'applications/peacebuddy/messages/Exceptions';
import {Block} from '@mui/icons-material';
import {updateGraveConnections} from 'graphql/peaceBuddy/Grave/mutations';

const positionOptions = [
    {id: 'left', label: 'links'},
    {id: 'right', label: 'rechts'},
];

/**
 * The authorized customers formular for linking customers to graves
 * @param {import('applications/configuration').EntityFormularProps} props - props passed to the component
 * @returns {React.ReactElement} The CemeteryFormular component.
 */
function GraveLinksFormular({id, readonly}) {
    const {enqueueMessage} = useMessage();
    const {data} = useContext(ItemDataContext);

    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

    // eslint-disable-next-line function-paren-newline
    const getLineControls = useCallback(
        /**
         * @param {Record<string, any>} attributes - the entity link information
         * @param {boolean} locked - indicates, that no changes can be made
         * @param {(attributes: Record<string, any>)=>void} changeHandler - the form change handler
         * @param {object} item - the item data of the selected entity
         * @returns {import('react').ReactNode} - returns the link controls
         */
        (attributes, locked, changeHandler, item) => {
            const notConnectable = !item?.choosable // the selected/referenced grave is not choosable
            || !data?.choosable; // the current grave is not choosable
            const controls = [
                (notConnectable && !attributes?.connected) ? (
                    <Tooltip title="Reihengräber können nicht zu Familiengräbern verknüpft werden.">
                        <Block data-test="FormElementEntityChooser_link_connected" color="secondary" />
                    </Tooltip>
                ) : (
                    <Checkbox
                        key={1}
                        // data-test={`FormElementCheckbox_${attribute}`}
                        checked={attributes?.connected ?? false} // false is default value
                        onChange={(e) => changeHandler({
                            connected: e.target.checked,
                        })}
                        disabled={locked || (notConnectable && !attributes?.connected)} // only disable when it is not already selected to be able to deselect it
                        data-test="FormElementEntityChooser_link_connected"
                        style={((!data?.choosable || !item?.choosable) && attributes?.connected) ? {border: 'solid 1px red'} : {}}
                    />
                ),
                <Autocomplete
                    key={2}
                    value={_.find(positionOptions, (o) => o.id === attributes?.position) ?? null}
                    disabled={locked}
                    renderInput={(params) => (
                        <TextField
                            label="Position"
                            {...params}
                        />
                    )}
                    options={positionOptions}
                    onChange={(event, newValue) => {
                        changeHandler({
                            position: newValue?.id || '',
                        });
                    }}
                    getOptionLabel={(option) => option?.label}
                    data-test="FormElementEntityChooser_link_position"
                />,

            ];

            if (!isDesktop) {
                return [
                    <FormControlLabel key={1} label="Familiengrab" control={controls[0]} />,
                    controls[1],
                ];
            }
            return [
                <TableCell align="center" key={1}>{controls[0]}</TableCell>,
                <TableCell align="left" key={2}>{controls[1]}</TableCell>,
            ];
        }, [isDesktop, data?.choosable]);

    /** @type {()=>Partial<Record<import('applications/configuration').ManagedObject, Partial<import('applications/configs').FormElementEntityChooserDataSchemaOptions>>>} */
    const schemaOptions = useCallback(() => ({
        Grave: {
            queryVariables: {
                direct: {filter: {cemeteryId: data?.cemeteryId}},
            },
            routeHash: 'connections',
            // routeId: 'peacebuddy_settings_grave_sub_route',
        },
    }), [data?.cemeteryId]);

    return (
        <AttributesFormular
            entityTypeId={`Grave#${id}`}
            context="peaceBuddyGraveConnections"
            actionButtonProps={{
                portalAnchorSelector: `#GraveForm${id}action-button-frame-connections-actions`,
            }}
            disabled={readonly}
            tableHeadLabels={[{
                label: 'Familiengrab',
                align: 'center',
            },
            {
                label: 'Position',
            }]}
            getLineControls={getLineControls}
            addOpposite={(links) => _.map(links, (link) => ({
                ...link,
                opposite: [{
                    leftEntityTypeId: `Grave#${id}`,
                    rightEntityTypeId: link.leftEntityTypeId,
                    attributes: {
                        ...link.attributes,
                        ...(link.attributes.position ? {
                            position: link.attributes.position === 'left'
                                ? 'right'
                                : 'left',
                        } : {}),
                    },
                }],
            }))}
            validate={(links) => {
                const graveLinks = (_.filter(links, (link) => link.leftEntityTypeId.startsWith('Grave')
                    && _.has(link.attributes, 'position')));

                if (!_.size(graveLinks)) {
                    return true;
                }
                const countedPositions = _.countBy(graveLinks, (link) => link.attributes.position);
                if (countedPositions?.left > 1 || countedPositions?.right > 1) {
                    enqueueMessage(`grave_${id}_connections_tab`, PeaceBuddyExceptions.GRAVE_CONNECTION_POSITIONS_SINGULARITY);
                    return false;
                }

                return true;
            }}
            entityChooserProps={{
                availableEntities: ['Grave'],
                defaultAttributes: {Grave: {connected: true}},
                blockSelfReference: true,
                schemaOptions,
                entityChooserLabel: 'Gräber',
            }}
            loadConfig={{
                postProcess: (rawResponse) => {
                    // Extract grave mappings for easy lookup by entity ID
                    const graveMap = _.keyBy(rawResponse.graves, (grave) => `Grave#${grave.id}`);

                    // Sort links by `generalNr`
                    const links = _.sortBy(rawResponse.links, (link) => graveMap[link.leftEntityTypeId].generalNr);

                    return {
                        ...rawResponse,
                        links,
                    };
                },
            }}
            saveConfig={{
                mutation: updateGraveConnections,
                postProcess: (rawResponse) => {
                    // Extract grave mappings for easy lookup by entity ID
                    const graveMap = _.keyBy(rawResponse.graves, (grave) => `Grave#${grave.id}`);

                    // Sort links by `generalNr`
                    const links = _.sortBy(rawResponse.links, (link) => graveMap[link.leftEntityTypeId].generalNr);
                    return {
                        ...rawResponse,
                        links,
                    };
                },
            }}
        >

            <>
                {!data?.choosable && (
                    <Alert severity="warning">
                        <Typography textAlign="justify">
                                Dieses Grab kann nicht als Familiengrab verknüpft werden, da es sich um ein Reihengrab handelt.
                                Ein Reihengrab bietet nur einen Bestattungsplatz und eine Erweiterung oder Kombination mit anderen Gräbern ist nicht vorgesehen!
                        </Typography>
                    </Alert>
                )}
                <Alert severity="info">
                    <Typography textAlign="justify">
                        {isDesktop && 'Bitte vergewissere Dich, dass Du den Friedhof ordnungsgemäß am Grab gespeichert hast. '}
                            Es stehen bei der Auswahl an Nachbargräbern ausschließlich Gräber des selben Friedhofs zur Verfügung.
                    </Typography>
                </Alert>
            </>
        </AttributesFormular>
    );
}

export {GraveLinksFormular};
