import React from 'react';
import _ from 'lodash';
import {FormContext, FormWrapper} from 'components/Form/FormWrapper';
import {
    Box, Divider, Grid,
    Tooltip,
} from '@mui/material';
import {FormElementAutocomplete} from 'components/Form/FormElements/FormElementAutocomplete';
import {FormElementTextField} from 'components/Form/FormElements/FormElementTextField';
import {FormElementLoadingButton} from 'components/Form/FormElements/FormElementLoadingButton';

import {FormElementInfoChips} from 'components/Form/FormElements/FormElementInfoChips';
import {FormElementActionButton} from 'components/Form/FormElements/FormElementActionButton';
import {
    AddCircleOutline, InfoOutlined, Refresh,
} from '@mui/icons-material';
import {useCanAccess} from 'hooks/useCanAccess';
import {FormReset} from 'components/Form/FormReset';
import {allowedApplicationsDataSchema} from 'applications/beyondbuddy/settings/forms/customer/CustomerSchema';
import {countryCodeDataSchema} from 'helper/CountrySchema';
import {genderDataSchema} from 'helper/GenderSchema';

const tootlTipSendingInformation = 'Diese optionalen Informationen werden als Versandadressen verwendet. Bei Firmen kann eine etwaige Person beispielsweise als z.H. deklariert werden.'
+ 'Sollten diese nicht angegeben werden, so wird die Adresse aus den Kontaktdaten herangezogen.';

const {schema: validatorSchema} = require('beyond-validators/beyondBuddy/Customer');

/**
 * The cemetery formular for creating and updating a customer
 * @param {import('applications/configuration').EntityFormularProps} props - props passed to the component
 * @returns {React.ReactElement} The CustomerFormular component.
 */
function CustomerFormular({id, onSave, ...rest}) {
    const canCreateCustomers = useCanAccess('createCustomer');
    return (
        <FormWrapper
            {...rest}
            isNewItem={id === 'create'}
            validatorSchema={{
                schema: validatorSchema,
                type: (id !== 'create') ? 'update' : 'create',
            }}
            onSaveCallback={(result) => {
                if (_.isFunction(onSave)) {
                    onSave(result);
                }
            }}
            messageKey={(id !== 'create') ? 'Customer_Update' : 'Customer_Create'}
            context={`Customer#${id}Base`}
        >
            <FormReset shouldClear={id === 'create'} />
            <Box style={{
                display: 'flex',
                gap: '1rem',
                flexWrap: 'wrap',
                justifyContent: 'center',
            }}
            >
                <FormElementActionButton
                    routeId="beyondbuddy_settings_general_customer_route"
                    routeParams={{id: 'create'}}
                    portalAnchorSelector={`#CustomerForm${id}action-button-frame-base-actions`}
                    formWrapperIdPattern={`Customer#${id}`}
                    disabled={id === 'create' || !canCreateCustomers}
                    context={FormContext}
                >
                    <AddCircleOutline />
                </FormElementActionButton>
                <FormElementActionButton
                    reload
                    portalAnchorSelector={`#CustomerForm${id}action-button-frame-base-actions`}
                    formWrapperIdPattern={`Customer#${id}`}
                    disabled={id === 'create'}
                    context={FormContext}
                >
                    <Refresh />
                </FormElementActionButton>
                <Box style={{flexGrow: 1, flexShrink: 1, flexBasis: '450px'}}>
                    <Grid container spacing={2}>
                        <FormElementInfoChips showReadonly />
                        <Grid item xs={12} sm={4}>
                            <FormElementTextField label="Nr." attribute="nr" />
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <FormElementTextField label="Referenz" attribute="referenceId" />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <FormElementAutocomplete
                                attribute="gender"
                                label="Geschlecht"
                                dataSchema={genderDataSchema}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <FormElementTextField label="Titel (vorangestellt)" attribute="titlePrefixed" />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <FormElementTextField label="Titel (nachgestellt)" attribute="titlePostfixed" />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormElementTextField label="Vorname" attribute="firstName" />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormElementTextField label="Nachname" attribute="lastName" />
                        </Grid>

                        <Grid item xs={12}>
                            <h3>Kontaktdaten</h3>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            <FormElementTextField label="Adresse" attribute="address" />
                        </Grid>
                        <Grid item xs={12} sm={4} md={2}>
                            <FormElementTextField label="PLZ" attribute="zip" type="number" />
                        </Grid>
                        <Grid item xs={12} sm={8} md={5}>
                            <FormElementTextField label="Stadt" attribute="city" />
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <FormElementAutocomplete dataSchema={countryCodeDataSchema} attribute="countryCode" label="Land" />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormElementTextField label="Telefon" attribute="phoneNumber" />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormElementTextField label="Telefon" attribute="phoneNumber2" />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormElementTextField label="E-Mail" attribute="eMailAddress" />
                        </Grid>

                        {/* <FormElementContainer
                                attribute="companyName"
                                conditionalRender={(data) => !data.value && !showFormParts?.company}
                            >
                                <Grid item xs={12}>
                                    <Button variant="contained" onClick={() => setShowFormParts((current) => ({...current, company: true}))}>Firma</Button>
                                </Grid>
                            </FormElementContainer> */}

                        <Grid item xs={12}>
                            <h3>Firmeninformationen</h3>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            <FormElementTextField label="Firma" attribute="companyName" />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormElementTextField label="UID" attribute="vat" />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormElementTextField label="FN" attribute="companyRegisterNumber" />
                        </Grid>

                        <Grid item xs={12}>
                            <h3 style={{display: 'inline'}}>Versandinformationen</h3>
                            <Tooltip arrow title={tootlTipSendingInformation}>
                                <InfoOutlined style={{marginLeft: '1rem', cursor: 'help'}} />
                            </Tooltip>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FormElementAutocomplete
                                attribute="toHandsGender"
                                label="Geschlecht"
                                dataSchema={genderDataSchema}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <FormElementTextField label="Titel (vorangestellt)" attribute="toHandsTitlePrefixed" />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <FormElementTextField label="Titel (nachgestellt)" attribute="toHandsTitlePostfixed" />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormElementTextField label="Vorname" attribute="toHandsFirstName" />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormElementTextField label="Nachname" attribute="toHandsLastName" />
                        </Grid>
                        <Grid item xs={12}>
                            <FormElementTextField label="Adresse" attribute="deliveryAddress" />
                        </Grid>
                        <Grid item xs={12} sm={4} md={2}>
                            <FormElementTextField label="PLZ" attribute="deliveryZip" type="number" />
                        </Grid>
                        <Grid item xs={12} sm={8} md={5}>
                            <FormElementTextField label="Stadt" attribute="deliveryCity" />
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <FormElementTextField label="Land" attribute="deliveryCountryCode" />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormElementTextField label="E-Mail (Versandadresse)" attribute="deliveryEMailAddress" />
                        </Grid>

                        <Grid item xs={12}>
                            <h3>Sonstiges</h3>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            <FormElementTextField label="Vermerk" attribute="notes" multiline minRows={3} />
                        </Grid>

                        {!rest?.initialData?.allowedApplications
                            && (
                                <Grid item xs={12}>
                                    <FormElementAutocomplete
                                        attribute="allowedApplications"
                                        label="Freigabe auf folgende Anwendungen beschränken"
                                        dataSchema={allowedApplicationsDataSchema}
                                        multiple
                                    />
                                </Grid>
                            )}
                        <Grid item xs={12}>
                            <FormElementLoadingButton />
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </FormWrapper>
    );
}

export {CustomerFormular};
