import React, {
    useCallback, useMemo, useRef, useState,
} from 'react';
import {LayoutContainer} from 'assets/theme/layout/LayoutContainer/LayoutContainer';
import {VehicleFormular} from 'applications/drivebuddy/settings/forms/vehicle/VehicleFormular';
import {
    generatePath,
    useNavigate,
    useParams,
} from 'react-router-dom';
import {useCurrentRoute} from 'routes';

import {createVehicle, updateVehicle} from 'graphql/driveBuddy/Vehicle/mutations';
import {getVehicle} from 'graphql/driveBuddy/Vehicle/queries';
import _ from 'lodash';
import {PermissionFormular} from 'applications/beyondbuddy/settings/forms/permissions/PermissionFormular';
import {createPermissionsObject} from 'applications/configs';
import {organizationEntityKeys} from 'applications/beyondbuddy/config';
import {readUpdateDeletePermissionTemplate} from 'components/Form/FormElements/FormElementEntityLinkPermissions';
import {
    AddCircleOutline,
    ArrowLeftOutlined,
    ArticleOutlined,
    BlurOnOutlined, LockOutlined,
} from '@mui/icons-material';
import {TabFormsContainer} from 'components/Form/TabFormsContainer';
import {Box, Typography} from '@mui/material';
import {useCanAccess} from 'hooks/useCanAccess';
import {VehicleProtocolEntryFormular} from 'applications/drivebuddy/settings/forms/vehicle/VehicleProtocolEntryFormular';
import {VehicleProtocolEntriesListFormular} from 'applications/drivebuddy/settings/forms/vehicle/VehicleProtocolEntriesListFormular';
import {MenuLink} from 'components/MenuLink';

import {useFindRoute} from 'hooks/useFindRoute';
import {FormElementActionButton} from 'components/Form/FormElements/FormElementActionButton';

const mask = {
    id: true,
    tenantId: true,
    name: true,
    manufacturer: false,
    model: false,
    vin: false,
    licensenumber: false,
    imageKey: false,
};

/**
 * @param {Partial<import('applications/configuration').EntityFormularProps>} props the properties
 * @returns {React.ReactElement} the vehicle formular
 */
const getVehicleFormular = (props) => <VehicleFormular {...props} />;

/**
 * @param {Partial<import('applications/configuration').EntityFormularProps>} props the properties
 * @returns {React.ReactElement} the permissions formular
 */
const getPermissionFormular = (props) => (
    <PermissionFormular
        disabled={props.readonly}
        isIncoming
        entityTypeId={`Vehicle#${props.id}`}
        actionButtonProps={{portalAnchorSelector: `#VehicleForm${props.id}action-button-frame-permissions-actions`}}
        entityChooserProps={{
            guideId: '',
            availablePermissions: createPermissionsObject(organizationEntityKeys, ['useVehicleGroup', 'administrateVehicleGroup']),
            defaultPermissions: createPermissionsObject(organizationEntityKeys, ['useVehicleGroup']),
            permissionDependencies: {
                User: readUpdateDeletePermissionTemplate,
                Group: readUpdateDeletePermissionTemplate,
                OrganizationalUnit: readUpdateDeletePermissionTemplate,
                Tenant: readUpdateDeletePermissionTemplate,
            },
        }}
        {...props}
    />
);

/**
 * This page shows a create and update form for a vehicle.
 * Also other information as authorized customers, links and permissions are available.
 * @returns {React.ReactElement} The CemeteryPage component.
 */
function VehiclePage() {
    const {id, protocolEntryId} = useParams();
    const isNewItem = id === 'create';
    const navigate = useNavigate();
    const [currentTab, setCurrentTab] = useState(0);
    const proRef = useRef();
    const findRoute = useFindRoute();

    const canCreateProtocolEntries = useCanAccess('createVehicleProtocolEntry');

    /** @type {import('routeinfo').RoutePathInfo} */
    const route = useCurrentRoute();

    const onSave = useCallback((result) => {
        if (id === 'create' && result?.id) {
            navigate(`/${generatePath(route.path, {id: result?.id})}`);
        }
    }, [id, route, navigate]);

    /** @type {import('components/Form/form').ItemLoadConfig} */
    const loadConfig = useMemo(() => ({
        query: getVehicle,
        variables: {direct: {id}},
        mask: {id: true},
    }), [id]);

    /** @type {import('components/Form/form').ItemSaveConfig} */
    const saveConfig = useMemo(() => ({
        variables: {global: {tenantId: 'tenantId'}},
        ...!isNewItem ? {
            mutation: updateVehicle,
            mask,
        } : {
            mutation: createVehicle,
            mask: _.omit(mask, 'id'),
        },
    }), [id]);

    const getVehicleProtocolEntries = useCallback(() => (
        <>
            {!protocolEntryId
            && (
                <>
                    <Box display="flex" justifyContent="space-between">
                        {/* Span to avoid the actionbutton frame shifting to the right to take the buttons place */}
                        <span>
                            {canCreateProtocolEntries && (
                                <FormElementActionButton
                                    dataTest="FormElementActionButton_Add"
                                    callback={() => navigate(`/${generatePath(
                                        findRoute('drivebuddy_drive_settings_vehicle_protocolEntry_route').path,
                                        {id, protocolEntryId: 'create'},
                                    )}#protocolEntry`)}
                                    portalAnchorSelector={`#VehicleForm${id}action-button-frame-protocolEntry-actions`}
                                >
                                    <AddCircleOutline />
                                </FormElementActionButton>
                            )}
                        </span>
                    </Box>
                    <VehicleProtocolEntriesListFormular
                        vehicleId={id}
                        actions={{
                            edit: {
                                callBack: (item) => navigate(`/${generatePath(
                                    findRoute('drivebuddy_drive_settings_vehicle_protocolEntry_route').path,
                                    {id, protocolEntryId: item.id},
                                )}#protocolEntry`),
                            },
                        }}
                        actionButtonProps={{portalAnchorSelector: `#VehicleForm${id}action-button-frame-protocolEntry-actions`}}
                        listDataRef={proRef}
                    />
                </>
            )}
            {protocolEntryId && (
                <>
                    <MenuLink
                        to={`/${generatePath(findRoute('drivebuddy_drive_settings_vehicle_route').path, {id})}#protocolEntry`}
                        onClick={() => {
                            // @ts-ignore
                            proRef.current?.reload?.();
                        }}
                    >
                        <Box display="flex" flexWrap="nowrap" alignContent="center">
                            <ArrowLeftOutlined color="primary" style={{marginRight: '2rem'}} />
                            <Typography color="primary" textTransform="uppercase">Zurück zur Übersicht</Typography>
                        </Box>
                    </MenuLink>
                    <br />
                    <VehicleProtocolEntryFormular
                        vehicleId={id}
                        id={protocolEntryId}
                        actionButtonProps={{portalAnchorSelector: `#VehicleForm${id}action-button-frame-protocolEntry-actions`}}
                        onSave={(result) => {
                            if (protocolEntryId === 'create' && 'id' in result && result?.id) {
                                navigate(`/${generatePath(findRoute('drivebuddy_drive_settings_vehicle_protocolEntry_route').path, {id, protocolEntryId: result?.id})}#protocolEntry`);
                            }
                        }}
                    />
                </>
            )}
        </>
    ), [id, protocolEntryId, canCreateProtocolEntries, proRef.current, navigate, generatePath, findRoute]);

    // eslint-disable-next-line function-paren-newline
    const tabFormsContainerProps = useMemo(
        /** @returns {import('components/Form/form').TabFormsContainerProps} container properties */
        () => ({
            identifier: `VehicleForm${id}`,
            itemDataProps: {
                ...(!isNewItem) ? {loadConfig} : {},
                saveConfig,
            },
            tabs: [{
                id: 'base',
                label: 'Basisdaten',
                icon: <BlurOnOutlined />,
                getChildren: () => getVehicleFormular({id, onSave}),
                formWrapperId: `Vehicle#${id}Base`,
            }, {
                id: 'protocolEntry',
                label: 'Protokolle',
                icon: <ArticleOutlined />,
                enabledCondition: 'vehicleProtocolEntryReadable',
                // !isNewItem is important, instead it will be some error shown when switching from a list view (e.g. permissions tab) to create
                getChildren: () => !isNewItem && getVehicleProtocolEntries(),
                formWrapperId: `VehicleProtocolEntry#${protocolEntryId}Base`,
                quickGuideId: 'driveBuddy_vehicle_protocolEntry',
            }, {
                id: 'permissions',
                label: 'Berechtigungen',
                icon: <LockOutlined />,
                enabledCondition: 'permissionsReadable',
                // !isNewItem is important, instead it will be some error shown when switching from a list view (e.g. permissions tab) to create
                getChildren: (grants) => !isNewItem && getPermissionFormular({id, readonly: !grants.permissionsUpdatable}),
                formWrapperId: `Vehicle#${id}Permissions`,
                quickGuideId: 'beyondbuddy_general_permissions',
            },
            ],
            currentTab,
            setCurrentTab,
        }), [id, protocolEntryId, isNewItem, loadConfig, saveConfig, currentTab,
            getVehicleProtocolEntries, getPermissionFormular, setCurrentTab, onSave]);

    return (
        <LayoutContainer>
            <TabFormsContainer {...tabFormsContainerProps} />
        </LayoutContainer>
    );
}
export {VehiclePage};
