/* eslint-disable max-len */
import React from 'react';
import {
    Alert, Box, Divider, Typography,
} from '@mui/material';
// eslint-disable-next-line import/no-cycle
import {QuickGuideLink} from 'components/QuickGuide/QuickGuideLink';

const getFormAttributeDescription = (label, description) => (
    <li>
        <strong>{label}</strong>
        {`: ${description}`}
    </li>
);

/**
 *
 * @returns {React.ReactElement} the guide page
 */
function GuideGravePage() {
    return (
        <>
            <Typography variant="h1" color="primary">Grab</Typography>
            <hr />
            <Box>
                Das Formular zur Anlage und zum Bearbeiten eines Grabs umfasst den Bereich der Basisinformationen,
                sowie bei bestehenden Gräbern zusätzlich die
                <QuickGuideLink id="beyondbuddy_general_permissions"> (eingehenden) Berechtigungen</QuickGuideLink>
                .
                <br />
                <br />
                <Alert severity="info">Beim Erstellen eines Grabs, wird automatich der gesammte Mandant berechtigt diesen zu administrieren.</Alert>
                <br />
                <Typography variant="h2" color="primary">Pflichtfelder</Typography>
                <Divider />
                <ul>
                    {getFormAttributeDescription(
                        'Grabkartennummer',
                        'Eindeutige Nummer des Grabes innerhalb eines Friedhofs. Wird kein Friedhof gewählt, muss die Nummer ebenfalls unter den nicht zugeordneten Gräbern eindeutig sein.',
                    )}
                    {getFormAttributeDescription(
                        'Art',
                        'Sarg- oder Urnengrab zur Auswahl',
                    )}
                    {getFormAttributeDescription(
                        'Form',
                        'Grabformen, je nach Art',
                    )}
                    {getFormAttributeDescription(
                        'Plätze',
                        'Anzahl an Plätzen innerhalb des Sarggrabs. Sollte es kein Wahlgrab sein, so wird es als Reihengrab klassifiziert und die Anzahl der Plätze automatisch auf 1 begrenzt.',
                    )}
                    {getFormAttributeDescription(
                        'Ruhefrist',
                        'Die Frist ist in Jahren anzugeben und wird bei der Auswahl eines Friedhofs automatisch ausgefüllt.',
                    )}
                </ul>
                <br />
                <Typography variant="h2" color="primary">Weitere Felder</Typography>
                <Divider />
                <ul>
                    {getFormAttributeDescription(
                        'Friedhof',
                        'Zugeordneter Friedhof',
                    )}
                    {getFormAttributeDescription(
                        'Abteilung / Baumkreis',
                        'Dient der adressierung des Grabs',
                    )}
                    {getFormAttributeDescription(
                        'Reihe / Zusatz',
                        'Dient der adressierung des Grabs',
                    )}
                    {getFormAttributeDescription(
                        'Nummer',
                        'Dient der adressierung des Grabs',
                    )}
                    {getFormAttributeDescription(
                        'Letzte Beisetzung',
                        'Datum der letzten Beisetzung',
                    )}
                    {getFormAttributeDescription(
                        'Fristende',
                        'Datum des Ende der Ruhefrist. Wird automatisch anhand der Ruhefrist auf den 31. Dezember des letzten Jahres der Ruhefrist gesetzt, kann jedoch überschrieben werden.',
                    )}
                    {getFormAttributeDescription(
                        'Frei / Heimgefallen',
                        'Zeigt an, dass das Grab verfügbar ist',
                    )}
                    {getFormAttributeDescription(
                        'Wahlgrab',
                        'Dieses Grab kann nach der Ruhefrist verlängert werden',
                    )}
                    {getFormAttributeDescription(
                        'Ehrengrab',
                        'Das Grab stellt ein Ehrengrab da',
                    )}
                    {getFormAttributeDescription(
                        'Vermerk',
                        'Beliebiges Kommentar',
                    )}
                </ul>

                <br />
                <Typography variant="h2" color="primary">Dokumente und Multimedia-Inhalte</Typography>
                <Divider />
                Das Formular bietet die Möglichkeit ein repräsentatives Bild des Grabes hochzuladen.
                Zusätzlich können multimediale Inhalte wie Dokumente oder weitere Bilder hochgeladen werden,
                um Informationen zum Grab bereitzustellen.

                <br />
                <Typography variant="h2" color="primary">Wissenswertes</Typography>
                <Divider />
                Gräber können nicht mehr gelöscht werden, sobald diese Grabeinträge haben.
                Auch ist ein Löschen nicht möglich, wenn das Grab bereits vergeben wurde und nicht als frei ausgeführt wird.
            </Box>
        </>
    );
}
export {GuideGravePage};
