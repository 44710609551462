import React, {
    useCallback, useContext, useMemo, useState,
} from 'react';
import {
    Alert,
    Box, Checkbox, FormControlLabel,
    FormGroup,
    Paper,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    useMediaQuery,
    useTheme
    ,
} from '@mui/material';
import {FormContext} from 'components/Form/FormWrapper';
import _ from 'lodash';
import {FormElementEntityLink} from 'components/Form/FormElements/FormElementEntityLink';
import {dataSchemas} from 'components/Form/FormElements/FormElementEntityChooserSchema';
import {
    defaultLinkSchemaOptions, defaultPermissionGroupDefinitions, permissionLabels,
} from 'applications/configs';

/**
 * @typedef  {import('applications/configuration').ManagedObject} ManagedObject
 * @typedef {"Objecttype" | ManagedObject} SelfType
 * @typedef {import('components/Form/FormElements/FormElementEntityLink').EntityBase} EntityBase
 * @typedef  {import('components/Form/FormElements/formElements').FormElementEntityChooserDataSchemaOptions} FormElementEntityChooserDataSchemaOptions
 */

/**
 * @typedef {import('applications/configuration').Permission} Permission
 * @typedef {import('applications/configuration').Permissions} Permissions
 * @typedef {import('applications/configuration').PermissionGroup} PermissionGroup
 * @typedef {import('applications/configuration').PermissionGroupDefinition} PermissionGroupDefinition
 * @typedef {import('applications/configuration').PermissionDependency} PermissionDependency
 * @typedef {import('applications/configuration').PermissionDependencies} PermissionDependencies
 * @typedef {import('components/Form/FormElements/FormElementEntityLink').Link} Link
 * @typedef {import('components/Form/FormElements/FormElementEntityLink').Links} Links
 */

/**
 * @typedef FormElementEntityLinkPermissionsProps
 * @property {string} attribute - attribute to load/write data from/to
 * @property {"left" | "right"} linkDirection - direction of the permissions being read and set
 * @property {Permissions} availablePermissions - all the permissions, that should be configured
 * @property {Record<string, Permission[]>} [extendedPermissionGroupDefinition] - non default permission groups
 * @property {Permissions} [defaultPermissions] - permissions, that are automatically enabled when adding the entity
 * @property {PermissionDependencies} [permissionDependencies] - dependencies among permissions
 * @property {SelfType} selfType - type of the same entity being edited
 * @property {boolean} [blockSelfReference] - flag to disable the option to link to the same entity
 * @property {ManagedObject} [initialEntityType] - entityType to be selected initially
 * @property {(isIncoming: boolean)=>Partial<Record<ManagedObject, Partial<FormElementEntityChooserDataSchemaOptions>>>} [schemaOptions] - options to enhance data schema
 * @property {(Permission|PermissionGroup)[]} [lockedPermissions] - permission types that will be shown but not modifiable locked
 * @property {boolean} [showCostsWarning] - flag to disable the costs warning information
 * @property {boolean} [disabled] - flag to disable the entity chooser. Viewing and browsing is still possible
 * @property {string} [guideId] - id of the quick guide
 * @property {Partial<import('components/Form/FormElements/formElement').FormElementActionButtonProps>} [actionButtonProps] - properties for actionButtons
 */

/**
 * function that preprocesses links before sending it. The json transformation is done here.
 * @param {object} data links data
 * @returns {object} prepared links data
 */
const preProcess = (data) => {
    if (!('links' in data)) {
        return data;
    }
    const links = data.links.links?.filter(({permissions, attributes}) => !_.isEmpty(permissions) || !_.isEmpty(_.isString(attributes) ? JSON.parse(attributes) : attributes));

    /**
     * @param {Link} link the link to process
     * @returns {object} the processed link
     */
    const process = (link) => ({
        ...link,
        permissions: _.uniq(link.permissions),
        ...(link.attributes ? {attributes: link.attributes ? JSON.stringify(link.attributes) : {}} : {}),
        ...(link.opposite ? {opposite: _.map(link.opposite, process)} : {}),
    });

    return {
        ...data,
        links: _.map(links, process),
    };
};

const postProcess = (data, id) => {
    /**
     * @param {object} link the link to process
     * @returns {Link} the processed link
     */
    const process = (link) => ({
        ...link,
        ...(link.attributes ? {attributes: link.attributes ? JSON.parse(link.attributes) : {}} : {}),
        ...(link.opposite ? {opposite: _.map(link.opposite, process)} : {}),
    });
    // console.log('postProcess', _.cloneDeep(data), {
    //     id,
    //     links: {
    //         ...data,
    //         links: _.map(data?.links, process),
    //     },
    // });

    return {
        id,
        links: {
            ...data,
            links: _.map(data?.links, process),
        },
    };
};

/**
 * FormElementEntityLinkPermissions
 *
 * Shows a EntityLink form element, for setting permissions (but no attributes)
 *
 * If items that have been selected in a previous session are supposed to be shown
 * the `permittedObjects` field should be set in the above `ItemData`
 * @param {FormElementEntityLinkPermissionsProps} props - props of the component
 * @returns {React.ReactElement} - rendered FormElementEntityChooser
 */
function FormElementEntityLinkPermissions({
    attribute,
    availablePermissions,
    extendedPermissionGroupDefinition,
    disabled,
    linkDirection,
    defaultPermissions,
    showCostsWarning = false,
    blockSelfReference,
    permissionDependencies,
    lockedPermissions,
    schemaOptions,
    selfType,
    ...rest
}) {
    const {get, changeHandler} = useContext(FormContext);
    const [expertMode, setExpertMode] = useState(false); // indicated wether the permissions will be shown in expert mode

    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const isPortraitMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const permissionGroupDefinition = useMemo(() => _.merge(
        defaultPermissionGroupDefinitions,
        extendedPermissionGroupDefinition,
    ), [defaultPermissionGroupDefinitions, extendedPermissionGroupDefinition]);

    /**
     * Resolves permission groups to single permissions. If a single permission is provided, it will be returned
     * @param {Permission|PermissionGroup} permission - the permission group to resolve
     * @returns {Permission[]|Permission} - the flat permissions
     */
    const getPermissionsFromPermissionGroup = (permission) => {
        if (permission in permissionGroupDefinition) {
            const permissions = _.find(permissionGroupDefinition, (v, k) => k === permission);
            return _.flattenDeep(_.map(permissions, (p) => getPermissionsFromPermissionGroup(p)));
        }
        // @ts-ignore
        return permission;
    };

    /**
     * @param {ManagedObject} entityType - type of the entity
     * @returns {Array<Permission>} the permissions to be added by default
     */
    const processEntityDefaultPermissions = (entityType) => _.flattenDeep(
        _.map(defaultPermissions?.[entityType], getPermissionsFromPermissionGroup),
    );

    /**
     * @type {{
     *  value: Links
     *  displayValue: Record<string, EntityBase & {type: ManagedObject}>,
     * }}
     */
    const {value, displayValue} = useMemo(() => {
        const data = get(attribute);
        return {
            value: _.cloneDeep(data?.value) ?? {links: []},
            displayValue: data?.displayValue ?? {}, // need to be a new object for recognition of changes
        };
    }, [attribute, get]);

    /** @type {ManagedObject[]} */
    // @ts-ignore
    const availableEntities = useMemo(() => _.keys(availablePermissions), [availablePermissions]);
    const schemaOptionsMemo = useMemo(
        () => _.merge(defaultLinkSchemaOptions(linkDirection === 'left'), schemaOptions?.(linkDirection === 'left')),
        [defaultLinkSchemaOptions, schemaOptions, linkDirection],
    );
    const ownId = useMemo(() => get('id')?.value, [get]);
    const ownEntityId = useMemo(() => (ownId ? `${selfType}#${ownId}` : undefined), [ownId, selfType]);

    /**
     * @type {"rightEntityTypeId" | "leftEntityTypeId"}
     */
    const sideKey = `${linkDirection}EntityTypeId`;

    /**
     * Get's keys of the specified entity type within the current specified value
     * @param {ManagedObject} type - type of entity to get
     * @returns {string[]} ids of relevant entities
     */
    const getKeys = useCallback((type) => _.chain(value.links)
        .filter(({[sideKey]: typeId}) => typeId?.startsWith(type))
        .map(({[sideKey]: typeId}) => typeId.split('#').at(1))
        .value(), [value.links, sideKey]);

    /** @type {{ManagedObject?: EntityBase[]}} */
    const entityLinks = useMemo(() => _.zipObject(
        availableEntities,
        _.map(
            availableEntities,
            (entityType) => _.pick(displayValue, getKeys(entityType)),
        ),
    ), [displayValue, getKeys, availableEntities]);

    const expertControl = _.some(_.flatMap(availablePermissions, (perms) => _.map(perms, (p) => p in permissionGroupDefinition))) && (
        <FormControlLabel
            label="Expertenmodus"
            // margin to prevent overlap with main menu SwipeableDrawer
            style={{marginLeft: '0'}}
            control={(
                <Switch
                    data-test={`FormElementEntityChooser_${attribute}_expertswitch`}
                    checked={expertMode}
                    onClick={() => setExpertMode((current) => !current)}
                    disabled={disabled}
                />
            )}
        />
    );

    /**
     * @param {Permission|PermissionGroup} permission - permission value
     * @returns {string} - corresponding string for the type
     */
    const getPermissionLabel = (permission) => {
        const label = permissionLabels[permission];
        if (!label) {
            throw new Error(`Berechtigung ${permission} nicht gefunden`);
        }
        return label;
    };

    // eslint-disable-next-line function-paren-newline
    const updatePermissionDependencies = useCallback(
        /**
         * @param {object} item - entity that should be permitted
         * @param {Permission} permission - permission to be added or removed from the entity
         * @param {boolean} checked - indicates wether the permission should be set or removed
         * @param {string[]} entityPermissions - current permissions
         * @returns {string[]} - updated permissions
         */
        (item, permission, checked, entityPermissions) => {
            let newEntityPermissions = entityPermissions;
            /** @type {Partial<Record<Permission, Array<Permission>>>} */
            const dependencies = _.get(permissionDependencies, item.type);
            if (checked) {
                const hasPeer = _.get(dependencies, permission);
                _.forEach(hasPeer, (dependency) => {
                    newEntityPermissions.push(dependency);
                    newEntityPermissions = updatePermissionDependencies(item, dependency, checked, newEntityPermissions);
                });
            } else {
                const isPeerOf = _.filter(_.map(dependencies, (d, b) => [b, d]), ([, d]) => d.includes(permission));
                _.forEach(isPeerOf, ([base]) => {
                    _.remove(newEntityPermissions, (p) => p === base);
                    // @ts-ignore
                    newEntityPermissions = updatePermissionDependencies(item, base, checked, newEntityPermissions);
                });
            }
            return _.uniq(newEntityPermissions);
        }, [permissionDependencies]);

    // eslint-disable-next-line function-paren-newline
    const isPermissionSet = useCallback(
        /**
         * @param {object} item - entity that should be permitted
         * @param {Permission|PermissionGroup} permission - permission to check
         * @returns {number} - indicator if the permission is set, unset or partially set
         */
        (item, permission) => {
            if (permission in permissionGroupDefinition) {
                const permissions = _.map(_.get(permissionGroupDefinition, permission), (p) => isPermissionSet(item, p));
                if (_.every(permissions)) {
                    return 1;
                }
                if (_.some(permissions)) {
                    return 2;
                }
                return 0;
            }
            const entityValueIndex = _.findIndex(value.links, (link) => link[sideKey] === `${item.type}#${item.id}`);
            if (entityValueIndex === -1) {
                return 0;
            }
            const entityValue = value.links[entityValueIndex];
            return entityValue.permissions.includes(permission) ? 1 : 0;
        }, [value.links, sideKey, permissionDependencies]);

    // eslint-disable-next-line function-paren-newline
    const setPermission = useCallback(
    /**
     * @param {object} item - entity that should be permitted
     * @param {Permission|PermissionGroup} permission - permission to be added or removed from the entity
     * @param {boolean} checked - indicates wether the permission should be set or removed
     */
        (item, permission, checked) => {
            if (permission in permissionGroupDefinition) {
                _.forEach(_.get(permissionGroupDefinition, permission), (p) => setPermission(item, p, checked));
                return;
            }
            const currentLinks = value.links;

            const entityValueIndex = _.findIndex(currentLinks, (link) => link[sideKey] === `${item.type}#${item.id}`);

            // permission not found, but should be removed -> error
            if (entityValueIndex === -1 && !checked) {
                throw new Error('Die Entität wurde noch nicht zugeordnet, die Berechtigung kann nicht entfernt werden!');
            }

            // new entity in the list
            if (entityValueIndex === -1) {
                let entityPermissions = [permission];
                // @ts-ignore
                entityPermissions = updatePermissionDependencies(item, permission, checked, entityPermissions);

                currentLinks.push({[sideKey]: `${item.type}#${item.id}`, permissions: entityPermissions});
                // displayValue = {...displayValue, item;
            } else {
                const entityValue = currentLinks[entityValueIndex];
                let entityPermissions = entityValue.permissions;

                if (!checked) {
                // remove permission from entity permissions list
                    _.pull(entityPermissions, permission);
                } else {
                // add permission to entity permissions list
                // entityPermissions = _.union(entityValue.permissions, [permission]);
                    entityPermissions.push(permission);
                }
                // @ts-ignore
                entityPermissions = updatePermissionDependencies(item, permission, checked, entityPermissions);
                _.set(currentLinks[entityValueIndex], 'permissions', entityPermissions);
            }

            // populate changes
            changeHandler({
                attribute,
                value: {
                    ...value,
                    permissions: Array.from(value.links),
                },
                interacted: true,
            });
        }, [changeHandler, permissionGroupDefinition, value, attribute, sideKey]);

    /**
     * @param {object} item - entity that should be permitted
     * @param {Permission|PermissionGroup} permission - actual permission that can be activated
     * @param {boolean} locked - disable the checkbox
     * @param {'label' | 'cell'} type - type of return rendering mode
     * @returns {import('react').ReactNode} - checkbox, according to the type of the line
     */
    const getPermissionLineCheckbox = useCallback((item, permission, locked, type) => {
        if (permission in permissionGroupDefinition && expertMode) {
            return _.map(_.get(permissionGroupDefinition, permission), (p) => getPermissionLineCheckbox(item, p, locked, type));
        }
        const permSet = isPermissionSet(item, permission);
        const cb = (
            <Checkbox
                disabled={locked}
                checked={!!permSet ?? false}
                indeterminate={permSet === 2}
                onChange={(e) => setPermission(item, permission, e.target.checked)}
                data-test={`FormElementEntityChooser_${attribute}_${permission}`}
            />
        );
        if (type === 'label') {
            return (
                <FormControlLabel
                    key={permission}
                    control={cb}
                    label={getPermissionLabel(permission)}
                />
            );
        }
        return (
            <TableCell key={permission} padding="checkbox" align="center">
                {cb}
            </TableCell>
        );
    }, [expertMode, permissionGroupDefinition, isPermissionSet, setPermission, getPermissionLabel]);

    const getEntityLine = useCallback(
        (item, type, entityDisabled) => {
            const entityValue = _.find(value.links, (link) => link[sideKey] === `${item.type}#${item.id}`);
            if (!entityValue) {
                throw new Error(`No value could be found for ${sideKey}`);
            }
            let locked = disabled;
            locked ||= entityDisabled;
            locked ||= entityValue.attributes?.locked ?? false;
            locked ||= (blockSelfReference && ownEntityId === entityValue[sideKey]);

            let avatarImgStyle = {};
            const position = item.image?.options?.image?.position;
            if (position) {
                const x = (position.x / position.width) * -40;
                const y = (position.y / position.height) * -40;
                avatarImgStyle = {
                    height: 40 / position.height,
                    width: 40 / position.width,
                    objectPosition: `${x}px ${y}px`,
                };
            }

            const permissionsToRenderCheckboxesFor = _.uniq(
                expertMode
                    ? _.flatMap(
                        availablePermissions[type],
                        (permission) => ((permission in permissionGroupDefinition) ? permissionGroupDefinition[permission] : [permission]),
                    )
                    : availablePermissions[type],
            );

            const availablePermissionLabels = _.map(
                permissionsToRenderCheckboxesFor,
                (permission) => getPermissionLineCheckbox(item, permission, locked ?? lockedPermissions?.includes(permission), 'label'),
            );
            const availablePermissionCell = _.map(
                permissionsToRenderCheckboxesFor,
                (permission) => getPermissionLineCheckbox(item, permission, locked ?? lockedPermissions?.includes(permission), 'cell'),
            );

            const entityDataSchema = _.get(dataSchemas, type)(schemaOptionsMemo[type]);

            return (
                <TableRow
                    key={item.id}
                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                >
                    <TableCell>
                        <Box style={{display: 'flex', gap: '1rem'}}>
                            {(!isPortraitMobile && _.has(item, 'image'))
                            && (
                                <Box
                                    style={{
                                        height: '40px',
                                        width: '40px',
                                        overflow: 'hidden',
                                        borderRadius: '50%',
                                    }}
                                >
                                    <Box
                                        component="img"
                                        alt={item.id}
                                        src={item.image?.url}
                                        data-test={`FormElementEntityChooser_${attribute}_avatar`}
                                        style={{
                                            objectFit: 'cover',
                                            ...avatarImgStyle,
                                            display: item.image?.url ? 'inherit' : 'none',
                                        }}
                                    >
                                        {/* {_.get(avatarDefault, type, <HelpOutline />)} */}
                                    </Box>
                                </Box>
                            )}
                            <Box style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                <Typography fontWeight="bold">{entityDataSchema.getPrimaryValue(item)}</Typography>
                                {entityDataSchema.getSecondaryValue
                                && <Typography paddingLeft="1rem" fontSize="0.9rem">{entityDataSchema.getSecondaryValue(item)}</Typography>}
                            </Box>
                        </Box>
                        {!isDesktop
                        && (
                            <Box>
                                <FormGroup row>{availablePermissionLabels}</FormGroup>
                            </Box>
                        )}
                    </TableCell>
                    {isDesktop && availablePermissionCell}
                </TableRow>
            );
        },
        [disabled, isDesktop, isPortraitMobile, dataSchemas, value.links, schemaOptionsMemo,
            // lockedPermissions, setPermission,
            getPermissionLabel,
            // getPermissionLineCheckbox,
            sideKey, blockSelfReference, ownEntityId,
            // permissionDirection
        ],
    );

    // eslint-disable-next-line function-paren-newline
    const getEntityTable = useCallback(
        /**
         *
         * @param {EntityBase[]} entities - entities to render
         * @param {ManagedObject} entityType - entity type
         * @returns {React.ReactElement} - table for the entity type
         */
        (entities, entityType) => {
            const permissionsToRender = expertMode
                ? _.flatMap(availablePermissions[entityType], (permission) => (permission in permissionGroupDefinition
                    ? permissionGroupDefinition[permission]
                    : [permission]))
                : availablePermissions[entityType];

            const permissionTitles = _.uniq(permissionsToRender).map((permission) => (
                <TableCell key={permission} align="center">
                    <Typography fontWeight="bold">{getPermissionLabel(permission)}</Typography>
                </TableCell>
            ));
            if (!_.get(dataSchemas, entityType)) {
                // eslint-disable-next-line no-console
                console.warn('schema missing', entityType);
                return null;
            }
            const entityDataSchema = _.get(dataSchemas, entityType)(schemaOptionsMemo[entityType]);
            if (_.isEmpty(entities)) {
                return null;
            }
            return (
                <TableContainer
                    key={entityType}
                    component={Paper}
                    data-test={`FormElementEntityChooser_${attribute}_table_${_.camelCase(entityType)}`}
                >
                    <Table size="small">
                        <TableHead>
                            <TableRow sx={{backgroundColor: 'secondary.main'}}>
                                <TableCell>
                                    <Typography fontWeight="bold">{entityDataSchema.label}</Typography>
                                </TableCell>
                                {isDesktop && permissionTitles}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {_.map(entities, (item) => getEntityLine(item, entityType, entityDataSchema.isDisabled?.(item)))}
                        </TableBody>
                    </Table>
                </TableContainer>
            );
        }, [dataSchemas, isDesktop, availablePermissions, schemaOptionsMemo, expertMode, getEntityLine]);

    return (
        <Box>
            {showCostsWarning && (
                <Alert severity="warning" style={{marginBottom: '2rem'}}>
                    <Typography textAlign="justify">
                        Dieses Objekt hat potentiell Zugriff auf Module und Anwendungen!
                        Hinzufügen oder Entfernen von Berechtigungsobjekten verändert Zugriffsberechtigungen und die Anzahl an kostenpflichtigen Lizenzen.
                    </Typography>
                </Alert>
            )}
            <FormElementEntityLink
                attribute={attribute}
                linkDirection={linkDirection}
                availableEntities={availableEntities}
                disabled={disabled}
                controls={expertControl}
                blockSelfReference={blockSelfReference}
                schemaOptions={schemaOptionsMemo}
                processEntityDefaultPermissions={processEntityDefaultPermissions}
                // guideId="beyondbuddy_general_permissions"
                {...rest}
            />
            <Box style={{display: 'flex', flexDirection: 'column', gap: '1rem'}}>
                {_.map(entityLinks, getEntityTable)}
            </Box>
        </Box>
    );
}

/** @type {PermissionDependency} */
const readUpdateDeletePermissionTemplate = {
    updateAttributes: ['readAttributes'],
    updatePermission: ['readPermission'],
    readAttributes: ['read'],
    readPermission: ['read'],
    delete: ['read', 'update'],
    update: ['read'],
};

export {
    FormElementEntityLinkPermissions,
    preProcess as preProcessLinks,
    postProcess as postProcessLinks,
    readUpdateDeletePermissionTemplate,
};
