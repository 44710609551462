import React from 'react';
import {Box, Breadcrumbs, Typography} from '@mui/material';

import classes from 'components/Form/FormHeadContainer.module.scss';
import _ from 'lodash';
import {RouteLink} from 'components/RouteLink';

/**
 * This page shows the form header with two possible areas.
 * The two areas stand side by side on desktop, but over and under on mobile.
 * The first area grows and consumes the available space.
 * The second area is considered for icons.
 * @param {object} props - the properties
 * @param {React.ReactNode} props.children - the two children
 * @param {Array<{label: string, routeId?: string}>} [props.breadCrumbs] - optional Breadcrumbs
 * @returns {React.ReactNode} The TabFormsContainer component.
 */
function FormHeadContainer({children, breadCrumbs}) {
    const [firstChild, secondChild] = React.Children.toArray(children).slice(0, 2);

    return (
        <>
            <Box data-test="form-head-container" className={classes.containerWrap}>
                {firstChild && React.isValidElement(firstChild) && React.cloneElement(firstChild, {className: classes.firstContainer})}
                {secondChild && React.isValidElement(secondChild) && React.cloneElement(secondChild, {className: classes.iconsContainer})}
            </Box>
            {breadCrumbs && (
                <Breadcrumbs aria-label="breadcrumb">
                    {_.map(breadCrumbs, (breadCrumb, key) => {
                        if (breadCrumb.routeId) {
                            return <RouteLink routeId={breadCrumb.routeId} key={key}><Typography color="secondary">{breadCrumb.label}</Typography></RouteLink>;
                        }
                        return <Typography key={key}>{breadCrumb.label}</Typography>;
                    })}
                </Breadcrumbs>
            )}
        </>
    );
}
export {FormHeadContainer};
