import React, {
    useCallback,
} from 'react';
import _ from 'lodash';
import {FormContext, FormWrapper} from 'components/Form/FormWrapper';
import {
    Box, Grid,
} from '@mui/material';
import {FormElementImageContainer} from 'components/Form/FormElements/FormElementImageContainer';
import {FormElementTextField} from 'components/Form/FormElements/FormElementTextField';
import {FormElementLoadingButton} from 'components/Form/FormElements/FormElementLoadingButton';
import {FormElementAutocomplete} from 'components/Form/FormElements/FormElementAutocomplete';

import {autocompleteListOrganizationalUnits} from 'graphql/beyondBuddy/OrganizationalUnit/queries';

import groupImg from 'applications/beyondbuddy/settings/pages/images/AdobeStock_77507676.jpg';
import {FormElementInfoChips} from 'components/Form/FormElements/FormElementInfoChips';
import {FormElementActionButton} from 'components/Form/FormElements/FormElementActionButton';
import {AddCircleOutline, Refresh} from '@mui/icons-material';
import {useCanAccess} from 'hooks/useCanAccess';
import {FormReset} from 'components/Form/FormReset';

const {schema: validatorSchema} = require('beyond-validators/beyondBuddy/OrganizationalUnit');

const parentOrganizationalUnitDataSchema = {
    query: autocompleteListOrganizationalUnits,
    queryVariablesMask: {tenantId: true},
    queryVariables: {global: {tenantId: 'tenantId'}},
    dataKey: 'id',
    getOptionLabel: (option) => option?.name,
    getOptionValue: (option) => option?.id,
};

/**
 * The tenant formular for creating and updating a tenant
 * @param {import('applications/configuration').EntityFormularProps} props - props passed to the component
 * @returns {React.ReactElement} The OrganizationalUnitFormular component.
 */
function OrganizationalUnitFormular({id, onSave, ...rest}) {
    const canCreateOrganizationalUnits = useCanAccess('createOrganizationalUnit');

    return (
        <FormWrapper
            {...rest}
            isNewItem={id === 'create'}
            validatorSchema={{
                schema: validatorSchema,
                type: (id !== 'create') ? 'update' : 'create',
            }}
            onSaveCallback={(result) => {
                if (_.isFunction(onSave)) {
                    onSave(result);
                }
            }}
            messageKey={(id !== 'create') ? 'OrganizationalUnit_Update' : 'OrganizationalUnit_Create'}
            context={`OrganizationalUnit#${id}Base`}
        >
            <FormReset shouldClear={id === 'create'} />
            <Box style={{
                display: 'flex',
                gap: '1rem',
                flexWrap: 'wrap',
                justifyContent: 'center',
            }}
            >

                <FormElementActionButton
                    routeId="beyondbuddy_settings_general_organizationalunit_route"
                    routeParams={{id: 'create'}}
                    portalAnchorSelector={`#OrganizationalUnitForm${id}action-button-frame-base-actions`}
                    formWrapperIdPattern={`OrganizationalUnit#${id}`}
                    disabled={id === 'create' || !canCreateOrganizationalUnits}
                    context={FormContext}
                >
                    <AddCircleOutline />
                </FormElementActionButton>
                <FormElementActionButton
                    reload
                    portalAnchorSelector={`#OrganizationalUnitForm${id}action-button-frame-base-actions`}
                    formWrapperIdPattern={`OrganizationalUnit#${id}`}
                    disabled={id === 'create'}
                    context={FormContext}
                >
                    <Refresh />
                </FormElementActionButton>
                <FormElementImageContainer defaultImg={groupImg} alt="Organizational Unit" edit />
                <Box style={{flexGrow: 1, flexShrink: 1, flexBasis: '450px'}}>
                    <Grid container spacing={2}>
                        <FormElementInfoChips showReadonly />
                        <Grid item xs={12}>
                            <FormElementTextField label="Name" attribute="name" />
                        </Grid>
                        <Grid item xs={12}>
                            <FormElementAutocomplete
                                label="Übergeordnete Organisationseinheit"
                                attribute="parentOrganizationalUnitId"
                                optionReference="parentOrganizationalUnit"
                                dataSchema={parentOrganizationalUnitDataSchema}
                                optionsFilter={useCallback((option) => option?.id !== id, [id])}
                                data-test="FormElementAutocomplete_parentOrganizationalUnitId"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormElementLoadingButton />
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </FormWrapper>
    );
}

export {OrganizationalUnitFormular};
