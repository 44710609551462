import React from 'react';
import _ from 'lodash';
import {FormContext, FormWrapper} from 'components/Form/FormWrapper';
import {
    Alert,
    Box, Divider, Grid,
} from '@mui/material';
import {FormElementTextField} from 'components/Form/FormElements/FormElementTextField';
import {FormElementLoadingButton} from 'components/Form/FormElements/FormElementLoadingButton';

import {FormElementInfoChips} from 'components/Form/FormElements/FormElementInfoChips';
import {FormElementActionButton} from 'components/Form/FormElements/FormElementActionButton';
import {
    AddCircleOutline, Refresh,
} from '@mui/icons-material';
import {useCanAccess} from 'hooks/useCanAccess';
import {FormReset} from 'components/Form/FormReset';
import {FormElementFilesUpload} from 'components/Form/FormElements/FormElementFilesUpload';
import {FormElementAutocomplete} from 'components/Form/FormElements/FormElementAutocomplete';
import {countryCodeDataSchema} from 'helper/CountrySchema';
import {genderDataSchema} from 'helper/GenderSchema';
import {FormElementDatePicker} from 'components/Form/FormElements/FormElementDatePicker';
import {FormElementCheckbox} from 'components/Form/FormElements/FormElementCheckbox';
import {FormElementContainer} from 'components/Form/FormElements/FormElementContainer';

const {schema: validatorSchema} = require('beyond-validators/peaceBuddy/DeceasedPerson');

/**
 * The deceasedPerson formular for creating and updating a deceasedPerson
 * @param {import('applications/configuration').EntityFormularProps} props - props passed to the component
 * @returns {React.ReactElement} The DeceasedPersonFormular component.
 */
function DeceasedPersonFormular({id, onSave, ...rest}) {
    const isNewItem = id === 'create';
    const canCreateCemeteries = useCanAccess('createDeceasedPerson');

    return (
        <FormWrapper
            {...rest}
            isNewItem={isNewItem}
            validatorSchema={{
                schema: validatorSchema,
                type: !isNewItem ? 'update' : 'create',
            }}
            onSaveCallback={(result) => {
                if (_.isFunction(onSave)) {
                    onSave(result);
                }
            }}
            messageKey={(id !== 'create') ? 'DeceasedPerson_Update' : 'DeceasedPerson_Create'}
            context={`DeceasedPerson#${id}Base`}
        >
            <FormReset shouldClear={isNewItem} />
            <Box style={{
                display: 'flex',
                gap: '1rem',
                flexWrap: 'wrap',
                justifyContent: 'center',
            }}
            >
                <FormElementActionButton
                    routeId="peacebuddy_settings_deceasedPerson_route"
                    routeParams={{id: 'create'}}
                    portalAnchorSelector={`#DeceasedPersonForm${id}action-button-frame-base-actions`}
                    disabled={isNewItem || !canCreateCemeteries}
                    context={FormContext}
                >
                    <AddCircleOutline />
                </FormElementActionButton>
                <FormElementActionButton
                    reload
                    portalAnchorSelector={`#DeceasedPersonForm${id}action-button-frame-base-actions`}
                    disabled={isNewItem}
                    context={FormContext}
                >
                    <Refresh />
                </FormElementActionButton>
                <Grid container spacing={2}>
                    <FormElementInfoChips showReadonly />
                    <Grid item xs={6} sm={6} md={3}>
                        <FormElementTextField label="Referenz" attribute="referenceId" />
                    </Grid>
                    <Grid item xs={6} sm={6} md={3}>
                        <FormElementAutocomplete
                            attribute="gender"
                            label="Geschlecht"
                            dataSchema={genderDataSchema}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={3}>
                        <FormElementTextField label="Titel (vorangestellt)" attribute="titlePrefixed" />
                    </Grid>
                    <Grid item xs={6} sm={6} md={3}>
                        <FormElementTextField label="Titel (nachgestellt)" attribute="titlePostfixed" />
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                        <FormElementTextField label="Vorname" attribute="firstName" />
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                        <FormElementTextField label="Nachname" attribute="lastName" />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormElementAutocomplete dataSchema={countryCodeDataSchema} attribute="nationalityCountryCode" label="Nationalität" />
                    </Grid>
                    <Grid item xs={12}><Divider /></Grid>
                    <Grid item xs={6} sm={6} md={4}>
                        <FormElementDatePicker label="Geburtsdatum" attribute="dateOfBirth" />
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                        <FormElementTextField label="Geburtsort" attribute="placeOfBirth" />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormElementAutocomplete dataSchema={countryCodeDataSchema} attribute="placeOfBirthCountryCode" label="Geburtsland" />
                    </Grid>
                    <Grid item xs={12}><Divider /></Grid>
                    <Grid item xs={6} sm={6} md={4}>
                        <FormElementDatePicker label="Sterbedatum" attribute="dateOfDeath" />
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                        <FormElementTextField label="Sterbeort" attribute="placeOfDeath" />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormElementAutocomplete dataSchema={countryCodeDataSchema} attribute="placeOfDeathCountryCode" label="Sterbeland" />
                    </Grid>
                    <Grid item xs={12}><Divider /></Grid>
                    <Grid item xs={6} sm={4} md={2}>
                        <FormElementCheckbox label="Totgeburt" attribute="stillbirth" />
                    </Grid>
                    <Grid item xs={6} sm={4} md={2}>
                        <FormElementCheckbox label="Beigesetzt" attribute="burried" />
                    </Grid>
                    <Grid item xs={12}><Divider /></Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                        <FormElementContainer
                            attribute="cremation"
                            propsMapping={(props) => ({
                                disabled: !props.value,
                            })}
                        >
                            <FormElementTextField label="Aschekapselnummer" attribute="ashCapsuleNr" />
                        </FormElementContainer>
                    </Grid>
                    <Grid item xs={4} sm={6} md={2}>
                        <FormElementCheckbox label="Feuerbestattung" attribute="cremation" />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                        <FormElementContainer
                            attribute="homeStorage"
                            propsMapping={(props) => ({
                                disabled: !props.value,
                            })}
                        >
                            <FormElementTextField label="Urnenaufbewahrungsort" attribute="ashCapsuleLocation" />
                        </FormElementContainer>
                    </Grid>
                    <Grid item xs={4} sm={6} md={2}>
                        <FormElementCheckbox label="Heimaufbewahrung" attribute="homeStorage" />
                    </Grid>
                    <Grid item xs={12}>
                        <FormElementTextField label="Vermerk" attribute="notes" />
                    </Grid>
                    <Grid item xs={12}>
                        <FormElementFilesUpload />
                    </Grid>
                    {isNewItem && (
                        <Grid item xs={12}>
                            <Alert severity="warning">
                            Nach dem Erstellen wird automatisch der gesamte Mandant berechtigt den Verstorbenen zu lesen und zu bearbeiten.
                            Sollte die Nutzung eingeschränkt werden, so kann dies nach dem Anlegen im Bereich
                                {' '}
                                <i>Eingehende Berechtigungen</i>
                                {' '}
                            verwaltet werden.
                            </Alert>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <FormElementLoadingButton />
                    </Grid>
                </Grid>
            </Box>
        </FormWrapper>
    );
}

export {DeceasedPersonFormular};
