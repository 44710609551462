// eslint-disable-next-line no-restricted-imports
import {FileInformationFragment, PermissionEntitiesFragment} from '../../beyondBuddy/fragments';

export const createGrave = /* GraphQL */ `
  mutation CreateGrave(
      $tenantId: ID!
      $cemeteryId: ID
      $generalNr: String!
      $type: String!
      $division: String
      $subDivision: String
      $nr: String
      $lastFuneral: AWSDateTime
      $restPeriod: Int!
      $leaseStartDate: AWSDateTime
      $leaseExpirationDate: AWSDateTime
      $honoraryGrave: Boolean
      $free: Boolean
      $fellHome: Boolean
      $choosable: Boolean
      $unitPositions: AWSJSON!
      $notes: String
      $imageKey: String
      $attachmentKeys: AWSJSON
    ) {
    createGrave(
      tenantId: $tenantId
      cemeteryId: $cemeteryId
      generalNr: $generalNr
      type: $type
      division: $division
      subDivision: $subDivision
      nr: $nr
      lastFuneral: $lastFuneral
      restPeriod: $restPeriod
      leaseStartDate: $leaseStartDate
      leaseExpirationDate: $leaseExpirationDate
      honoraryGrave: $honoraryGrave
      free: $free
      fellHome: $fellHome
      choosable: $choosable
      unitPositions: $unitPositions
      notes: $notes
      imageKey: $imageKey
      attachmentKeys: $attachmentKeys
    ) {
      id
      tenantId
      cemeteryId
      generalNr
      type
      division
      subDivision
      nr
      lastFuneral
      restPeriod
      leaseStartDate
      leaseExpirationDate
      honoraryGrave
      free
      fellHome
      choosable
      unitPositions
      notes
      imageKey
      image {
        ...FileInformation
      }
      attachments{
        key
        filename
        description
        url
      }
      grants {
        updatable
        permissionsReadable
        permissionsUpdatable
        attributesReadable
        attributesUpdatable
      }
    }
  }
  ${FileInformationFragment}
`;

export const updateGrave = /* GraphQL */ `
  mutation UpdateGrave(
      $id: ID!
      $cemeteryId: ID
      $generalNr: String!
      $type: String!
      $division: String
      $subDivision: String
      $nr: String
      $lastFuneral: AWSDateTime
      $restPeriod: Int!
      $leaseStartDate: AWSDateTime
      $leaseExpirationDate: AWSDateTime
      $honoraryGrave: Boolean
      $free: Boolean
      $fellHome: Boolean
      $choosable: Boolean
      $unitPositions: AWSJSON!
      $notes: String
      $imageKey: String
      $attachmentKeys: AWSJSON
    ) {
    updateGrave(
      id: $id
      cemeteryId: $cemeteryId
      generalNr: $generalNr
      type: $type
      division: $division
      subDivision: $subDivision
      nr: $nr
      lastFuneral: $lastFuneral
      restPeriod: $restPeriod
      leaseStartDate: $leaseStartDate
      leaseExpirationDate: $leaseExpirationDate
      honoraryGrave: $honoraryGrave
      free: $free
      fellHome: $fellHome
      choosable: $choosable
      unitPositions: $unitPositions
      notes: $notes
      imageKey: $imageKey
      attachmentKeys: $attachmentKeys
    ) {
      id
      tenantId
      cemeteryId
      generalNr
      type
      division
      subDivision
      nr
      lastFuneral
      restPeriod
      leaseStartDate
      leaseExpirationDate
      honoraryGrave
      free
      fellHome
      choosable
      unitPositions
      coffinGrave
      urnGrave
      notes
      imageKey
      image {
        ...FileInformation
      }
      attachments{
        key
        filename
        description
        url
      }
      grants {
        updatable
        permissionsReadable
        permissionsUpdatable
        attributesReadable
        attributesUpdatable
      }
    }
  }
  ${FileInformationFragment}
`;

export const deleteGrave = /* GraphQL */ `
 mutation DeleteGrave(
     $id: ID!
   ) {
   deleteGrave(
     id: $id,
     ) {
      id
   }
 }
`;

export const deleteGraves = /* GraphQL */ `
 mutation DeleteGraves(
     $ids: [ID!]
   ) {
   deleteGraves(
     ids: $ids,
     )
 }
`;

/**
 * updateLinks
 * @property {string} entityTypeId - entityTypeId.
 * @property {Array} permissions - new permissions.
 * @returns {object} The requested permission info.
 */
export const updateGraveConnections = /* GraphQL */ `
  mutation UpdateGraveConnections(
    $entityTypeId: String!
    $links: [LinksInput!]!
    $isIncoming: Boolean
    $deleteLinks: Boolean
    $context: String
    $leftEntityProtocolInput: SimpleProtocolEntryInputOperation
    $rightEntityProtocolInput: SimpleProtocolEntryInputOperation
    $protocolEntityReplacementData: AWSJSON
    ) {
      updateGraveConnections(
      entityTypeId: $entityTypeId
      links: $links
      isIncoming: $isIncoming
      deleteLinks: $deleteLinks
      context: $context
      leftEntityProtocolInput: $leftEntityProtocolInput
      rightEntityProtocolInput: $rightEntityProtocolInput
      protocolEntityReplacementData: $protocolEntityReplacementData
    ) {
      links {
        leftEntityTypeId
        rightEntityTypeId
        permissions
        attributes
      }
      ...PermissionEntities
    }
  }
  ${PermissionEntitiesFragment}
`;
