import React, {
    useCallback,
} from 'react';
import _ from 'lodash';
import {FormContext, FormWrapper} from 'components/Form/FormWrapper';
import {FormElementTextField} from 'components/Form/FormElements/FormElementTextField';
import {FormElementAutocomplete} from 'components/Form/FormElements/FormElementAutocomplete';
import {FormElementLoadingButton} from 'components/Form/FormElements/FormElementLoadingButton';
import {
    Box, Chip, Collapse, Grid,
} from '@mui/material';
import userImg from 'applications/beyondbuddy/settings/pages/images/AdobeStock_417452843.jpg';
import {FormElementImageContainer} from 'components/Form/FormElements/FormElementImageContainer';
import {listUserOptions} from 'graphql/beyondBuddy/User/queries';
import {autocompleteListOrganizationalUnits} from 'graphql/beyondBuddy/OrganizationalUnit/queries';
import {FormElementSwitch} from 'components/Form/FormElements/FormElementSwitch';
import {useGlobalState} from 'hooks/useGlobalState';
import {FormElementInfoChips} from 'components/Form/FormElements/FormElementInfoChips';
import {AddCircleOutline, Refresh} from '@mui/icons-material';
import {FormElementActionButton} from 'components/Form/FormElements/FormElementActionButton';
import {useCanAccess} from 'hooks/useCanAccess';
import {FormReset} from 'components/Form/FormReset';
import {FormElementDatePicker} from 'components/Form/FormElements/FormElementDatePicker';
import {FormElementContainer} from 'components/Form/FormElements/FormElementContainer';
import {QuickGuideLink} from 'components/QuickGuide/QuickGuideLink';

const {schema: validatorSchema} = require('beyond-validators/beyondBuddy/User');

const lineManagerDataSchema = {
    query: listUserOptions,
    queryVariablesMask: {tenantId: true},
    queryVariables: {global: {tenantId: 'tenantId'}},
    dataKey: 'id',
    getOptionLabel: (option) => _.compact([option?.contactFirstName, option?.contactLastName]).join(' '),
    getOptionValue: (option) => option?.id,
};

const organizationalUnitDataSchema = {
    query: autocompleteListOrganizationalUnits,
    queryVariablesMask: {tenantId: true},
    queryVariables: {global: {tenantId: 'tenantId'}},
    dataKey: 'id',
    getOptionLabel: (option) => option?.name,
    getOptionValue: (option) => option?.id,
};

/**
 * The user formular for creating and updating a user
 * @param {import('applications/configuration').EntityFormularProps} props - props passed to the component
 * @returns {React.ReactElement} The UserFormular component.
 */
function UserFormular({id, onSave, ...rest}) {
    const isNewItem = id === 'create';
    const {getGlobal} = useGlobalState();
    const currentUser = getGlobal('user');
    const isSelf = id === currentUser?.id;

    // const processChange = useCallback((data) => {
    //     const grants = data?.grants;
    //     setShowPermissions(grants?.permissionsReadable ?? false);
    //     setEnablePermissions(grants?.permissionsUpdatable ?? false);
    //     const subGrants = data?.subGrants;
    //     setShowWorkingTimeModelAssignment(subGrants?.workingTimeModelAssignmentReadable ?? false);
    //     setWorkingTimeLogsReadable(subGrants?.workingTimeLogsReadable ?? false);
    //     setWorkingTimeAggregateCorrectable(subGrants?.workingTimeAggregateCorrectable ?? false);
    // }, [setEnablePermissions, setShowPermissions]);

    const canCreate = useCanAccess('createUser');

    const refreshUserProfile = getGlobal('refreshUserProfile');

    return (
        <FormWrapper
            {...rest}
            isNewItem={isNewItem}
            validatorSchema={{
                schema: validatorSchema,
                type: (!isNewItem) ? 'update' : 'create',
            }}
            onSaveCallback={(result) => {
                // Refreshing user since it might have changed
                refreshUserProfile?.();
                if (_.isFunction(onSave)) {
                    onSave(result);
                }
            }}
            messageKey={(!isNewItem) ? 'User_Update' : 'User_Create'}
            context={`User${id}`}
        >
            <FormReset shouldClear={isNewItem} />
            <Box style={{
                display: 'flex',
                gap: '1rem',
                flexWrap: 'wrap',
                justifyContent: 'center',
            }}
            >
                <FormElementActionButton
                    routeId="beyondbuddy_settings_general_user_route"
                    routeParams={{id: 'create'}}
                    portalAnchorSelector={`#UserForm${id}action-button-frame-base-actions`}
                    formWrapperIdPattern={`User#${id}`}
                    disabled={isNewItem || !canCreate}
                    context={FormContext}
                >
                    <AddCircleOutline />
                </FormElementActionButton>
                <FormElementActionButton
                    reload
                    portalAnchorSelector={`#UserForm${id}action-button-frame-base-actions`}
                    formWrapperIdPattern={`User#${id}`}
                    disabled={isNewItem}
                    context={FormContext}
                >
                    <Refresh />
                </FormElementActionButton>
                <FormElementImageContainer defaultImg={userImg} alt="User" edit onSave={onSave} />
                <Box style={{flexGrow: 1, flexShrink: 1, flexBasis: '450px'}}>
                    <Grid container spacing={2}>
                        <FormElementInfoChips showDraft>
                            <FormContext.Consumer>
                                {({get}) => (
                                    <Collapse in={get('notVerified').value}>
                                        <Chip label="Nicht Verifiziert" />
                                    </Collapse>
                                )}

                            </FormContext.Consumer>
                        </FormElementInfoChips>
                        <Grid item xs={12} md={6}>
                            <FormElementTextField label="Vorname" attribute="contactFirstName" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormElementTextField label="Familienname" attribute="contactLastName" />
                        </Grid>
                        <Grid item xs={12}>
                            <FormElementTextField label="Email" attribute="contactEMailAddress" disabled={id !== 'create'} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormElementTextField
                                label="Personal Nummer"
                                attribute="personalNumber"
                                readOnly={isSelf && !currentUser?.tenantAdministrator}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormElementAutocomplete
                                label="Personalverantwortliche(r)"
                                attribute="lineManagerUserId"
                                optionReference="lineManager"
                                dataSchema={lineManagerDataSchema}
                                optionsFilter={useCallback((option) => option?.id !== id, [id])}
                                readOnly={isSelf && !currentUser?.tenantAdministrator}
                                data-test="FormElementAutocomplete_lineManagerUserId"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormElementAutocomplete
                                label="Organisationseinheit"
                                attribute="organizationalUnitId"
                                optionReference="organizationalUnit"
                                dataSchema={organizationalUnitDataSchema}
                                optionsFilter={useCallback((option) => option?.id !== id, [id])}
                                readOnly={isSelf && !currentUser?.tenantAdministrator}
                                data-test="FormElementAutocomplete_organizationalUnitId"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormElementDatePicker
                                attribute="entryDate"
                                label="Eintrittsdatum"
                                readOnly={isSelf && !currentUser?.tenantAdministrator}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormElementDatePicker
                                attribute="exitDate"
                                label="Austrittsdatum"
                                readOnly={isSelf && !currentUser?.tenantAdministrator}
                                minDateAttribute="entryDate"
                            />
                        </Grid>
                        <Grid item xs={6} display="flex">
                            <FormElementSwitch
                                attribute="active"
                                label="Aktiv"
                                defaultTrue
                                disabled={isSelf}
                            />
                            <QuickGuideLink id="beyondbuddy_general_user_active" />
                        </Grid>
                        <Grid item xs={6}>
                            <FormElementSwitch
                                attribute="tenantAdministrator"
                                label="Mandantenadministrator"
                                disabled={!currentUser?.tenantAdministrator}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormElementSwitch
                                attribute="cognito"
                                label="Anmelden per Email+Passwort"
                                defaultTrue={!getGlobal('authProviders')?.includes('saml')}
                                disabled={isSelf && !currentUser?.tenantAdministrator}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormElementSwitch
                                attribute="emailNotifications"
                                label="Notifikationen per Email Erhalten"
                                defaultTrue
                                disabled={!isSelf}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormElementLoadingButton />
                        </Grid>
                        <Grid item xs={12}>
                            <FormElementContainer attribute="cognito" conditionalRender={({value}) => value && isNewItem}>
                                        Bitte beachten Sie, dass falls die Registrierung nicht innerhalb von drei Tagen abgeschlossen ist, das temporäre Password abläuft
                                        und der Benutzer auf Inaktiv gesetzt wird.
                                <br />
                                        Um eine neue Einladung zu verschicken müssten sie den User wieder auf aktiv setzen.
                            </FormElementContainer>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </FormWrapper>
    );
}

export {UserFormular};
