import React, {useCallback, useMemo} from 'react';
import _ from 'lodash';
import {FormContext, FormWrapper} from 'components/Form/FormWrapper';
import {FormElementLoadingButton} from 'components/Form/FormElements/FormElementLoadingButton';
import {FormElementAutocomplete} from 'components/Form/FormElements/FormElementAutocomplete';
import {FormElementDateTimePicker} from 'components/Form/FormElements/FormElementDateTimePicker';
import {
    Chip, Collapse, Grid, IconButton,
} from '@mui/material';
import {ItemData} from 'components/Form/ItemData';
import {kindDataSchema, userSchema, workPlaceDataSchema} from 'applications/timebuddy/modules/workingtime/forms/workingTimeLog/WorkingTimeLogSchema';
import {schema as validatorSchema} from 'beyond-validators/timeBuddy/WorkingTimeWorkLog';
import {AddCircleOutline, OpenInBrowserOutlined, Refresh} from '@mui/icons-material';
import {FormElementInfoChips} from 'components/Form/FormElements/FormElementInfoChips';
import {FormElementActionButton} from 'components/Form/FormElements/FormElementActionButton';
import {useCanAccess} from 'hooks/useCanAccess';
import {createWorkingTimeWorkLog, updateWorkingTimeWorkLog} from 'graphql/timeBuddy/WorkingTimeLog/mutations';
import {getWorkingTimeLog} from 'graphql/timeBuddy/WorkingTimeLog/queries';
import {FormElementTextField} from 'components/Form/FormElements/FormElementTextField';
import {FormElementContainer} from 'components/Form/FormElements/FormElementContainer';
import {FormReset} from 'components/Form/FormReset';
import {FormElementSwitch} from 'components/Form/FormElements/FormElementSwitch';
import {useGlobalState} from 'hooks/useGlobalState';
import {RouteLink} from 'components/RouteLink';

/**
 * The WorkingTimeLogFormular for creating and updating a worktime log
 * .TimeBuddy.Forms
 * @param {import('applications/configuration').EntityFormularProps} props - props passed to the component
 * @returns {React.ReactElement} The WorkingTimeLogFormular component
 */
function WorkingTimeLogFormular({
    id, onSave, initialData: initialDataRoot, readonly, ...rest
}) {
    const createType = id === 'create';
    const {getGlobal} = useGlobalState();
    const userId = getGlobal('userId');

    /** @type {import('components/Form/form').ItemSaveConfig} */
    const saveConfig = useMemo(() => {
        const preProcess = (item) => ({
            ...item,
            userId: item.ownEntry ?? true
                ? userId
                : item.userId,
        });
        const postProcess = (item) => {
            if (item.userId === userId) {
                return {
                    ...item,
                    ownEntry: true,
                };
            }
            return item;
        };
        return !createType ? {
            mutation: updateWorkingTimeWorkLog,
            mask: {
                id: true,
                draft: false,
                startDateTime: false,
                endDateTime: false,
                workplaceId: false,
                kind: false,
                comment: false,
            },
            postProcess,
        } : {
            mutation: createWorkingTimeWorkLog,
            mask: {
                draft: false,
                userId: true,
                startDateTime: false,
                endDateTime: false,
                workplaceId: false,
                kind: false,
                comment: false,
            },
            postProcess,
            preProcess,
        };
    }, [userId]);

    const loadConfig = useMemo(() => ({
        query: getWorkingTimeLog,
        variables: {direct: {id}},
        mask: {id: true},

        postProcess: (item) => {
            // eslint-disable-next-line no-underscore-dangle
            if (item && '__typename' in item && item.__typename !== 'WorkingTimeWorkLog') {
                return {};
            }
            const ownEntry = item.userId === userId;
            return {
                ...item,
                ownEntry,
            };
        },
    }), [id, userId]);

    const initialData = useMemo(() => (createType ? {
        startDateTime: new Date().toISOString(),
        kind: 'NORMAL',
        ...initialDataRoot,
    } : undefined), [createType, initialDataRoot]);

    const canCreateWorkingTimeLogs = useCanAccess('createWorkingTimeLog');
    const isLineManager = useCanAccess('LineManager');

    return (
        <ItemData
            initialData={initialData}
            loadConfig={!createType ? loadConfig : undefined}
            saveConfig={saveConfig}
        >
            <FormWrapper
                {...rest}
                readonly={readonly}
                isNewItem={createType}
                validatorSchema={{
                    schema: validatorSchema,
                    type: (!createType) ? 'update' : 'create',
                }}
                onSaveCallback={(result) => {
                    if (_.isFunction(onSave)) {
                        onSave(result);
                    }
                }}
                messageKey={(!createType) ? 'WorkingTimeLog_Update' : 'WorkingTimeLog_Create'}
                context={`WorkingTimeLog${id}`}
            >
                <FormReset shouldClear={createType} />
                <FormElementActionButton
                    routeId="timebuddy_workingtime_work_log_route"
                    routeParams={{id: 'create'}}
                    portalAnchorSelector="#action-button-frame"
                    disabled={createType || !canCreateWorkingTimeLogs}
                    context={FormContext}
                >
                    <AddCircleOutline />
                </FormElementActionButton>
                <FormElementActionButton
                    reload
                    portalAnchorSelector="#action-button-frame"
                    disabled={createType}
                    context={FormContext}
                >
                    <Refresh />
                </FormElementActionButton>
                <Grid container spacing={2}>
                    <FormElementContainer
                        attribute="template"
                        propsMapping={(att) => ({isReadOnly: (readonly || (att.value ? !att.value?.modifiable : false))})}
                    >
                        <FormElementInfoChips showDraft showReadonly>
                            <FormElementContainer
                                attribute="template"
                                propsMapping={(att) => ({in: att.value?.checkoutEnforcement ?? false})}
                            >
                                <Collapse>
                                    <Chip label="QR Only" />
                                </Collapse>
                            </FormElementContainer>
                        </FormElementInfoChips>
                    </FormElementContainer>
                    { isLineManager && (
                        <>
                            <Grid item xs={6} sm={12}>
                                <FormElementSwitch
                                    attribute="ownEntry"
                                    label="Eigener Eintrag"
                                    defaultTrue
                                    disabled={!createType}
                                />
                            </Grid>
                            <Grid item xs={6} sm={12} display="flex">
                                <FormElementContainer attribute="ownEntry" conditionalRender={({value}) => !value}>
                                    <FormElementAutocomplete
                                        attribute="userId"
                                        label="Benutzer"
                                        readOnly={!createType}
                                        dataSchema={userSchema}
                                        optionReference="user"
                                        optionsFilter={useCallback((option) => option.id !== userId, [userId])}
                                    />
                                    <FormElementContainer
                                        attribute="userId"
                                        propsMapping={(props) => ({
                                            routeParams: {id: props?.value},
                                            disabled: _.isNil(props?.value),
                                        })}
                                    >
                                        <RouteLink routeId="beyondbuddy_settings_general_user_route">
                                            <IconButton><OpenInBrowserOutlined /></IconButton>
                                        </RouteLink>
                                    </FormElementContainer>
                                </FormElementContainer>
                            </Grid>
                        </>
                    )}
                    <Grid item xs={6} sm={12} md={6}>
                        <FormElementContainer
                            attribute="template"
                            propsMapping={(att) => ({readOnly: (att.value ? !att.value?.modifiable : false)})}
                        >
                            <FormElementDateTimePicker
                                label="Datum Start"
                                attribute="startDateTime"
                                openTo="hours"
                            />
                        </FormElementContainer>
                    </Grid>
                    <Grid item xs={6} sm={12} md={6}>
                        <FormElementContainer
                            attribute="template"
                            propsMapping={(att) => ({readOnly: (att.value?.checkoutEnforcement || !(att.value?.modifiable ?? true))})}
                        >
                            <FormElementDateTimePicker
                                label="Datum End"
                                attribute="endDateTime"
                                minTimeAttribute="startDateTime"
                                openTo="hours"
                            />
                        </FormElementContainer>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormElementContainer
                            attribute="template"
                            propsMapping={(att) => ({readOnly: (att.value ? !att.value?.modifiable : false)})}
                        >
                            <FormElementAutocomplete
                                attribute="kind"
                                label="Art"
                                dataSchema={kindDataSchema}
                            />
                        </FormElementContainer>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormElementContainer
                            attribute="template"
                            propsMapping={(att) => ({readOnly: (att.value ? !att.value?.modifiable : false)})}
                        >
                            <FormElementAutocomplete
                                attribute="workplaceId"
                                label="Ort"
                                optionReference="workplace"
                                dataSchema={workPlaceDataSchema}
                            />
                        </FormElementContainer>
                    </Grid>
                    <Grid item xs={12}>
                        <FormElementContainer
                            attribute="template"
                            propsMapping={(att) => ({readOnly: (att.value ? !att.value?.modifiable : false)})}
                        >
                            <FormElementTextField rows={3} attribute="comment" label="Kommentar" />
                        </FormElementContainer>
                    </Grid>
                    <FormElementContainer
                        attribute="template"
                        propsMapping={(att) => ({readOnly: (att.value ? !att.value?.modifiable : false)})}
                        conditionalRender={({readOnly}) => !readOnly}
                    >
                        <Grid item md={3} sm={5}>
                            <FormElementLoadingButton label="Speichern" saveVariables={{draft: false}} />
                        </Grid>
                        <Grid item md={3} sm={5}>
                            <FormElementLoadingButton variant="text" label="Entwurf" draft />
                        </Grid>
                    </FormElementContainer>
                </Grid>
            </FormWrapper>
        </ItemData>
    );
}

export {WorkingTimeLogFormular};
