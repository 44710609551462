import React, {useCallback, useMemo, useState} from 'react';
import {LayoutContainer} from 'assets/theme/layout/LayoutContainer/LayoutContainer';
import {OrganizationalUnitFormular} from 'applications/beyondbuddy/settings/forms/organizationalUnit/OrganizationalUnitFormular';
import {PermissionFormular} from 'applications/beyondbuddy/settings/forms/permissions/PermissionFormular';

import {
    generatePath,
    useNavigate,
    useParams,
} from 'react-router-dom';
import {useCurrentRoute} from 'routes';
import {readUpdateDeletePermissionTemplate} from 'components/Form/FormElements/FormElementEntityLinkPermissions';
import {createPermissionsObject} from 'applications/configs';
import {organizationEntityKeys} from 'applications/beyondbuddy/config';
import {TabFormsContainer} from 'components/Form/TabFormsContainer';
import {getOrganizationalUnit} from 'graphql/beyondBuddy/OrganizationalUnit/queries';
import {createOrganizationalUnit, updateOrganizationalUnit} from 'graphql/beyondBuddy/OrganizationalUnit/mutations';
import {BlurOnOutlined, LockOutlined} from '@mui/icons-material';
import _ from 'lodash';

/**
 * @param {Partial<import('applications/configuration').EntityFormularProps>} props the properties
 * @returns {React.ReactElement} the formular
 */
const getOrganizationalUnitFormular = (props) => <OrganizationalUnitFormular {...props} />;

/**
 * @param {Partial<import('applications/configuration').EntityFormularProps>} props the properties
 * @param {boolean} isIncomming indicates the permission direction
 * @returns {React.ReactElement} the permissions formular
 */
const getPermissionFormular = (props, isIncomming) => {
    const availablePermissions = createPermissionsObject(organizationEntityKeys, ['read', 'extendedRead', 'updateGroup', 'permissionGroup']);
    const defaultPermissions = createPermissionsObject(organizationEntityKeys, ['read']);

    /** @type {Partial<import('components/Form/FormElements/FormElementEntityLinkPermissions').FormElementEntityLinkPermissionsProps>}  */
    const incommingEntityChooserProps = {
        availablePermissions,
        defaultPermissions,
    };
    /** @type {Partial<import('components/Form/FormElements/FormElementEntityLinkPermissions').FormElementEntityLinkPermissionsProps>}  */
    const outgoingEntityChooserProps = {
        availablePermissions: {
            ...availablePermissions,
            Group: ['member_of', ...availablePermissions.Group],
        },
        defaultPermissions: {
            ...defaultPermissions,
            Group: ['member_of', 'read'],
        },
    };

    return (
        <PermissionFormular
            disabled={props.readonly}
            isIncoming={isIncomming}
            entityTypeId={`OrganizationalUnit#${props.id}`}
            actionButtonProps={{portalAnchorSelector: `#OrganizationalUnitForm${props.id}action-button-frame-${isIncomming ? '' : 'outgoing_'}permissions-actions`}}
            entityChooserProps={{
                initialEntityType: 'User',
                ...(isIncomming ? incommingEntityChooserProps : outgoingEntityChooserProps),
                permissionDependencies: {
                    User: readUpdateDeletePermissionTemplate,
                    Group: readUpdateDeletePermissionTemplate,
                    OrganizationalUnit: readUpdateDeletePermissionTemplate,
                    Tenant: readUpdateDeletePermissionTemplate,
                },
            }}
            {...props}
        />
    );
};

const mask = {
    id: true,
    tenantId: true,
    name: true,
    imageKey: false,
    parentOrganizationalUnitId: false,
};

/**
 * This page shows a create or edit form for a group.
 * .DriveBuddy.Pages
 * @returns {React.ReactElement} The OrganizationalUnitPage.
 */
function OrganizationalUnitPage() {
    // getting the URL parameters
    const {id} = useParams();
    const isNewItem = id === 'create';
    const navigate = useNavigate();
    const [currentTab, setCurrentTab] = useState(0);

    /** @type {import('routeinfo').RoutePathInfo} */
    const route = useCurrentRoute();

    const onSave = useCallback((result) => {
        if (id === 'create' && result?.id) {
            navigate(`/${generatePath(route.path, {id: result?.id})}`);
        }
    }, [id, route, navigate]);

    const loadConfig = useMemo(() => ({
        query: getOrganizationalUnit,
        variables: {direct: {id}},
        mask: {id: true},
    }), [id]);

    /** @type {import('components/Form/form').ItemSaveConfig}  */
    const saveConfig = useMemo(() => ({
        variables: {global: {tenantId: 'tenantId'}},
        ...(!isNewItem) ? {
            mutation: updateOrganizationalUnit,
            mask,
        } : {
            mutation: createOrganizationalUnit,
            mask: _.omit(mask, 'id'),
        },
    }), [isNewItem]);

    // eslint-disable-next-line function-paren-newline
    const tabFormsContainerProps = useMemo(
        /** @returns {import('components/Form/form').TabFormsContainerProps} container properties */
        () => ({
            identifier: `OrganizationalUnitForm${id}`,
            itemDataProps: {
                ...(!isNewItem) ? {loadConfig} : {},
                saveConfig,
            },
            tabs: [{
                id: 'base',
                label: 'Basisdaten',
                icon: <BlurOnOutlined />,
                getChildren: () => getOrganizationalUnitFormular({id, onSave}),
                formWrapperId: `OrganizationalUnit#${id}Base`,
            // quickGuideId: 'peacebuddy_general_cemetery',
            }, {
                id: 'permissions',
                label: 'Berechtigungen',
                icon: <LockOutlined />,
                enabledCondition: 'permissionsReadable',
                getChildren: (grants) => id !== 'create' && getPermissionFormular({id, readonly: !grants.permissionsUpdatable}, true),
                formWrapperId: `OrganizationalUnit#${id}Permissions`,
                quickGuideId: 'beyondbuddy_general_permissions',
            }, {
                id: 'outgoing_permissions',
                label: 'Ausgehende Berechtigungen',
                icon: <LockOutlined />,
                enabledCondition: 'permissionsReadable',
                getChildren: (grants) => id !== 'create' && getPermissionFormular({id, readonly: !grants.permissionsUpdatable}, false),
                formWrapperId: `OrganizationalUnit#${id}OutgoingPermissions`,
                quickGuideId: 'beyondbuddy_general_permissions',
            }],
            currentTab,
            setCurrentTab,
        }), [id, isNewItem, loadConfig, saveConfig, currentTab, setCurrentTab, onSave]);

    return (
        <LayoutContainer>
            <TabFormsContainer {...tabFormsContainerProps} />
        </LayoutContainer>
    );
}
export {OrganizationalUnitPage};
