import React from 'react';
import _ from 'lodash';
import {FormContext, FormWrapper} from 'components/Form/FormWrapper';
import {
    Box, Grid,
} from '@mui/material';
import {FormElementLoadingButton} from 'components/Form/FormElements/FormElementLoadingButton';

import {FormElementInfoChips} from 'components/Form/FormElements/FormElementInfoChips';
import {FormElementActionButton} from 'components/Form/FormElements/FormElementActionButton';
import {AddCircleOutline, Refresh} from '@mui/icons-material';
import {useCanAccess} from 'hooks/useCanAccess';
import {FormElementDatePicker} from 'components/Form/FormElements/FormElementDatePicker';
import {FormElementCheckbox} from 'components/Form/FormElements/FormElementCheckbox';
import {FormReset} from 'components/Form/FormReset';

const {schema: validatorSchema} = require('beyond-validators/timeBuddy/TimePeriod');

/**
 * The timeperiod formular for creating and updating a timeperiod
 * @param {import('applications/configuration').EntityFormularProps} props - props passed to the component
 * @returns {React.ReactElement} The TimePeriodFormular component.
 */
function TimePeriodFormular({id, onSave, ...rest}) {
    const canCreateTimePeriods = useCanAccess('createTimePeriod');

    return (
        <FormWrapper
            {...rest}
            isNewItem={id === 'create'}
            validatorSchema={{
                schema: validatorSchema,
                type: (id !== 'create') ? 'update' : 'create',
            }}
            onSaveCallback={(result) => {
                if (_.isFunction(onSave)) {
                    onSave(result);
                }
            }}
            messageKey={(id !== 'create') ? 'TimePeriod_Update' : 'TimePeriod_Create'}
            context={`TimePeriod#${id}Base`}
        >
            <FormReset shouldClear={id === 'create'} />
            <Box style={{
                display: 'flex',
                gap: '1rem',
                flexWrap: 'wrap',
                justifyContent: 'center',
            }}
            >

                <FormElementActionButton
                    routeId="timebuddy_time_period_route"
                    routeParams={{id: 'create'}}
                    portalAnchorSelector={`#TimePeriodForm${id}action-button-frame-base-actions`}
                    formWrapperIdPattern={`TimePeriod#${id}`}
                    disabled={id === 'create' || !canCreateTimePeriods}
                    context={FormContext}
                >
                    <AddCircleOutline />
                </FormElementActionButton>
                <FormElementActionButton
                    reload
                    portalAnchorSelector={`#TimePeriodForm${id}action-button-frame-base-actions`}
                    formWrapperIdPattern={`TimePeriod#${id}`}
                    disabled={id === 'create'}
                    context={FormContext}
                >
                    <Refresh />
                </FormElementActionButton>
                <Box style={{flexGrow: 1, flexShrink: 1, flexBasis: '450px'}}>
                    <Grid container spacing={2}>
                        <FormElementInfoChips showReadonly />
                        <Grid item xs={12} sm={6}><FormElementDatePicker label="Start" attribute="startDate" /></Grid>
                        <Grid item xs={12} sm={6}><FormElementDatePicker label="Ende" attribute="endDate" minDateAttribute="startDate" /></Grid>
                        <Grid item xs={6} sm={12} style={{paddingLeft: '2rem'}}>
                            {/* Padding required to access checkbox on mobile */}
                            <FormElementCheckbox label="Offen" attribute="open" />
                        </Grid>
                        <Grid item xs={6} sm={12}>
                            <FormElementLoadingButton />
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </FormWrapper>
    );
}

export {TimePeriodFormular};
