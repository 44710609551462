const _ = require('lodash');

/**
 * Returns the input as an array if it's a valid array or a JSON-encoded array.
 * If the input is neither, returns `false`.
 * @param {string|Array<string>} values - The input to check. It can be either an array or a JSON-encoded string.
 * @returns {Array<string>|false} - Returns the array if valid, otherwise `false`.
 */
const getArrayOrFalse = (values) => {
    // Check if it's already an array
    if (_.isArray(values)) return values;

    // Check if it's a string and parse it
    try {
        const parsed = JSON.parse(values);
        return _.isArray(parsed) ? parsed : false;
    } catch (e) {
        // If parsing fails, it's not a valid JSON
        return false;
    }
};

/**
 * Removes all unused validations from the schema and returns them for further use.
 * @module convertValidationSchema
 * @param {import('./types').ValidationSchema} schema - schema from the configuration file
 * @returns {import('./types').ConvertedValidationSchema} - environment friendly schema format
 * @example convertValidationSchema({frontEnd: {}, backEnd: {}, attributes:{}})
 */
const convertValidationSchema = (schema) => {
    if (!schema) {
        return undefined;
    }
    /**
     *
     * @param {object} environmentSchema - Schema that will be converted
     * @returns {object} converted schema
     */
    const convertEnvironmentSchema = (environmentSchema) => {
        if (!environmentSchema) {
            return undefined;
        }
        const convertedSchema = {};

        // the actual conversion function
        const convertSchema = (config, attributeName, type) => {
            _.forEach(config.actions, (action) => {
                _.set(
                    convertedSchema,
                    type ? [type, action, attributeName] : [action, attributeName],
                    _.union(
                        config.configurations,
                        _.get(
                            convertedSchema,
                            type ? [type, action, attributeName] : [action, attributeName],
                        ),
                    ),
                );
            });
        };

        // convert the schema to a more usable format
        _.forEach(environmentSchema, (attribute, attributeName) => {
            _.forEach(attribute, (config) => {
                if (config.formType) {
                    _.forEach(config.formType, (type) => {
                        convertSchema(config, attributeName, type);
                    });
                } else {
                    convertSchema(config, attributeName);
                }
            });
        });
        return convertedSchema;
    };

    return {
        frontend: convertEnvironmentSchema(schema.frontend),
        backend: convertEnvironmentSchema(schema.backend),
        attributes: schema.attributes,
    };
};

/**
 * @param {Function | object} getAttributeValue - function that returns the value of the input OR the values as an object
 * @param {string} attributeName - the name of the attribute that should be validated
 * @returns {any} - returns the value of the requested attribute
 */
const getValue = (getAttributeValue, attributeName) => (_.isFunction(getAttributeValue) ? getAttributeValue(attributeName) : getAttributeValue[attributeName]);

/**
 * The main validator function to instantiate with all validation methods inside.
 * @module Validator
 * @param {Record<string, import("beyond-validators").CheckFunction>} [functionOverrides] - Specific validations functions defined outside of the validator.
 * @returns {import("beyond-validators").Validator} the functions to export
 * @example const {validateMultiple, checkValidationResults, addFunctionOverrides} = Validator(functionOverrides);
 */
function Validator(functionOverrides) {
    /**
     * Validates the input based on its type
     * @function checkType
     * @param {object} type - the expected type (e.g. 'String', 'Number', 'Boolean')
     * @param {*} value - the value to validate
     * @returns {boolean} true if the value is of the expected type
     */
    const checkType = (type, value) => {
        if (_.isNil(value)) { return false; } // an empty value won't be checked
        switch (type) {
        case 'Array': return !!getArrayOrFalse(value);
        case 'String': return _.isString(value);
        case 'Object': return _.isObject(value);
        case 'Number': return _.isFinite(value) && !_.isString(value);
        case 'DateTime': return _.isArray(_.toString(value).match(/^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}(.[0-9]+)?([zZ]|([+-])([01]\d|2[0-3]):?([0-5]\d)?)$/));
        case 'Date': return _.isArray(_.toString(value).match(/^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}(.[0-9]+)?$/));
        case 'Boolean': return _.isBoolean(value);
        default: throw Error(`Validator: The requested type is invalid!\n Type: ${type}`);
        }
    };

    /**
     * Validates the length of a string
     * @function checkLength
     * @param {Record<string, number>} expressions - the expected lengths (e.g. {gte: 3, let: 10})
     * @param {*} value - the value to check
     * @returns {boolean} - true if the value is valid, false if not
     */
    const checkLength = (expressions, value) => {
        if (_.isNil(value)) { return false; } // an empty value won't be checked

        // if the type is array, it has to be mapped to check every value
        if ((_.isArray(value) && !_.every(_.map(value, _.isString))) || (!_.isArray(value) && !_.isString(value))) {
            throw Error(`Validator: The provided value is invalid!\n Value: ${JSON.stringify(value)}`);
        }
        if (!_.isObject(expressions) || _.isEmpty(expressions)) {
            if (_.isFinite(expressions)) {
                throw Error(`Validator: The provided expressions are invalid!\n Expressions: ${expressions}`);
            }
            throw Error(`Validator: The provided expressions are invalid!\n Expressions: ${expressions}`);
        }

        const check = (v) => _.every(_.map(expressions, (
            /** @type {number} */
            vL,
            /** @type {string} */
            operator,
        ) => {
            if (!_.isFinite(vL)) {
                throw Error(`Validator The requested validationConfigurations are invalid!\n ValidationConfigurations: ${vL}`);
            }
            switch (operator) {
            case 'eq': return v.length === vL;
            case 'gt': return v.length > vL;
            case 'lt': return v.length < vL;
            case 'gte': return v.length >= vL;
            case 'lte': return v.length <= vL;
            default: throw Error('The requested validationConfigurations are invalid');
            }
        }));

        const arr = getArrayOrFalse(value);
        return arr ? _.every(_.map(arr, check)) : check(value);
    };

    /**
     * Validates the given value against a Regular Expression
     * @function checkFormat
     * @param {RegExp} expression the Regular Expression to validate against
     * @param {string} value the value to validate
     * @returns {boolean} true if the value matches the Regular Expression
     */
    const checkFormat = (expression, value) => {
        if (_.isNil(value)) { return false; } // an empty value won't be checked
        // if the type is array, it has to be mapped to check every value
        if ((_.isArray(value) && !_.every(_.map(value, _.isString))) || (!_.isArray(value) && (!_.isString(value) && !_.isNumber(value)))) {
            throw Error(`Validator: The provided value is invalid!\n Value: ${JSON.stringify(value)}, Type: ${typeof (value)}`);
        }
        if (!_.isRegExp(expression)) {
            throw Error(`Validator: The provided expression is invalid!\n Expression: ${expression}`);
        }

        const check = (v) => {
            const res = _.isArray(v.match(expression));
            return res;
        };

        const arr = getArrayOrFalse(value);
        if (arr) {
            return _.every(_.map(arr, check));
        }
        if (_.isNumber(value)) {
            return check(`${value}`);
        }
        return check(value);
    };

    /**
     * Checks if the extension of the file matches the configuration
     * @function checkFileExtension
     * @param {Array<string>} extensions - the array of regular expressions to validate against
     * @param {string|Array<string>} values - value that gets validated
     * @returns {boolean} true if the value is required and defined or not required and undefined
     */
    const checkFileExtension = (extensions, values) => {
        if (!_.every(extensions, _.isString)) {
            throw Error(`Validator: The provided expressions are invalid!\n Expressions: ${extensions}`);
        }

        const reg = new RegExp(`^.*\\.(${extensions.join('|')})$`, 'i');
        const check = (value) => {
            const res = checkFormat(reg, value);
            return res;
        };
        const arr = getArrayOrFalse(values);
        return arr ? _.every(_.map(arr, check)) : check(values);
    };

    /**
     * Checks if the value is required, then checks if the value is defined
     * @function checkRequired
     * @param {boolean} isRequired configuration to validate against
     * @param {string} value the value to validate
     * @returns {boolean} true if the value is required and defined or not required and undefined
     */
    const checkRequired = (isRequired, value) => {
        // if there is no configuration, the value is not required
        if (!isRequired) return true;
        // check for boolean values (e.g. Checkboxes) that should be true if required
        if (isRequired && (_.isBoolean(value) && value === true)) return true;
        // check for empty, undefined or null values
        if (isRequired && (!_.isEmpty(value) || _.isNumber(value))) return true;
        // if the value is required and not defined, return false
        return false;
    };

    /**
     * Validates the input based on its dependency formats
     * @function checkDependencyFormat
     * @param {Record<string, Record<string, RegExp>>} dependencies - the dependency configuration
     * @param {string} value - the value to validate
     * @param {string} attributeName - the name of the attribute that should be validated
     * @param {Function | object} [getAttributeValue] - function that returns the value of the input OR the values as an object
     * @returns {boolean} true if the value is of the expected type
     */
    const checkDependencyFormat = (dependencies, value, attributeName, getAttributeValue) => {
        if (_.isNil(value)) { return false; } // an empty value won't be checked
        if (!getAttributeValue) { return false; } // the function is required
        return _.every(dependencies, (dependency, key) => {
            const dependencyValue = getAttributeValue(key);
            return checkFormat(_.get(dependency, dependencyValue), value);
        });
    };

    /**
     * Validates the input based on its dependency requirements
     * @function checkDependencyRequired
     * @param {Array<string>} dependencies - the dependency configuration
     * @param {string} value - the value to validate
     * @param {string} attributeName - the name of the attribute that should be validated
     * @param {Function | object} [getAttributeValue] - function that returns the value of the input OR the values as an object
     * @returns {boolean} true if the value is of the expected type
     */
    const checkDependencyRequired = (dependencies, value, attributeName, getAttributeValue) => {
        if (!_.isArray(dependencies)) {
            throw Error(`Validator: The provided expression is invalid!\n Expression: ${dependencies}`);
        }
        // if some of the dependency keys has a value, the attribute becomes required
        if (_.some(dependencies, (dependency) => !!getAttributeValue(dependency))) {
            return checkRequired(true, value);
        }
        return true;
    };

    const checkingFunctions = {
        type: checkType,
        length: checkLength,
        format: checkFormat,
        required: checkRequired,
        fileExtensions: checkFileExtension,
        dependencyFormat: checkDependencyFormat,
        dependencyRequired: checkDependencyRequired,
        ...functionOverrides,
    };

    /**
     * Combines the additionalFunctions with the functionOverrides
     * @function addFunctionOverrides
     * @param {object} fOverrides the function overrides coming from the caller
     */
    const addFunctionOverrides = (fOverrides) => {
        Object.assign(checkingFunctions, fOverrides);
    };

    /**
     * Validates if the validationFunction is valid and executes it
     * @function validate
     * @param {*} validationKey - the key of the validation function that should be executed (e.g. 'length')
     * @param {*} validationConfiguration - the configuration for the validation function (e.g. {lte: 5, gte: 3})
     * @param {any} value - the value to be validated
     * @param {string} attributeName - the name of the attribute that should be validated
     * @param {Function | object} [getAttributeValue] - function that returns the value of the input OR the values as an object
     * @returns {Promise<boolean>} true if the validation function is valid, false otherwise
     */
    const validate = async (validationKey, validationConfiguration, value, attributeName, getAttributeValue) => {
        if (!_.isFunction(checkingFunctions[validationKey])) {
            return false;
        }
        if (_.isUndefined(value) && validationKey !== 'dependencyRequired') {
            return false;
        }
        return checkingFunctions[validationKey](validationConfiguration, value, attributeName, getAttributeValue);
    };

    /**
     * Handles validation of a single value
     * @function validateSingle
     * @param {Array} schema - the schema to validate against (e.g. ['type', 'required', 'length', 'unique', 'format'])
     * @param {object} configuration - the configuration of the input (e.g. {type: 'String', length: {eq: 20}})
     * @param {any }value - the value to be validated
     * @param {string} attributeName - the name of the attribute that should be validated
     * @param {Function | object} [getAttributeValue] - function that returns the value of the input OR the values as an object
     * @returns {Promise<object>} An object with keys of validationKeys and values of validation results.
     * @example
     * validateSingle(['type', 'length'], {type: 'String', length: {gt: 3}}, 'Test'))
     */
    const validateSingle = async (schema, configuration, value, attributeName, getAttributeValue) => {
        if (
            _.isNil(schema) // schema is not provided
            || _.isNil(configuration) // configuration is not provided
            || _.isArrayLikeObject(_.pick(configuration, schema)) // {length: 2} will be recognized as array -> not allowed
        ) {
            throw Error(`Validator: The provided parameters are invalid!\n Configuration: ${JSON.stringify(configuration)}\n Schema: ${schema}\n Value: ${value}`);
        }
        const diff = _.difference(schema, _.keys(configuration));
        if (!_.isEmpty(diff)) {
            throw new Error(`Validator: Not all requested validations in the schema have a corresponding configuration.\n Missing: ${_.join(diff, ',')}`);
        }

        return _.fromPairs(await Promise.all(
            _.chain(configuration)
                .pick(schema)
                .map(async (validationConfiguration, validationKey) => [
                    validationKey,
                    await validate( // await is required
                        validationKey,
                        validationConfiguration,
                        value,
                        attributeName,
                        getAttributeValue,
                    ),
                ])
                .value(),
        ));
    };

    /**
     * Handles validation of an input with multiple values
     * @function validateMultiple
     * @param {object} schema - schema (configuration) of the input (e.g. {frontEnd: {...}, backEnd: {...}, attributes: {...}})
     * @param {object} attributes - attributes of the input that should be validated.
     * @param {Function | object} getAttributeValue - function that returns the value of the input OR the values as an object
     * @returns {Promise<object>} An object with keys of validationKeys and values of validation results
     */
    const validateMultiple = async (schema, attributes, getAttributeValue) => _.fromPairs(
        await Promise.all(_.chain(attributes)
            .pick(_.keys(schema)) // extracts values that should be validated
            .map(async (validationConfigurations, attributeName) => [
                attributeName,
                // processes each attribute
                await validateSingle(
                    schema[attributeName],
                    validationConfigurations,
                    getValue(getAttributeValue, attributeName),
                    attributeName,
                    getAttributeValue,
                ),
            ])
            .value()),
    );

    /**
     * Handles the validation result of an input
     * @function checkValidationResult
     * @param {{required: boolean, dependencyRequired: boolean, validationResultsRest: object}} validationResult the result of the validation
     * (e.g. {frontEnd: {...}, backEnd: {...}, attributes: {...}})
     * @param {*} value the value of the input (e.g. 'Test')
     * @returns {boolean} true if the validation is valid, false otherwise
     */
    const checkValidationResult = ({required, dependencyRequired, ...validationResultsRest}, value) => {
        const restResult = _.every(validationResultsRest);

        // console.log('checkValidationResult', {
        //     required,
        //     dependencyRequired,
        //     restResult,
        //     isEmpty: _.isEmpty(value),
        //     value,
        //     return: (!(required === false || dependencyRequired === false) || !restResult && (_.isEmpty(value) || value === '[]' || value === '{}')),
        // });

        if (required === false || dependencyRequired === false) return false; // required check returned false (if not required would be undefined)
        // if everything except required is false but the value is empty
        if (!restResult && (_.isEmpty(value) || value === '[]' || value === '{}')) return true;

        return restResult; // return everything except required and unique
    };

    /**
     * Handles the validation result of an input
     * @function checkValidationResults
     * @param {object} validationResults the result of the validation (e.g. {frontEnd: {...}, backEnd: {...}, attributes: {...}})
     * @param {Function | object} getAttributeValue the function that returns the value of the input OR the values as an object
     * @returns {boolean} true if the validation is valid, false otherwise
     */
    const checkValidationResults = (validationResults, getAttributeValue) => _.every(
        validationResults,
        (validationResult, attributeName) => checkValidationResult(
            validationResult,
            _.isFunction(getAttributeValue) ? getAttributeValue(attributeName) : getAttributeValue[attributeName],
        ),
    );

    return {
        validate,
        validateSingle,
        validateMultiple,
        checkValidationResult,
        checkValidationResults,
        addFunctionOverrides,
    };
}

/**
 * Checks if the supplied result indicates that the supplied data may be saved
 * as a draft
 * @param {Record<string, Record<string, boolean>>} multipleResult - validation result for
 * an item / a set of attributes
 * @param {Array<string>} values - set of attributes that are present, and need to be valid even in a draft
 * @returns {boolean} true if the item this result comes from would be a valid draft
 */
const isDraft = (multipleResult, values) => {
    const available = _.pick(multipleResult, values);
    return _.every(available, _.every) && !_.isEmpty(available);
};

module.exports = {Validator, convertValidationSchema, isDraft};
// export {Validator, convertValidationSchema};
