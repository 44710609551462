import {
    add, format, parseISO, subDays, addDays, isWithinInterval,
    differenceInCalendarDays, isBefore, startOfToday,
} from 'date-fns';
import {deAT} from 'date-fns/locale';

/**
 * Checks if a given ISO date string is within a range of days in the past or future.
 * @param {string} dateString - The ISO date string to check.
 * @param {number} days - The number of days to check in both past and future.
 * @returns {boolean} - Returns true if the date is within the range of days, false otherwise.
 * @example
 * // Check if the date is within the past or next 5 days
 * isDateWithinRange('2023-09-01T00:00:00Z', 5); // true or false
 */
const isDateWithinRange = (dateString, days) => {
    try {
        const date = parseISO(dateString); // Parse the input ISO string to a JavaScript Date object
        const now = new Date(); // Current date

        // Calculate the range: past and future date
        const pastDate = subDays(now, days); // Subtract the number of days for past range
        const futureDate = addDays(now, days); // Add the number of days for future range

        // Check if the date is within the range
        return isWithinInterval(date, {start: pastDate, end: futureDate});
    } catch {
        return false;
    }
};

/**
 * Checks if a given ISO date string is before today.
 * @param {string} isoDateString - The ISO string representation of the date to check.
 * @returns {boolean} - Returns `true` if the given date is before today, `false` otherwise.
 */
const isBeforeToday = (isoDateString) => {
    const date = new Date(isoDateString);
    return isBefore(date, startOfToday());
};

/**
 * Convert days into years, months, weeks, and days starting from today, removing leading zero values.
 * @param {number} totalDays - The total number of days to convert.
 * @returns {string} - A formatted string showing the equivalent years, months, weeks, and days.
 */
const formatDaysToPeriod = (totalDays) => {
    if (totalDays <= 0) return '0 Tage';

    let remainingDays = totalDays;
    const today = new Date();
    const endDate = addDays(today, totalDays);
    const years = endDate.getUTCFullYear() - today.getUTCFullYear();

    remainingDays = differenceInCalendarDays(endDate, addDays(today, years * 365));

    const months = Math.floor(remainingDays / 30);
    remainingDays -= months * 30;

    const weeks = Math.floor(remainingDays / 7);
    const days = remainingDays % 7;

    const periodParts = [
        years ? `${years} Jahr${years > 1 ? 'e' : ''}` : '',
        months ? `${months} Monat${months > 1 ? 'e' : ''}` : '',
        weeks ? `${weeks} Woche${weeks > 1 ? 'n' : ''}` : '',
        days ? `${days} Tag${days > 1 ? 'e' : ''}` : '',
    ];

    return periodParts.filter(Boolean).join(' ');
};

const formatDateTime = (date, formatString = 'dd.MM.yyyy HH:mm') => {
    if (!Number.isNaN(date)) {
        return format(date, formatString, {locale: deAT});
    }
    return '';
};

const formatDateTimeString = (dateString, formatString = 'dd.MM.yyyy HH:mm') => {
    const value = Date.parse(dateString);
    return formatDateTime(value, formatString);
};

/**
 * Changes a date to the specified hours, and returns it
 * @param {Date} date - date that will be modified
 * @param  {[h: number, m?: number, s?: number, ms?: number]} hours - hours input
 * @returns {Date} the same date as the input
 */
const dateAtHour = (date, ...hours) => {
    date.setHours(...hours);
    return date;
};

/**
 * Function that attempts to convert an unknown object into a date object
 * @param {unknown} date - date string or timestamp or an existing date object
 * @returns {Date} resulting date
 */
const toDate = (date) => {
    if (date instanceof Date || typeof date === 'number') {
        return new Date(date);
    }
    if (typeof date === 'string') {
        return new Date(Date.parse(date));
    }
    throw new Error(`Could not convert to date: ${date}`);
};

/**
 * Adds years to the start year and returns the end of that year in ISO string.
 * If the startDateString is not provided, it defaults to the current date.
 * @param {number} yearsInFuture - The number of years to be added to the start year.
 * @param {string} [startDateString] - The starting date as an ISO string. Defaults to the current date if not provided.
 * @returns {string|null} The end of the future year as an ISO string, or null if yearsInFuture is not provided.
 */
const getFutureEndOfYearIsoString = (yearsInFuture, startDateString) => {
    if (!yearsInFuture) {
        return null;
    }

    // Parse the start date or default to the current date
    const startDate = startDateString ? new Date(startDateString) : new Date();
    // Get the start year
    const startYear = startDate.getFullYear();
    // Calculate the target year
    const targetYear = startYear + yearsInFuture;
    // Create a new Date object representing December 31st of the target year
    const endOfYearDate = new Date(targetYear, 11, 31, 23, 59, 59, 999);
    // Convert the date to an ISO string
    return endOfYearDate.toISOString();
};

/**
 * Adds years to the current year and returns the end of that year in ISO string
 * @param {number} [years] - years to be added to the current year
 * @returns {string} the date in the future
 */
const getFutureDateIsoString = (years) => {
    if (!years) {
        return null;
    }
    return (add(new Date(), {years})).toISOString();
};

export {
    formatDateTimeString, formatDateTime,
    toDate, dateAtHour, getFutureDateIsoString,
    getFutureEndOfYearIsoString, isDateWithinRange, formatDaysToPeriod,
    isBeforeToday,
};
