import React, {
    useMemo,
} from 'react';
import _ from 'lodash';
import {FormWrapper} from 'components/Form/FormWrapper';
import {
    Box, Grid, Typography,
} from '@mui/material';
import {ItemData} from 'components/Form/ItemData';
import {addUserToEvacuation} from 'graphql/beyondBuddy/Evacuation/mutations';
import {FormElementLoadingButton} from 'components/Form/FormElements/FormElementLoadingButton';
import {FormElementAutocomplete} from 'components/Form/FormElements/FormElementAutocomplete';
import {listUserOptions} from 'graphql/beyondBuddy/User/queries';
import {getEvacuation} from 'graphql/beyondBuddy/Evacuation/queries';
import {FormElementContainer} from 'components/Form/FormElements/FormElementContainer';
import {HowToReg} from '@mui/icons-material';

const {schema: validatorSchema} = require('beyond-validators/beyondBuddy/RegisterEvacuee');

const userDataSchema = {
    query: listUserOptions,
    queryVariablesMask: {tenantId: true},
    queryVariables: {global: {tenantId: 'tenantId'}},
    dataKey: 'id',
    getOptionLabel: (option) => _.compact([option?.contactFirstName, option?.contactLastName]).join(' '),
    getOptionValue: (option) => option?.id,
};

/**
 * The tenant formular for creating and updating a tenant
 * @param {import('applications/configuration').EntityFormularProps} props - props passed to the component
 * @returns {React.ReactElement} The RegisterEvacueeFormular component.
 */
function RegisterEvacueeFormular({id, onSave, ...rest}) {
    /** @type {import('components/Form/form').ItemSaveConfig}  */
    const saveConfig = useMemo(() => ({
        variables: {direct: {id}},
        mutation: addUserToEvacuation,
        preProcess: (variables) => ({
            id: variables.id,
            newEntry: {
                userId: variables.userId,
            },
        }),
        mask: {
            id: true,
            newEntry: true,
        },
    }), [id]);

    const loadConfig = useMemo(() => ({
        query: getEvacuation,
        variables: {direct: {id}},
        mask: {id: true},
    }), [id]);

    return (
        <ItemData loadConfig={loadConfig} saveConfig={saveConfig}>
            <FormWrapper
                {...rest}
                isNewItem
                validatorSchema={{
                    schema: validatorSchema,
                    type: 'create',
                }}
                messageKey="Register_Evacuee"
            >
                <FormElementContainer
                    attribute="completedAt"
                    conditionalRender={(data) => !data.value}
                >
                    {/* <FormReset shouldClear={isCreate} /> */}
                    <Typography variant="h2">Personen registrieren</Typography>
                    <hr />
                    <Box style={{
                        display: 'flex',
                        gap: '1rem',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                        marginTop: '1rem',
                    }}
                    >
                        <Box style={{flexGrow: 1, flexShrink: 1, flexBasis: '450px'}}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <FormElementAutocomplete
                                        label="Person"
                                        attribute="userId"
                                        optionReference="user"
                                        dataSchema={userDataSchema}
                                        data-test="FormElementAutocomplete_userId"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormElementLoadingButton label="Registrieren" startIcon={<HowToReg />} />
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </FormElementContainer>
            </FormWrapper>
        </ItemData>
    );
}

export {RegisterEvacueeFormular};
