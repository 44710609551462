const {FILEEXTENSIONS_IMAGE} = require('../constants');
const {
    EMAIL_REGEX, NAME_REGEX, UUID_REGEX, FILE_KEY_REGEX, ISO8601_REGEX,
} = require('../regex');

/**
 * @type {import('beyond-validators').ValidationSchema}
 */
const schema = {
    frontend: {
        active: [
            {
                formType: ['create', 'update'],
                actions: ['save'],
                configurations: ['type'],
            },
        ],
        contactFirstName: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['type', 'length', 'format', 'required'],
            },
        ],
        contactLastName: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['type', 'length', 'format'],
            },
        ],
        contactEMailAddress: [
            {
                formType: ['create'],
                actions: ['blur', 'save'],
                configurations: ['type', 'length', 'format', 'required'],
            },
        ],
        imageKey: [
            {
                formType: ['create', 'update'],
                actions: ['save'],
                configurations: ['format', 'fileExtensions'],
            },
        ],
        lineManagerUserId: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['format'],
            },
        ],
        organizationalUnitId: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['format'],
            },
        ],
        entryDate: [{
            formType: ['create', 'update'],
            actions: ['save', 'blur'],
            configurations: ['format'],
        }],
        exitDate: [{
            formType: ['create', 'update'],
            actions: ['save', 'blur'],
            configurations: ['format'],
        }],
    },
    backend: {
        id: [
            {
                actions: ['update'],
                configurations: ['format', 'required'],
            },
        ],
        active: [
            {
                actions: ['create', 'update'],
                configurations: ['type'],
            },
        ],
        contactFirstName: [
            {
                actions: ['create', 'update'],
                configurations: ['type', 'length', 'format', 'required'],
            },
        ],
        contactLastName: [
            {
                actions: ['create', 'update'],
                configurations: ['type', 'length', 'format'],
            },
        ],
        contactEMailAddress: [
            {
                actions: ['create'],
                configurations: ['type', 'length', 'format', 'unique'],
            },
        ],
        imageKey: [
            {
                actions: ['create', 'update'],
                configurations: ['format', 'fileExtensions'],
            },
        ],
        lineManagerUserId: [
            {
                actions: ['create', 'update'],
                configurations: ['format'],
            },
        ],
        organizationalUnitId: [
            {
                actions: ['create', 'update'],
                configurations: ['format'],
            },
        ],
        entryDate: [
            {
                actions: ['create', 'update'],
                configurations: ['format'],
            },
        ],
        exitDate: [
            {
                actions: ['create', 'update'],
                configurations: ['format'],
            },
        ],
        darkMode: [
            {
                actions: ['create', 'update'],
                configurations: ['type'],
            },
        ],
        tenantAdministrator: [
            {
                actions: ['create', 'update'],
                configurations: ['type'],
            },
        ],
        cognito: [
            {
                actions: ['create', 'update'],
                configurations: ['type'],
            },
        ],
        microsoft: [
            {
                actions: ['create', 'update'],
                configurations: ['type'],
            },
        ],
    },

    attributes: {
        id: {
            format: UUID_REGEX,
            required: true, // "create", "update"
            // message: 'Die id muss mindestens 50 Zeichen lang sein, ist einzigartig und verpflichtend.',
        },
        active: {
            type: 'Boolean',
            required: true,
        },
        contactFirstName: {
            type: 'String',
            required: true,
            length: {lte: 50},
            format: NAME_REGEX,
            // message: 'Der Vorname darf maximal 50 Zeichen lang sein, ist einzigartig und verpflichtend.',
        },
        contactLastName: {
            type: 'String',
            length: {lte: 50},
            format: NAME_REGEX,
            // message: 'Der Nachname darf maximal 50 Zeichen lang sein.',
        },
        contactEMailAddress: {
            type: 'String',
            length: {lte: 200},
            format: EMAIL_REGEX,
            required: true,
            unique: true,
            // message: 'Die E-Mail Adresse darf maximal 50 Zeichen lang sein.',
        },
        imageKey: {
            format: FILE_KEY_REGEX,
            fileExtensions: FILEEXTENSIONS_IMAGE,
        },
        lineManagerUserId: {
            format: UUID_REGEX,
        },
        organizationalUnitId: {
            format: UUID_REGEX,
        },
        entryDate: {
            format: ISO8601_REGEX,
        },
        exitDate: {
            format: ISO8601_REGEX,
        },
        darkMode: {
            type: 'Boolean',
        },
        tenantAdministrator: {
            type: 'Boolean',
        },
        cognito: {
            type: 'Boolean',
        },
        microsoft: {
            type: 'Boolean',
        },
    },
};

module.exports = {schema};
