import _ from 'lodash';

import {getTenant} from 'graphql/beyondBuddy/Tenant/queries';
import {listOrganizationalUnits} from 'graphql/beyondBuddy/OrganizationalUnit/queries';
import {listUsers} from 'graphql/beyondBuddy/User/queries';
import {listGroups} from 'graphql/beyondBuddy/Group/queries';

import {listVehicles} from 'graphql/driveBuddy/Vehicle/queries';

import {listTimePeriods} from 'graphql/timeBuddy/TimePeriod/queries';
import {formatDateTimeString} from 'helper/date';
import {listWorkingTimeModels} from 'graphql/timeBuddy/WorkingTimeModel/queries';
import {listWorkingTimeLogTemplates} from 'graphql/timeBuddy/WorkingTimeLogTemplate/queries';
import {listWorkplaces} from 'graphql/timeBuddy/Workplace/queries';

import {listCemeteries} from 'graphql/peaceBuddy/Cemetery/queries';
import {listGravesLink} from 'graphql/peaceBuddy/Grave/queries';
import {listCustomers} from 'graphql/beyondBuddy/Customer/queries';
import {getCustomerName} from 'applications/beyondbuddy/settings/forms/customer/CustomerListFormular';
import {listDeceasedPersonsLink} from 'graphql/peaceBuddy/DeceasedPerson/queries';
import {getFullName} from 'helper/name';

/**
 * @param {import('./formElements').FormElementEntityChooserDataSchemaOptions} [schemaOptions] - options to change schema behaviour
 * @returns {import('./formElements').FormElementEntityChooserDataSchema} - the schema for loading entity data
 */
export const dataSchemaTenant = (schemaOptions) => ({
    // Temporarily based on getTenant since only the own tenant is currently available
    query: getTenant,
    queryVariablesMask: {id: true},
    queryVariables: {
        global: {id: 'tenantId'},
    },
    dataKey: 'id',
    routeId: schemaOptions?.routeId ?? 'beyondbuddy_settings_general_tenant_route',
    routeParams: schemaOptions?.routeParams,
    routeHash: schemaOptions?.routeHash,
    resultPreprocess: schemaOptions?.resultPreprocess ?? ((tenant) => ({items: [tenant]})),
    label: schemaOptions?.label ?? 'Mandant',
    getPrimaryValue: (item) => item?.companyName ?? _.compact([item?.contactFirstName, item?.contactLastName]).join(' ') ?? item.contactEMailAddress,
    getSecondaryValue: schemaOptions?.getSecondaryValue,
    getFilterMap: () => ({}),
});

/**
 * @param {import('./formElements').FormElementEntityChooserDataSchemaOptions} [schemaOptions] - options to change schema behaviour
 * @returns {import('./formElements').FormElementEntityChooserDataSchema} - the schema for loading entity data
 */
export const dataSchemaUser = (schemaOptions) => ({
    query: listUsers,
    queryVariablesMask: {
        tenantId: true,
        filter: false,
        ...schemaOptions?.queryVariablesMask,
    },
    queryVariables: {
        global: {tenantId: 'tenantId', ...schemaOptions?.queryVariables?.global},
        direct: schemaOptions?.queryVariables?.direct,
    },
    label: schemaOptions?.label ?? 'Benutzer',
    dataKey: 'id', // needed for merging
    routeId: schemaOptions?.routeId ?? 'beyondbuddy_settings_general_user_route',
    routeParams: schemaOptions?.routeParams,
    routeHash: schemaOptions?.routeHash,
    resultPreprocess: schemaOptions?.resultPreprocess,
    getPrimaryValue: schemaOptions?.getPrimaryValue ?? ((item) => `${item?.contactFirstName} ${item?.contactLastName || ''}`),
    getSecondaryValue: schemaOptions?.getSecondaryValue ?? ((item) => item?.contactEMailAddress),
    getFilterMap: schemaOptions?.getFilterMap ?? ((search) => ({search, active: true})),
    isDisabled: schemaOptions?.isDisabled ?? ((item) => (item?.active === false)),
});

/**
 * @param {import('./formElements').FormElementEntityChooserDataSchemaOptions} [schemaOptions] - options to change schema behaviour
 * @returns {import('./formElements').FormElementEntityChooserDataSchema} - the schema for loading entity data
 */
export const dataSchemaGroup = (schemaOptions) => ({
    query: listGroups,
    queryVariablesMask: {
        tenantId: true,
        filter: false,
        ...schemaOptions?.queryVariablesMask,
    },
    queryVariables: {
        global: {tenantId: 'tenantId', ...schemaOptions?.queryVariables?.global},
        direct: schemaOptions?.queryVariables?.direct,
    },
    label: schemaOptions?.label ?? 'Gruppe',
    dataKey: 'id', // needed for merging
    routeId: schemaOptions?.routeId ?? 'beyondbuddy_settings_general_group_route',
    routeParams: schemaOptions?.routeParams,
    routeHash: schemaOptions?.routeHash,
    resultPreprocess: schemaOptions?.resultPreprocess,
    getPrimaryValue: schemaOptions?.getPrimaryValue ?? ((item) => item?.name),
    getSecondaryValue: schemaOptions?.getSecondaryValue,
    getFilterMap: schemaOptions?.getFilterMap ?? ((search) => ({search})),
    isDisabled: schemaOptions?.isDisabled ?? (() => false),
});

/**
 * @param {import('./formElements').FormElementEntityChooserDataSchemaOptions} [schemaOptions] - options to change schema behaviour
 * @returns {import('./formElements').FormElementEntityChooserDataSchema} - the schema for loading entity data
 */
export const dataSchemaOrganizationalUnit = (schemaOptions) => ({
    query: listOrganizationalUnits,
    queryVariablesMask: {
        tenantId: true,
        filter: false,
        ...schemaOptions?.queryVariablesMask,
    },
    queryVariables: {
        global: {tenantId: 'tenantId', ...schemaOptions?.queryVariables?.global},
        direct: schemaOptions?.queryVariables?.direct,
    },
    label: schemaOptions?.label ?? 'Organisationseinheit',
    dataKey: 'id', // needed for merging
    routeId: schemaOptions?.routeId ?? 'beyondbuddy_settings_general_organizationalunit_route',
    routeParams: schemaOptions?.routeParams,
    routeHash: schemaOptions?.routeHash,
    resultPreprocess: schemaOptions?.resultPreprocess,
    getPrimaryValue: schemaOptions?.getPrimaryValue ?? ((item) => item?.name),
    getSecondaryValue: schemaOptions?.getSecondaryValue,
    getFilterMap: schemaOptions?.getFilterMap ?? ((search) => ({search})),
    isDisabled: schemaOptions?.isDisabled ?? (() => false),
});

/**
 * @param {import('./formElements').FormElementEntityChooserDataSchemaOptions} [schemaOptions] - options to change schema behaviour
 * @returns {import('./formElements').FormElementEntityChooserDataSchema} - the schema for loading entity data
 */
export const dataSchemaCustomer = (schemaOptions) => ({
    query: listCustomers,
    queryVariablesMask: {
        tenantId: true,
        filter: false,
        ...schemaOptions?.queryVariablesMask,
    },
    queryVariables: {
        global: {tenantId: 'tenantId', ...schemaOptions?.queryVariables?.global},
        direct: schemaOptions?.queryVariables?.direct,
    },
    label: schemaOptions?.label ?? 'Kunde',
    dataKey: 'id', // needed for merging
    routeId: schemaOptions?.routeId ?? 'beyondbuddy_settings_general_customer_route',
    routeParams: schemaOptions?.routeParams,
    routeHash: schemaOptions?.routeHash,
    resultPreprocess: schemaOptions?.resultPreprocess,
    getPrimaryValue: schemaOptions?.getPrimaryValue ?? ((item) => getCustomerName(_.pick(item, 'companyName', 'titlePrefixed', 'firstName', 'lastName', 'titlePostfixed'))),
    getSecondaryValue: schemaOptions?.getSecondaryValue ?? ((item) => item.eMailAddress),
    getFilterMap: schemaOptions?.getFilterMap ?? ((search) => ({search})),
    isDisabled: schemaOptions?.isDisabled ?? (() => false),
});

// ####################################################################################

/**
 * @param {import('./formElements').FormElementEntityChooserDataSchemaOptions} [schemaOptions] - options to change schema behaviour
 * @returns {import('./formElements').FormElementEntityChooserDataSchema} - the schema for loading entity data
 */
export const dataSchemaVehicle = (schemaOptions) => ({
    query: listVehicles,
    queryVariablesMask: {
        tenantId: true,
        filter: false,
        ...schemaOptions?.queryVariablesMask,
    },
    queryVariables: {
        global: {tenantId: 'tenantId', ...schemaOptions?.queryVariables?.global},
        direct: schemaOptions?.queryVariables?.direct,
    },
    label: schemaOptions?.label ?? 'Fahrzeug',
    dataKey: 'id', // needed for merging
    routeId: schemaOptions?.routeId ?? 'drivebuddy_drive_settings_vehicle_route',
    routeParams: schemaOptions?.routeParams,
    routeHash: schemaOptions?.routeHash,
    resultPreprocess: schemaOptions?.resultPreprocess,
    getPrimaryValue: schemaOptions?.getPrimaryValue ?? ((item) => item?.name),
    getSecondaryValue: schemaOptions?.getSecondaryValue,
    getFilterMap: schemaOptions?.getFilterMap ?? ((search) => ({search})),
    isDisabled: schemaOptions?.isDisabled ?? (() => false),
});

// ####################################################################################

/**
 * @param {import('./formElements').FormElementEntityChooserDataSchemaOptions} [schemaOptions] - options to change schema behaviour
 * @returns {import('./formElements').FormElementEntityChooserDataSchema} - the schema for loading entity data
 */
export const dataSchemaTimePeriod = (schemaOptions) => ({
    query: listTimePeriods,
    queryVariablesMask: {
        filter: false,
        ...schemaOptions?.queryVariablesMask,
    },
    queryVariables: schemaOptions?.queryVariables,
    label: schemaOptions?.label ?? 'Abrechnungsperiode',
    dataKey: 'id', // needed for merging
    routeId: schemaOptions?.routeId ?? 'timebuddy_time_period_route',
    routeParams: schemaOptions?.routeParams,
    routeHash: schemaOptions?.routeHash,
    resultPreprocess: schemaOptions?.resultPreprocess,
    getPrimaryValue: schemaOptions?.getPrimaryValue ?? ((item) => `${formatDateTimeString(item.startDate, 'dd.MM.yyyy')} - ${formatDateTimeString(item.endDate, 'dd.MM.yyyy')}`),
    getSecondaryValue: schemaOptions?.getSecondaryValue,
    getFilterMap: schemaOptions?.getFilterMap ?? ((search) => ({search})),
    isDisabled: schemaOptions?.isDisabled ?? (() => false),
});

/**
 * @param {import('./formElements').FormElementEntityChooserDataSchemaOptions} [schemaOptions] - options to change schema behaviour
 * @returns {import('./formElements').FormElementEntityChooserDataSchema} - the schema for loading entity data
 */
export const dataSchemaWorkingTimeModel = (schemaOptions) => ({
    query: listWorkingTimeModels,
    queryVariablesMask: {
        tenantId: true,
        filter: false,
        ...schemaOptions?.queryVariablesMask,
    },
    queryVariables: {
        global: {tenantId: 'tenantId', ...schemaOptions?.queryVariables?.global},
        direct: schemaOptions?.queryVariables?.direct,
    },
    label: schemaOptions?.label ?? 'Arbeitszeitmodell',
    dataKey: 'id', // needed for merging
    routeId: schemaOptions?.routeId ?? 'timebuddy_workingtime_model_route',
    routeParams: schemaOptions?.routeParams,
    routeHash: schemaOptions?.routeHash,
    resultPreprocess: schemaOptions?.resultPreprocess,
    getPrimaryValue: schemaOptions?.getPrimaryValue ?? ((item) => item?.name),
    getSecondaryValue: schemaOptions?.getSecondaryValue,
    getFilterMap: schemaOptions?.getFilterMap ?? ((search) => ({search})),
    isDisabled: schemaOptions?.isDisabled ?? (() => false),
});

/**
 * @param {import('./formElements').FormElementEntityChooserDataSchemaOptions} [schemaOptions] - options to change schema behaviour
 * @returns {import('./formElements').FormElementEntityChooserDataSchema} - the schema for loading entity data
 */
export const dataSchemaWorkingTimeLogTemplate = (schemaOptions) => ({
    query: listWorkingTimeLogTemplates,
    queryVariablesMask: {
        tenantId: true, filter: false,
    },
    queryVariables: {
        global: {tenantId: 'tenantId'},
    },
    label: schemaOptions?.label ?? 'Arbeitszeitvorlage',
    dataKey: 'id', // needed for merging
    routeId: schemaOptions?.routeId ?? 'timebuddy_workingtime_template_route',
    routeParams: schemaOptions?.routeParams,
    routeHash: schemaOptions?.routeHash,
    resultPreprocess: schemaOptions?.resultPreprocess,
    getPrimaryValue: schemaOptions?.getPrimaryValue ?? ((item) => item?.name),
    getSecondaryValue: schemaOptions?.getSecondaryValue,
    getFilterMap: schemaOptions?.getFilterMap ?? ((search) => ({search})),
    isDisabled: schemaOptions?.isDisabled ?? (() => false),
});

/**
 * @param {import('./formElements').FormElementEntityChooserDataSchemaOptions} [schemaOptions] - options to change schema behaviour
 * @returns {import('./formElements').FormElementEntityChooserDataSchema} - the schema for loading entity data
 */
export const dataSchemaWorkplace = (schemaOptions) => ({
    query: listWorkplaces,
    queryVariablesMask: {
        filter: false,
        ...schemaOptions?.queryVariablesMask,
    },
    queryVariables: schemaOptions?.queryVariables,
    label: schemaOptions?.label ?? 'Arbeitsort',
    dataKey: 'id', // needed for merging
    routeId: schemaOptions?.routeId ?? 'timebuddy_workplace_route',
    routeParams: schemaOptions?.routeParams,
    routeHash: schemaOptions?.routeHash,
    resultPreprocess: schemaOptions?.resultPreprocess,
    getPrimaryValue: schemaOptions?.getPrimaryValue ?? ((item) => item?.name),
    getSecondaryValue: schemaOptions?.getSecondaryValue,
    getFilterMap: schemaOptions?.getFilterMap ?? ((search) => ({search})),
    isDisabled: schemaOptions?.isDisabled ?? (() => false),
});

// ####################################################################################

/**
 *
 * @param {import('./formElements').FormElementEntityChooserDataSchemaOptions} [schemaOptions] - options to change schema behaviour
 * @returns {import('./formElements').FormElementEntityChooserDataSchema} - the schema for loading entity data
 */
export const dataSchemaCemetery = (schemaOptions) => ({
    query: listCemeteries,
    queryVariablesMask: {
        tenantId: true,
        filter: false,
        ...schemaOptions?.queryVariablesMask,
    },
    queryVariables: {
        global: {tenantId: 'tenantId', ...schemaOptions?.queryVariables?.global},
        direct: schemaOptions?.queryVariables?.direct,
    },
    label: schemaOptions?.label ?? 'Friedhof',
    dataKey: 'id', // needed for merging
    routeId: schemaOptions?.routeId ?? 'peacebuddy_settings_cemetery_route',
    routeParams: schemaOptions?.routeParams,
    routeHash: schemaOptions?.routeHash,
    resultPreprocess: schemaOptions?.resultPreprocess,
    getPrimaryValue: schemaOptions?.getPrimaryValue ?? ((item) => item.name),
    getSecondaryValue: schemaOptions?.getSecondaryValue,
    getFilterMap: schemaOptions?.getFilterMap ?? ((search) => ({search})),
    isDisabled: schemaOptions?.isDisabled ?? (() => false),
});

/**
 * @param {import('./formElements').FormElementEntityChooserDataSchemaOptions} [schemaOptions] - options to change schema behaviour
 * @returns {import('./formElements').FormElementEntityChooserDataSchema} - the schema for loading entity data
 */
export const dataSchemaGrave = (schemaOptions) => ({
    query: listGravesLink,
    queryVariablesMask: {
        tenantId: true,
        filter: false,
        ...schemaOptions?.queryVariablesMask,
    },
    queryVariables: {
        global: {tenantId: 'tenantId', ...schemaOptions?.queryVariables?.global},
        direct: schemaOptions?.queryVariables?.direct,
    },
    label: schemaOptions?.label ?? 'Grab',
    dataKey: 'id', // needed for merging
    routeId: schemaOptions?.routeId ?? 'peacebuddy_settings_grave_route',
    routeParams: schemaOptions?.routeParams,
    routeHash: schemaOptions?.routeHash,
    resultPreprocess: schemaOptions?.resultPreprocess,
    getPrimaryValue: schemaOptions?.getPrimaryValue ?? ((item) => item?.generalNr),
    getSecondaryValue: schemaOptions?.getSecondaryValue ?? ((item) => {
        const numberPath = _.join(_.compact([item?.division, item?.subDivision, item?.nr]), '/');
        return numberPath ? `(${numberPath})` : '';
    }),
    getFilterMap: schemaOptions?.getFilterMap ?? ((generalNr) => ({generalNr})),
    isDisabled: schemaOptions?.isDisabled ?? (() => false),
});

/**
 * @param {import('./formElements').FormElementEntityChooserDataSchemaOptions} [schemaOptions] - options to change schema behaviour
 * @returns {import('./formElements').FormElementEntityChooserDataSchema} - the schema for loading entity data
 */
export const dataSchemaDeceasedPerson = (schemaOptions) => ({
    query: listDeceasedPersonsLink,
    queryVariablesMask: {
        tenantId: true,
        filter: false,
        ...schemaOptions?.queryVariablesMask,
    },
    queryVariables: {
        global: {tenantId: 'tenantId', ...schemaOptions?.queryVariables?.global},
        direct: schemaOptions?.queryVariables?.direct,
    },
    label: schemaOptions?.label ?? 'Verstorbener',
    dataKey: 'id', // needed for merging
    routeId: schemaOptions?.routeId ?? 'peacebuddy_settings_deceasedPerson_route',
    routeParams: schemaOptions?.routeParams,
    routeHash: schemaOptions?.routeHash,
    resultPreprocess: schemaOptions?.resultPreprocess,
    getPrimaryValue: schemaOptions?.getPrimaryValue ?? ((item) => getFullName(_.pick(item, 'titlePrefixed', 'firstName', 'lastName', 'titlePostfixed'))),
    getSecondaryValue: schemaOptions?.getSecondaryValue ?? ((item) => `${formatDateTimeString(item.dateOfBirth, 'dd.MM.yyyy')} - ${formatDateTimeString(item.dateOfDeath, 'dd.MM.yyyy')}`),
    getFilterMap: schemaOptions?.getFilterMap ?? ((search) => ({search})),
    isDisabled: schemaOptions?.isDisabled ?? (() => false),
});

// ####################################################################################

export const dataSchemas = {
    Tenant: dataSchemaTenant,
    User: dataSchemaUser,
    Group: dataSchemaGroup,
    OrganizationalUnit: dataSchemaOrganizationalUnit,
    Customer: dataSchemaCustomer,

    Vehicle: dataSchemaVehicle,

    TimePeriod: dataSchemaTimePeriod,
    WorkingTimeModel: dataSchemaWorkingTimeModel,
    WorkingTimeLogTemplate: dataSchemaWorkingTimeLogTemplate,
    Workplace: dataSchemaWorkplace,

    Cemetery: dataSchemaCemetery,
    Grave: dataSchemaGrave,
    DeceasedPerson: dataSchemaDeceasedPerson,
};
