import React, {
    useCallback, useMemo, useState,
} from 'react';
import {LayoutContainer} from 'assets/theme/layout/LayoutContainer/LayoutContainer';
import {CemeteryFormular} from 'applications/peacebuddy/settings/forms/cemetery/CemeteryFormular';
import {
    generatePath,
    useNavigate,
    useParams,
} from 'react-router-dom';
import {useCurrentRoute} from 'routes';

import _ from 'lodash';
import {PermissionFormular} from 'applications/beyondbuddy/settings/forms/permissions/PermissionFormular';
import {createPermissionsObject} from 'applications/configs';
import {organizationEntityKeys} from 'applications/beyondbuddy/config';
import {readUpdateDeletePermissionTemplate} from 'components/Form/FormElements/FormElementEntityLinkPermissions';
import {BlurOnOutlined, LockOutlined} from '@mui/icons-material';
import {TabFormsContainer} from 'components/Form/TabFormsContainer';

import {createCemetery, updateCemetery} from 'graphql/peaceBuddy/Cemetery/mutations';
import {getCemetery} from 'graphql/peaceBuddy/Cemetery/queries';
import {isValidGUID} from 'helper/guid';
import {NotFound} from 'applications/pages/NotFound';

const mask = {
    id: true,
    tenantId: true,
    nr: true,
    referenceId: false,
    name: true,
    address: false,
    zip: false,
    city: false,
    countryCode: false,
    eMailAddress: false,
    phoneNumber: false,
    restPeriodUrnGrave: false,
    restPeriodCoffinGrave: false,
    imageKey: false,
    attachmentKeys: false,
};

/**
 * @param {Partial<import('applications/configuration').EntityFormularProps>} props the properties
 * @returns {React.ReactElement} the grave formular
 */
const getCemeteryFormular = (props) => <CemeteryFormular {...props} />;

/**
 * @param {Partial<import('applications/configuration').EntityFormularProps>} props the properties
 * @returns {React.ReactElement} the permissions formular
 */
const getPermissionFormular = (props) => (
    <PermissionFormular
        disabled={props.readonly}
        isIncoming
        entityTypeId={`Cemetery#${props.id}`}
        actionButtonProps={{portalAnchorSelector: `#CemeteryForm${props.id}action-button-frame-permissions-actions`}}
        entityChooserProps={{
            availablePermissions: createPermissionsObject(organizationEntityKeys, ['read', 'updateGroup', 'permissionGroup']),
            defaultPermissions: createPermissionsObject(organizationEntityKeys, ['read', 'updateGroup']),
            permissionDependencies: {
                User: readUpdateDeletePermissionTemplate,
                Group: readUpdateDeletePermissionTemplate,
                OrganizationalUnit: readUpdateDeletePermissionTemplate,
                Tenant: readUpdateDeletePermissionTemplate,
            },
        }}
        {...props}
    />
);

/**
 * This page shows a create and update form for a grave.
 * Also other information as authorized customers, links and permissions are available.
 * @returns {React.ReactElement} The CemeteryPage component.
 */
function CemeteryPage() {
    const {id} = useParams();
    const isNewItem = id === 'create';
    const navigate = useNavigate();
    const [currentTab, setCurrentTab] = useState(0);

    /** @type {import('routeinfo').RoutePathInfo} */
    const route = useCurrentRoute();

    const onSave = useCallback((result) => {
        if (id === 'create' && result?.id) {
            navigate(`/${generatePath(route.path, {id: result?.id})}`);
        }
    }, [id, route, navigate]);

    /** @type {import('components/Form/form').ItemSaveConfig} */
    const saveConfig = useMemo(() => ({
        variables: {global: {tenantId: 'tenantId'}},
        ...(id !== 'create') ? {
            mutation: updateCemetery,
            mask,
        } : {
            mutation: createCemetery,
            mask: _.omit(mask, 'id'),
        },
        postProcess: (data) => ({
            ...data,
            attachmentKeys:
                'attachments' in data && data.attachments instanceof Array
                    ? JSON.stringify(data.attachments.map((item) => item.key))
                    : '[]',
        }),
    }), [id]);

    /** @type {import('components/Form/form').ItemLoadConfig} */
    const loadConfig = useMemo(() => ({
        query: getCemetery,
        variables: {direct: {id}},
        mask: {id: true},
        postProcess: (data) => ({
            ...data,
            attachmentKeys:
                'attachments' in data && data.attachments instanceof Array
                    ? JSON.stringify(data.attachments.map((item) => item.key))
                    : '[]',
        }),
    }), [id]);

    // eslint-disable-next-line function-paren-newline
    const tabFormsContainerProps = useMemo(
        /** @returns {import('components/Form/form').TabFormsContainerProps} container properties */
        () => ({
            identifier: `CemeteryForm${id}`,
            itemDataProps: {
                ...(!isNewItem) ? {loadConfig} : {},
                saveConfig,
            },
            tabs: [{
                id: 'base',
                label: 'Basisdaten',
                icon: <BlurOnOutlined />,
                getChildren: () => getCemeteryFormular({id, onSave}),
                formWrapperId: `Cemetery#${id}Base`,
                quickGuideId: 'peacebuddy_general_cemetery',
            }, {
                id: 'permissions',
                label: 'Berechtigungen',
                icon: <LockOutlined />,
                enabledCondition: 'permissionsReadable',
                getChildren: (grants) => id !== 'create' && getPermissionFormular({id, readonly: !grants.permissionsUpdatable}),
                formWrapperId: `Cemetery#${id}Permissions`,
                quickGuideId: 'beyondbuddy_general_permissions',
            },
            ],
            currentTab,
            setCurrentTab,
        }), [id, isNewItem, loadConfig, saveConfig, currentTab,
            getCemeteryFormular, getPermissionFormular, setCurrentTab, onSave]);

    // check if id is a guid and return notfound it is not
    if (!isValidGUID(id)) {
        return <NotFound />;
    }

    return (
        <LayoutContainer>
            <TabFormsContainer {...tabFormsContainerProps} breadCrumbs={[{label: 'Friedhof'}]} />
        </LayoutContainer>
    );
}
export {CemeteryPage};
