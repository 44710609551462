/** @type {import("./Messages").OptionsObject} */
const options = {
    variant: 'error',
};
/**
 * .Messages
 * @satisfies {import("./Messages").SnackbarMessage}
 */
const Exceptions = {
    GENERAL: {
        message: {
            de: 'OOPS! Es ist ein Fehler aufgetreten :(',
        },
        options,
    },
    API_LOAD_ERROR: {
        message: {
            de: 'Die Daten konnten nicht geladen werden :(',
        },
        options,
    },
    API_CONNECTION_ERROR: {
        message: {
            de: 'Die Verbindung wurde unterbrochen - bitte versuchen Sie es erneut',
        },
        options,
    },
    API_SAVE_ERROR: {
        message: {
            de: 'Der Eintrag konnte nicht gespeichert werden :(',
        },
        options,
    },
    API_SYNC_ERROR: {
        message: {
            de: 'Die Synchronisierung ist fehlgeschlagen :(',
        },
        options,
    },
    API_DELETE_ERROR: {
        message: {
            de: 'Der Eintrag konnte nicht gelöscht werden :(',
        },
        options,
    },
    MISSING_ATTRIBUTES_ERROR: {
        message: {
            de: 'Es wurden nicht alle erforderlichen Attribute übergeben',
        },
        options,
    },
    FALSE_ATTRIBUTE_ERROR: {
        message: {
            de: 'Der Wert ist nicht korrekt',
        },
    },
    STORAGE_LOAD_ERROR: {
        message: {
            de: 'Die Datei konnten nicht geladen werden :(',
        },
        options,
    },
    STORAGE_SAVE_ERROR: {
        message: {
            de: 'Die Datei konnte nicht hochgeladen werden :(',
        },
        options,
    },
    STORAGE_TYPE_ERROR: {
        message: {
            de: 'Die Datei entspricht nicht dem erwarteten typ :(',
        },
        options,
    },
    API_ITEM_IS_NOT_UNIQUE: {
        message: {
            de: 'Es existiert bereits ein Eintrag mit diesen Parametern',
        },
        options,
    },
    API_AUTHORIZATION_PERMISSION_MISSING: {
        message: {
            de: 'Die Berechtigungen reichen nicht aus um die Aktion durchzuführen.',
        },
        options,
    },
    API_VALIDATION_FAILED: {
        message: {
            de: 'Die Validierung ist Fehlgeschlagen :(',
        },
        options,
    },
    INVALID_DRAFT: {
        message: {
            de: 'Der Entwurf enthält ungültige Werte',
        },
        options,
    },
    EMPTY_ITEM: {
        message: {
            de: 'Leere Formulare können nicht gespeichert werden',
        },
        options,
    },
    ITEM_NOT_FOUND: {
        message: {
            de: 'Der Eintrag konnte nicht gefunden werden',
        },
        options,
    },
};

export {Exceptions};
