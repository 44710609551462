import React, {useCallback, useMemo, useState} from 'react';
import {LayoutContainer} from 'assets/theme/layout/LayoutContainer/LayoutContainer';
import {UserFormular} from 'applications/beyondbuddy/settings/forms/user/UserFormular';

import {TabFormsContainer} from 'components/Form/TabFormsContainer';
import {getUser} from 'graphql/beyondBuddy/User/queries';
import {updateUser} from 'graphql/beyondBuddy/User/mutations';
import {
    BlurOnOutlined, DescriptionOutlined, ViewTimelineOutlined,
} from '@mui/icons-material';
import _ from 'lodash';
import {useCanAccess} from 'hooks/useCanAccess';
import {WorkingTimeModelAssignmentsListFormular} from 'applications/timebuddy/modules/workingtime/forms/workingTimeModelAssignment/WorkingTimeModelAssignmentListFormular';
import {WorkingTimeTimeAccountReport} from 'applications/timebuddy/modules/workingtime/forms/workingTimeReports/WorkingTimeTimeAccountReport';
import {useGlobalState} from 'hooks/useGlobalState';

/**
 * @param {Partial<import('applications/configuration').EntityFormularProps>} props the properties
 * @returns {React.ReactElement} the formular
 */
const getUserFormular = (props) => <UserFormular {...props} />;

/**
 * @param {object} props the properties
 * @returns {React.ReactElement} the formular
 */
const getWorkingTimeAssignmentFormular = (props) => <WorkingTimeModelAssignmentsListFormular {...props} />;

/**
 * @param {object} props the properties
 * @returns {React.ReactElement} the formular
 */
const getWorkingTimeTimeAccountReport = (props) => <WorkingTimeTimeAccountReport {...props} />;

const mask = {
    id: true,
    cognito: false,
    microsoft: false,
    active: true,
    tenantId: true,
    contactFirstName: false,
    contactLastName: false,
    contactEMailAddress: true,
    tenantAdministrator: false,
    imageKey: false,
    personalNumber: false,
    image: false,
    lineManagerUserId: false,
    organizationalUnitId: false,
    emailNotifications: false,
    entryDate: false,
    exitDate: false,
    colormode: false,
};

/**
 *
 * @returns {React.ReactElement} The ProfilePage.
 */
function ProfilePage() {
    const {getGlobal, setGlobal} = useGlobalState();
    const [currentTab, setCurrentTab] = useState(0);

    const canAccessWorkingTimeModelAssignments = useCanAccess('WorkingTime', 'readWorkingTimeModelAssignment', 'readWorkingTimeModel');
    const canAccessWorkingTimeLog = useCanAccess('WorkingTime', 'readWorkingTimeLog');

    // getting the URL parameters
    const user = getGlobal('user');

    const onSave = useCallback((result) => {
        if ('key' in result) {
            setGlobal('user', {
                ...user,
                image: result,
            });
        } else {
            setGlobal('user', {
                ...user,
                ...result,
            });
        }
    }, [setGlobal, user]);

    const loadConfig = useMemo(() => ({
        query: getUser,
        variables: {direct: {id: user.id}},
        mask: {id: true},
    }), [user.id]);

    /** @type {import('components/Form/form').ItemSaveConfig}  */
    const saveConfig = useMemo(() => ({
        variables: {global: {tenantId: 'tenantId'}},
        mutation: updateUser,
        mask,
    }), []);

    // eslint-disable-next-line function-paren-newline
    const tabFormsContainerProps = useMemo(
        /** @returns {import('components/Form/form').TabFormsContainerProps} container properties */
        () => ({
            identifier: `UserForm${user.id}`,
            itemDataProps: {
                loadConfig,
                saveConfig,
            },
            tabs: _.compact([{
                id: 'base',
                label: 'Basisdaten',
                icon: <BlurOnOutlined />,
                getChildren: () => getUserFormular({id: user.id, onSave}),
                formWrapperId: `User#${user.id}Base`,
            },
            canAccessWorkingTimeModelAssignments ? {
                id: 'timemodel_history',
                label: 'Arbeitszeitmodell',
                icon: <DescriptionOutlined />,
                enabledCondition: 'workingTimeModelAssignmentReadable',
                getChildren: () => getWorkingTimeAssignmentFormular({userId: user.id}),
            } : undefined,
            canAccessWorkingTimeLog ? {
                id: 'time_accounts',
                label: 'Zeitkonten',
                icon: <ViewTimelineOutlined />,
                enabledCondition: 'permissionsReadable',
                getChildren: (grants, subGrants) => getWorkingTimeTimeAccountReport(
                    {userId: user.id, workingTimeAggregateCorrectable: subGrants.workingTimeAggregateCorrectable ?? false},
                ),
                formWrapperId: `User#${user.id}WorkingTimeAggregateCorrection`,
            } : undefined]),
            currentTab,
            setCurrentTab,
        }), [user.id, loadConfig, saveConfig, currentTab, canAccessWorkingTimeModelAssignments,
            setCurrentTab, onSave, getUserFormular, getWorkingTimeAssignmentFormular]);

    return (
        <LayoutContainer>
            <TabFormsContainer {...tabFormsContainerProps} />
        </LayoutContainer>
    );
}
export {ProfilePage};
