import React, {
    useMemo, useCallback, useState, useEffect, useContext, useRef,
} from 'react';
import _ from 'lodash';
import {FormContext, FormWrapper} from 'components/Form/FormWrapper';
import {FormElementTextField} from 'components/Form/FormElements/FormElementTextField';
import {FormElementAutocomplete} from 'components/Form/FormElements/FormElementAutocomplete';
import {FormElementLoadingButton} from 'components/Form/FormElements/FormElementLoadingButton';
import {
    Accordion,
    AccordionSummary,
    Box, Button, Collapse, Grid, Typography,
} from '@mui/material';
import {ItemDataContext} from 'components/Form/ItemData';
import {FormElementInfoChips} from 'components/Form/FormElements/FormElementInfoChips';
import {
    AddCircleOutline, Refresh,
} from '@mui/icons-material';
import {FormElementActionButton} from 'components/Form/FormElements/FormElementActionButton';
import {useCanAccess} from 'hooks/useCanAccess';
import {listWorkingTimeModelOptions} from 'graphql/timeBuddy/WorkingTimeModel/queries';
import {FormElementCheckbox} from 'components/Form/FormElements/FormElementCheckbox';
import {FormElementContainer} from 'components/Form/FormElements/FormElementContainer';
import FullCalendar from '@fullcalendar/react';
import timegrid from '@fullcalendar/timegrid';
import interaction from '@fullcalendar/interaction';
import {FormReset} from 'components/Form/FormReset';
import {BeyondCalendar} from 'assets/theme/components/Calendar/BeyondCalendar';

const {schema: validatorSchema} = require('beyond-validators/timeBuddy/WorkingTimeModel');

const templateSchema = {
    query: listWorkingTimeModelOptions,
    queryVariablesMask: {tenantId: true},
    queryVariables: {global: {tenantId: 'tenantId'}},
    dataKey: 'id',
    getOptionLabel: (option) => (option?.revision ? `${option?.name} #${option.revision}` : option?.name),
    getOptionValue: (option) => option?.id,
};
const dateZero = new Date(0, 0, 1, 0, 0, 0, 0);

const weekdays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

const workingHoursSpec = Object.fromEntries(weekdays.map((day) => [day, {from: true, until: true, multiplier: true}]));
/** @type {import('helper/recommendation-manager').RecommendationConfig} */
const recommendationConfig = {
    mask: {
        id: true,
    },
    skipInvalid: true,
    valueDependencies: {
        normalWorkingHours: ['templateId'],
        agreedWorkingHours: ['templateId'],
        fixedWorkingHours: ['templateId'],
        workHoursMultiplication: ['templateId'],
        info: ['templateId'],
        partTime: ['templateId'],
        flexibleTime: ['templateId'],
        dailyHours: ['templateId'],
        vacationClaimWeeks: ['templateId'],
    },
    preProcess: (({templateId}) => ({id: templateId})),
    postProcess: (values) => ({
        ...values,
        partTime: Boolean(values?.agreedWorkingHours),
        flexibleTime: Boolean(values?.fixedWorkingHours),
    }),
    querySpec: {
        queryName: 'getWorkingTimeModel',
        queryLabel: 'GetWorkingTimeModelTemplate',
        possibleAttributes: {
            normalWorkingHours: workingHoursSpec,
            fixedWorkingHours: workingHoursSpec,
            agreedWorkingHours: workingHoursSpec,
            workHoursMultiplication: workingHoursSpec,
            info: true,
            dailyHours: true,
            weeklyHours: true,
            weeklyDays: true,
            vacationClaimWeeks: true,
        },
        possibleInputVariables: {
            id: 'ID!',
        },
    },
};

const relativeTimeToDate = (weekday, time) => {
    const weekdayIndex = weekdays.indexOf(weekday) || 7;
    const date = new Date(0, 0, weekdayIndex, ...time.split(':'));
    date.setDate(weekdayIndex);
    return date;
};

/**
 * Creates a reusable section depicting working hours over the week
 * @param {object} props - props for the working hours section
 * @param {string} props.attribute - key under which the working hour data will be saved
 * @param {string} props.label - label which is placed atop the section
 * @param {boolean} [props.open] - indicator that the section should be shown at all
 * @param {string} [props.backgroundEventAttribute] - attribute where background events will be sourced from. None if omitted
 * @param {boolean} [props.weighted] - flag that turns on weight setting in each shift
 * @returns {React.ReactElement} component to display
 */
function WorkingHoursSection({
    label, attribute, open, backgroundEventAttribute, weighted = false,
}) {
    const [expanded, setExpanded] = useState(true);
    const {changeHandler, get, isReadonly} = useContext(FormContext);
    const {value} = get(attribute);
    const {value: background} = backgroundEventAttribute ? get(backgroundEventAttribute) : {value: null};
    const [currentlySelectedEvent, setCurrentlySelectedEvent] = useState(null);
    const currentlySelectedEventPath = useMemo(() => {
        let index;
        const day = _.findKey(value, (slots, weekday) => {
            index = _.findIndex(slots, (slot) => (
                relativeTimeToDate(weekday, slot.from).toISOString()
                === currentlySelectedEvent
            ));
            return index !== -1;
        });
        return `${day}[${index}]`;
    }, [currentlySelectedEvent, value]);
    const events = useMemo(() => {
        /** @type {import('@fullcalendar/core').EventInput[]} */
        const es = [];
        _.entries(value).forEach(([key, slots]) => {
            if (slots instanceof Array) {
                slots.forEach((e) => {
                    const start = relativeTimeToDate(key, e.from);
                    const end = relativeTimeToDate(key, e.until);
                    es.push({
                        start,
                        end,
                        editable: !isReadonly,
                        color: currentlySelectedEvent === start.toISOString()
                            ? 'orange'
                            : 'blue',
                        constraint: {startTime: e.from, allDay: true},
                        id: start.toISOString(),
                        extendedProps: e.multiplier
                            ? {multiplier: e.multiplier}
                            : {},
                    });
                });
            }
        });
        return es;
    }, [value, background, isReadonly, currentlySelectedEvent]);

    /** @type {import('@fullcalendar/core').BusinessHoursInput} */
    const businessHours = useMemo(() => _.flatMap(background, (slots, key) => {
        if (slots instanceof Array && slots.length) {
            return slots.map(({from, until}) => ({
                daysOfWeek: [weekdays.indexOf(key)],
                startTime: from,
                endTime: until,
            }));
        }
        return [];
    }), [background]);

    /** @type {React.MutableRefObject<FullCalendar>} */
    const ref = useRef();

    return (
        <Grid item xs={12}>
            <Collapse in={open}>
                <Accordion expanded={expanded}>
                    <AccordionSummary onClick={() => setExpanded((v) => !v)}><Typography>{label}</Typography></AccordionSummary>
                    <Box sx={{
                        '.fc': {
                            userSelect: 'none',
                        },
                    }}
                    >
                        <BeyondCalendar
                            calendarOptions={{
                                ref,
                                height: expanded ? undefined : 0,
                                businessHours,
                                plugins: [timegrid, interaction],
                                eventMaxStack: 1,
                                dayHeaderFormat: {weekday: 'short'},
                                aspectRatio: 2,
                                initialView: 'timeGridWeek',
                                locale: 'de-at',
                                firstDay: 1,
                                allDaySlot: false,
                                events,
                                selectOverlap: (event) => event.display === 'background',
                                eventClick: ({event, jsEvent}) => {
                                    if (!isReadonly && !weighted) {
                                        ref.current.getApi().getEventById(event.id).remove();
                                    }
                                    if (weighted) {
                                        setCurrentlySelectedEvent(event.id);
                                    }
                                    jsEvent.preventDefault();
                                },
                                eventsSet: (evt) => {
                                    const slots = evt.filter((event) => event.display !== 'background').map((e) => ({
                                        ...e.extendedProps,
                                        week: weekdays[e.start.getDay()],
                                        from: e.start.toLocaleTimeString('de-at'),
                                        until: e.end.toLocaleTimeString('de-at'),
                                    }));
                                    const newValue = _(slots)
                                        .groupBy('week')
                                        .mapValues((l) => l.map((v) => _.omit(v, 'week')))
                                        .value();
                                        // Only comparing to existing values, hence omitBy isNil

                                    if (value && newValue && !_.isEqual(
                                        _.chain(value)
                                            .omitBy((v) => _.isNil(v) || _.isEmpty(v))
                                            .mapValues((v) => _.map(v, (x) => _.omitBy(x, (vb) => _.isNil(vb) || _.isEmpty(vb))))
                                            .value(),
                                        Object.assign(
                                            Object.fromEntries(weekdays
                                                .map((day) => [day, []])
                                                .filter(([, v]) => !_.isNil(v) && !_.isEmpty(v))),
                                            newValue,
                                        ),
                                    )) {
                                        setTimeout(() => {
                                            changeHandler({
                                                attribute,
                                                value: newValue,
                                                interacted: true,
                                            });
                                        }, 1);
                                    }
                                },
                                select: (evt) => {
                                    const weekday = weekdays[evt.start.getDay()];
                                    const {start, end} = evt;

                                    changeHandler({
                                        attribute,
                                        value: {
                                            ...(value ?? Object()),
                                            [weekday]: [
                                                ...(value?.[weekday] ?? []),
                                                {
                                                    from: start.toLocaleTimeString('de-at'),
                                                    until: end.toLocaleTimeString('de-at'),
                                                },
                                            ],
                                        },
                                        displayValue: null,
                                        interacted: true,
                                    });
                                },
                                selectable: !isReadonly,
                                selectAllow: (event) => event.start.getDay() === event.end.getDay(),
                                headerToolbar: false,
                                initialDate: dateZero,
                            }}
                        />
                        {weighted && (currentlySelectedEvent) && (
                            <Grid container gap="1rem" marginTop="1rem">
                                <Grid item xs={5.5}>
                                    <FormElementTextField
                                        label="Multiplikator"
                                        attribute={`${attribute}.${currentlySelectedEventPath}.multiplier`}
                                        placeholder="1,0"
                                        type="float"
                                    />
                                </Grid>
                                <Grid item xs={5.5}>
                                    <Button onClick={() => {
                                        ref.current.getApi().getEventById(currentlySelectedEvent).remove();
                                        setCurrentlySelectedEvent(null);
                                    }}
                                    >
                                        Löschen
                                    </Button>
                                </Grid>
                            </Grid>
                        )}
                    </Box>
                </Accordion>
            </Collapse>

        </Grid>
    );
}

/**
 * Computes the difference in time in two time strings. They are expected to follow the format /\d\d(:\d\d){1,2}/
 * @param {string} first - first time string. Should be the earlier one
 * @param {string} second - second time string. Should be the later one
 * @returns {number} difference in milliseconds
 */
const timeDistance = (first, second) => Date.parse(`0-${second}`) - Date.parse(`0-${first}`);

/**
 * The WorkingTimeModel formular for creating and updating a WorkingTimeModel
 * .TimeBuddy.Forms
 * @param {import('applications/configuration').EntityFormularProps} props - props passed to the component
 * @returns {React.ReactElement} The WorkingTimeModelFormular component.
 */
function WorkingTimeModelFormular({id, onSave, ...rest}) {
    const [crossRevId, setCrossRevId] = useState(null);
    const isItemNew = id === 'create';
    const {data} = useContext(ItemDataContext);

    useEffect(() => {
        setCrossRevId(data?.crossRevId);
    }, [data, setCrossRevId]);

    const canCreate = useCanAccess('createWorkingTimeModel');

    return (
        <FormWrapper
            recommendationConfig={recommendationConfig}
            {...rest}
            isNewItem={isItemNew}
            validatorSchema={{
                schema: validatorSchema,
                type: 'create',
            }}
            onSaveCallback={(result) => {
                if (_.isFunction(onSave)) {
                    onSave(result);
                }
            }}
            messageKey="WorkingTimeModel_Create"
            context={`WorkingTimeModel#${id}Base`}
        >
            <FormReset shouldClear={isItemNew} />
            <Box style={{
                display: 'flex',
                gap: '1rem',
                flexWrap: 'wrap',
                justifyContent: 'center',
            }}
            >
                <FormElementActionButton
                    routeId="timebuddy_workingtime_model_route"
                    routeParams={{id: 'create'}}
                    portalAnchorSelector={`#WorkingTimeModelForm${id}action-button-frame-base-actions`}
                    formWrapperIdPattern={`WorkingTimeModel#${id}`}
                    disabled={isItemNew || !canCreate}
                    context={FormContext}
                >
                    <AddCircleOutline />
                </FormElementActionButton>
                <FormElementActionButton
                    reload
                    portalAnchorSelector={`#WorkingTimeModelForm${id}action-button-frame-base-actions`}
                    formWrapperIdPattern={`WorkingTimeModel#${id}`}
                    disabled={isItemNew}
                    context={FormContext}
                >
                    <Refresh />
                </FormElementActionButton>
                <Box style={{flexGrow: 1, flexShrink: 1, flexBasis: '450px'}}>
                    <Grid container spacing={2}>
                        <FormElementInfoChips showDraft />
                        <Grid item xs={12} md={6}>
                            <FormElementTextField label="Name" attribute="name" required />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormElementAutocomplete
                                label="Vorlage"
                                required
                                attribute="templateId"
                                optionReference="template"
                                dataSchema={templateSchema}
                                optionsFilter={useCallback((option) => option?.id !== id && option?.crossRevId !== crossRevId, [id, crossRevId])}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormElementTextField rows={3} label="Info" attribute="info" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormElementCheckbox label="Teilzeit" attribute="partTime" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormElementCheckbox label="Gleitzeit" attribute="flexibleTime" />
                        </Grid>
                        <Grid item xs={4}>
                            <FormElementContainer
                                attribute="*"
                                propsMapping={({value}) => ({
                                    InputLabelProps: {
                                        shrink: true,
                                    },
                                    placeholder: String('weeklyHours' in value && value.weeklyHours ? value.weeklyHours : Object
                                        .values(value?.normalWorkingHours ?? {})
                                        .flat()
                                        .reduce((sum, slot) => sum + (timeDistance(slot.from, slot.until) / (1000 * 60 * 60)), 0)) ?? 0,
                                })}
                            >
                                <FormElementTextField label="Wöchentliche Stunden" type="float" attribute="weeklyHours" />
                            </FormElementContainer>
                        </Grid>
                        <Grid item xs={4}>
                            <FormElementContainer
                                attribute="*"
                                propsMapping={({value}) => ({
                                    InputLabelProps: {
                                        shrink: true,
                                    },
                                    placeholder: String('weeklyDays' in value && value.weeklyDays ? value.weeklyDays : Object
                                        .values(value?.normalWorkingHours ?? {})
                                        .filter((slots) => slots.length)
                                        .length) ?? 0,
                                })}
                            >
                                <FormElementTextField label="Wöchentliche Tage" type="int" attribute="weeklyDays" />
                            </FormElementContainer>
                        </Grid>
                        <Grid item xs={4}>
                            <FormElementTextField label="Maximale Stunden pro Tag" type="float" attribute="dailyHours" required />
                        </Grid>
                        <Grid item xs={12}>
                            <FormElementTextField label="Wochen an Urlaubsanspruch" attribute="vacationClaimWeeks" type="float" required />
                        </Grid>
                        <WorkingHoursSection label="Normalarbeitszeit" open attribute="normalWorkingHours" />
                        <FormElementContainer attribute="partTime" propsMapping={(v) => ({open: Boolean(v.value)})}>
                            <WorkingHoursSection label="Vereinbarte Arbeitszeit" attribute="agreedWorkingHours" backgroundEventAttribute="normalWorkingHours" />
                        </FormElementContainer>
                        <FormElementContainer attribute="flexibleTime" propsMapping={(v) => ({open: Boolean(v.value)})}>
                            <WorkingHoursSection label="Kernarbeitszeit" attribute="fixedWorkingHours" backgroundEventAttribute="normalWorkingHours" />
                        </FormElementContainer>
                        <WorkingHoursSection label="Multiplikatoren" open weighted attribute="workHoursMultiplication" backgroundEventAttribute="normalWorkingHours" />
                        <Grid item xs={6}>
                            <FormElementLoadingButton />
                        </Grid>
                    </Grid>
                </Box>
            </Box>

        </FormWrapper>
    );
}

export {WorkingTimeModelFormular};
