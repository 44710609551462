/* eslint-disable react/no-array-index-key */
import React, {
    useMemo, useContext,
    useCallback,
    useState,
} from 'react';
import {
    Grid,
    Typography,
    Button,
    Alert,
    Skeleton,
    Card,
    CardContent,
} from '@mui/material';
import classes from 'applications/peacebuddy/settings/forms/grave/FormElementGraveRecordPositionChooser.module.scss';
import {FormContext} from 'components/Form/FormWrapper';
import _ from 'lodash';
import {graveUnitPositionTypeDataSchema} from 'applications/peacebuddy/settings/forms/grave/GraveSchema';

/**
 * FormElementGraveRecordPositionChooser Component
 * @param {object} props - properties of the FormElementGraveRecordPositionChooser
 * @param {import('applications/peacebuddy/types').GraveEntity['unitPositions']} props.unitPositions - available positions
 * @param {boolean} props.graveFormChanged - disables the chooser
 * @returns {React.ReactElement} FormElementGraveRecordPositionChooser component
 */
export function FormElementGraveRecordPositionChooser({unitPositions, graveFormChanged}) {
    /**
     * Destructuring the FormContext and assigning the values.
     */
    const {
        isLoading, get, changeHandler, onBlurHandler, isReadonly,
    } = useContext(FormContext);

    /**
     * Retrieving the value of the FormElement from the context
     * In case of no value, it returns the corresponding value for "no value" (e.g. null)
     * TODO: Negative numbers are not allowed in the input field. This should be fixed.
     */
    const [graveUnitId] = useMemo(() => [get('graveUnitId')], [get]);
    const [graveRecordId] = useMemo(() => [get('id')], [get]);

    // track the original value to know if the grave unit can be selected, even when used
    const [originalGraveRecordId, setOriginalGraveRecordId] = useState();

    const onSelect = useCallback((el) => {
        const isSelf = el.id === graveUnitId?.value;
        if (!originalGraveRecordId) {
            setOriginalGraveRecordId(graveUnitId?.value);
        }

        if (!isReadonly) {
            changeHandler({
                attribute: 'graveUnitId',
                value: isSelf ? null : el.id,
                error: graveUnitId?.error,
                interacted: true,
            });
            onBlurHandler({
                attribute: 'graveUnitId',
                value: isSelf ? null : el.id,
                error: graveUnitId?.error,
                interacted: true,
            });
        }
    }, [graveUnitId, originalGraveRecordId, isReadonly, changeHandler]);

    const disabled = useCallback((el) => {
        const isSelectedGraveUnit = (graveUnitId?.value && el.id === graveUnitId?.value) || (originalGraveRecordId && el.id === originalGraveRecordId);
        const isFree = !el.graveRecordId || el.graveRecordId === graveRecordId;

        return graveFormChanged || isLoading.save || (!isSelectedGraveUnit && !isFree);
    }, [graveFormChanged, graveUnitId?.value, graveRecordId]);

    if (isLoading.load) {
        return (
            <Card variant="outlined">
                <CardContent className={classes['grave-container']}>
                    <Grid container spacing={2} wrap="nowrap">
                        <Grid item>
                            <Skeleton variant="rectangular" animation="wave" className={classes['grave-element']} />
                        </Grid>
                        <Grid item>
                            <Skeleton variant="rectangular" animation="wave" className={classes['grave-element']} />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        );
    }

    return (
        <Card variant="outlined">
            <CardContent className={classes['grave-container']}>
                {graveUnitId?.error && <Alert severity="error" id="graveUnitId-helper-text"><Typography>{graveUnitId?.error.value}</Typography></Alert>}
                {graveFormChanged && <Alert severity="warning"><Typography>Die Positionen am Grab wurden geändert, jedoch nicht gespeichert!</Typography></Alert>}
                {unitPositions.map((row, rowIndex) => (
                    <Grid container key={`row-${rowIndex}`} spacing={2} wrap="nowrap">
                        {row.map((el, colIndex) => (el ? (
                            <Grid item key={el.id}>
                                <Button
                                    className={`${classes['grave-element']} ${el.id === graveUnitId?.value ? classes.used : ''}`}
                                    variant={el.id === graveUnitId?.value ? 'contained' : 'outlined'}
                                    onClick={() => onSelect(el)}
                                    disabled={disabled(el)}
                                    data-test={`FormElementGravePositionChooser_${rowIndex}/${colIndex}`}
                                    data-used={el.id === graveUnitId?.value}
                                >
                                    <Typography color="inherit" fontWeight="bold" textAlign="center">{`${el.label}`}</Typography>
                                    <Typography color="inherit" textAlign="center">{`${el.depth} cm`}</Typography>
                                    <Typography color="inherit" textAlign="center">
                                        {`${_.find(graveUnitPositionTypeDataSchema.options, (o) => o.value === el.type)?.de ?? 'unbekannt'}`}
                                    </Typography>
                                </Button>
                            </Grid>
                        ) : null))}
                    </Grid>
                ))}
            </CardContent>
        </Card>
    );
}
