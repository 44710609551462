import React, {useCallback, useMemo, useState} from 'react';
import _ from 'lodash';
import {FormContext, FormWrapper} from 'components/Form/FormWrapper';
import {FormElementLoadingButton} from 'components/Form/FormElements/FormElementLoadingButton';
import {FormElementDateTimePicker} from 'components/Form/FormElements/FormElementDateTimePicker';
import {Box, Chip, Grid} from '@mui/material';
import {ItemData} from 'components/Form/ItemData';
import {FormReset} from 'components/Form/FormReset';
import {
    AddCircleOutline, Approval, Check, DisabledByDefault, DoDisturb, Refresh,
} from '@mui/icons-material';
import {FormElementActionButton} from 'components/Form/FormElements/FormElementActionButton';
import {useCanAccess} from 'hooks/useCanAccess';
import {createWorkingTimeAbsenceLog, updateWorkingTimeAbsenceLog} from 'graphql/timeBuddy/WorkingTimeLog/mutations';
import {getWorkingTimeLog} from 'graphql/timeBuddy/WorkingTimeLog/queries';
import {FormElementTextField} from 'components/Form/FormElements/FormElementTextField';
import {FormElementContainer} from 'components/Form/FormElements/FormElementContainer';
import {FormElementInfoChips} from 'components/Form/FormElements/FormElementInfoChips';
import {FormElementFilesUpload} from 'components/Form/FormElements/FormElementFilesUpload';
import {useGlobalState} from 'hooks/useGlobalState';
import {FormElementText} from 'components/Form/FormElements/FormElementText';
import {FormElementAutocomplete} from 'components/Form/FormElements/FormElementAutocomplete';
import {listUserOptions} from 'graphql/beyondBuddy/User/queries';
import {absenceKindDataSchema, idByKind} from 'applications/timebuddy/modules/workingtime/forms/workingTimeLog/WorkingTimeLogSchema';
import {absenceSchemas} from 'beyond-validators/timeBuddy/WorkingTimeAbsenceLog';
import {FormElementSwitch} from 'components/Form/FormElements/FormElementSwitch';
import {FormElementDatePicker} from 'components/Form/FormElements/FormElementDatePicker';
import {extendMoment} from 'moment-range';
import momentWithoutRange from 'moment';
import 'moment-timezone';

const tz = 'Europe/Vienna';
const moment = extendMoment(momentWithoutRange);

const statusLabel = {
    OPEN: 'Offen',
    IN_REVIEW: 'Gelesen',
    REJECTED: 'Abgelehnt',
    APPROVED: 'Genehmigt',
    CANCELED: 'Storniert',
    OPEN_FOR_CANCELATION: 'Stornierung angefragt',
};

/** @type {import('components/Form/FormElements/FormElementAutocomplete').DataSchema} */
const userDataSchema = {
    query: listUserOptions,
    queryVariablesMask: {tenantId: true},
    queryVariables: {global: {tenantId: 'tenantId', lineManagerUserId: 'userId'}},
    dataKey: 'id',
    getOptionLabel: (option) => _.compact([option?.contactFirstName, option?.contactLastName]).join(' '),
    getOptionValue: (option) => option?.id,
};

const preProcess = (data) => {
    if (!data.fullDays) {
        return data;
    }
    const processed = {...data};
    if (data.startDateTime) {
        processed.startDateTime = moment(data.startDateTime).tz(tz).startOf('day').toISOString();
        // console.log(moment(data.startDateTime).tz(tz).startOf('day').toISOString());
    }
    if (data.endDateTime) {
        processed.endDateTime = moment(data.endDateTime).tz(tz).endOf('day').toISOString();
    }

    // console.log(data, processed);

    return (processed);
};

/**
 * The WorkingTimeLogFormular for creating and updating a work time log
 * .TimeBuddy.Forms
 * @param {import('applications/configuration').EntityFormularProps} props - props passed to the component
 * @returns {React.ReactElement} The WorkingTimeLogFormular component
 */
function WorkingTimeLogAbsenceFormular({
    id, onSave, initialData: initialDataRoot, readonly, ...rest
}) {
    const {getGlobal} = useGlobalState();

    const [userId, setUserId] = useState(null);
    const isOwn = getGlobal('userId') === userId;
    const createType = id === 'create';
    const {kind} = initialDataRoot;
    /** @type {import('components/Form/form').ItemSaveConfig} */
    const saveConfig = useMemo(() => {
        if (createType) {
            const variables = kind === 'ABSENCE'
                ? {}
                : {global: {userId: 'userId'}};
            return {
                mutation: createWorkingTimeAbsenceLog,
                variables,
                preProcess,
                mask: {
                    startDateTime: true,
                    endDateTime: kind !== 'SICKLEAVE' && kind !== 'ABSENCE',
                    comment: false,
                    attachmentKeys: false,
                    kind: true,
                    userId: true,
                },
            };
        }
        return {
            mutation: updateWorkingTimeAbsenceLog,
            preProcess,
            mask: {
                id: true,
                status: true,
                startDateTime: true,
                endDateTime: kind !== 'SICKLEAVE',
                comment: false,
                attachmentKeys: false,
            },
        };
    }, [createType, kind]);

    /** @type {import('components/Form/form').ItemLoadConfig} */
    const loadConfig = useMemo(() => ({
        query: getWorkingTimeLog,
        variables: {direct: {id}},
        mask: {id: true},
        postProcess: (item) => {
            const startDateTimeDate = moment(new Date(item.startDateTime)).tz(tz);
            const endDateTimeDate = moment(item.endDateTime).tz(tz);
            const fullDays = startDateTimeDate.isSame(startDateTimeDate.clone().startOf('day'))
        && (!item.endDateTime || endDateTimeDate.isSame(endDateTimeDate.clone().endOf('day')));

            if (item && 'kind' in item && item.kind !== kind) {
                // Removing all data in case it is loaded from the wrong source
                return {};
            }
            return {
                fullDays,
                ...item,
                attachmentKeys: 'attachments' in item && item.attachments instanceof Array
                    ? JSON.stringify(item.attachments.map((fileInfo) => fileInfo.key))
                    : '[]',
            };
        },
    }), [id, kind]);

    const initialData = useMemo(() => (createType ? {
        startDateTime: new Date().toISOString(),
        ...initialDataRoot,
        fullDays: true,
    } : undefined), [createType, initialDataRoot]);

    const canCreateWorkingTimeLogs = useCanAccess('createWorkingTimeLog');

    return (
        <ItemData
            initialData={initialData}
            loadConfig={!createType ? loadConfig : undefined}
            saveConfig={saveConfig}
            onChange={useCallback((item) => {
                if (item && 'userId' in item) {
                    setUserId(item.userId);
                }
            }, [setUserId])}
        >
            <FormWrapper
                {...rest}
                readonly={readonly}
                isNewItem={createType}
                validatorSchema={{
                    schema: absenceSchemas[kind],
                    type: (!createType) ? 'update' : 'create',
                }}
                onSaveCallback={(result) => {
                    if (_.isFunction(onSave)) {
                        onSave(result);
                    }
                }}
                messageKey={`WorkingTimeLog_Absence_${!createType ? 'Update' : 'Create'}`}
                context={`WorkingTimeLog_Absence${id}`}
            >
                <FormReset shouldClear id={[kind, id].toString()} />
                <FormElementActionButton
                    routeId={idByKind[kind]}
                    routeParams={{id: 'create'}}
                    portalAnchorSelector="#action-button-frame"
                    disabled={createType || !canCreateWorkingTimeLogs}
                    context={FormContext}
                >
                    <AddCircleOutline />
                </FormElementActionButton>
                <FormElementActionButton
                    reload
                    portalAnchorSelector="#action-button-frame"
                    disabled={createType}
                    context={FormContext}
                >
                    <Refresh />
                </FormElementActionButton>
                <Grid container spacing={2}>
                    {!isOwn && !createType && (
                        <Grid item xs={12}>
                            <FormElementContainer
                                attribute="user"
                                propsMapping={({value}) => {
                                    const userLabel = _.compact([value?.contactFirstName, value?.contactLastName]).join(' ');
                                    return userLabel && ({children: `Antrag von ${userLabel}`});
                                }}
                            >
                                <h3> </h3>
                            </FormElementContainer>
                        </Grid>
                    )}
                    <FormElementInfoChips showReadonly>
                        <FormContext.Consumer>
                            {({get}) => {
                                const status = get('status').value;
                                if (kind === 'SICKLEAVE' || createType) {
                                    // Hide chip in create type forms
                                    return '';
                                }
                                return (
                                    <Chip label={statusLabel[status]} />
                                );
                            }}
                        </FormContext.Consumer>
                    </FormElementInfoChips>
                    <Grid item xs={12} lg={2}>
                        <FormElementContainer
                            attribute="*"
                            propsMapping={({value}) => {
                                const notSickleave = value.kind !== 'SICKLEAVE';
                                const correctState = !(value.status === 'OPEN' || (!isOwn && value.status === 'IN_REVIEW') || value.status === 'CANCELED');
                                const hasApprover = value.approverId && value.approverId !== value.userId;
                                return (notSickleave && correctState && hasApprover) && {disabled: true};
                            }}
                        >
                            <FormElementSwitch label="Ganztag" attribute="fullDays" />
                        </FormElementContainer>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={5}>
                        <FormElementContainer attribute="fullDays" conditionalRender={({value}) => value}>
                            <FormElementContainer
                                attribute="*"
                                propsMapping={({value}) => {
                                    const notSickleave = value.kind !== 'SICKLEAVE';
                                    const correctState = !(value.status === 'OPEN' || (!isOwn && value.status === 'IN_REVIEW') || value.status === 'CANCELED');
                                    const hasApprover = value.approverId && value.approverId !== value.userId;
                                    return (notSickleave && correctState && hasApprover) && {readOnly: true};
                                }}
                            >
                                <FormElementDatePicker
                                    label="Datum Start"
                                    attribute="startDateTime"
                                />
                            </FormElementContainer>
                        </FormElementContainer>
                        <FormElementContainer attribute="fullDays" conditionalRender={({value}) => !value}>
                            <FormElementContainer
                                attribute="*"
                                propsMapping={({value}) => {
                                    const notSickleave = value.kind !== 'SICKLEAVE';
                                    const correctState = !(value.status === 'OPEN' || (!isOwn && value.status === 'IN_REVIEW') || value.status === 'CANCELED');
                                    const hasApprover = value.approverId && value.approverId !== value.userId;
                                    return (notSickleave && correctState && hasApprover) && {readOnly: true};
                                }}
                            >
                                <FormElementDateTimePicker
                                    label="Datum Start"
                                    attribute="startDateTime"
                                    minutesStep={15}
                                />
                            </FormElementContainer>
                        </FormElementContainer>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={5}>
                        <FormElementContainer attribute="fullDays" conditionalRender={({value}) => value}>
                            <FormElementContainer
                                attribute="*"
                                propsMapping={({value}) => {
                                    const notSickleave = value.kind !== 'SICKLEAVE';
                                    const correctState = !(value.status === 'OPEN' || (!isOwn && value.status === 'IN_REVIEW') || value.status === 'CANCELED');
                                    const hasApprover = value.approverId && value.approverId !== value.userId;
                                    return (notSickleave && correctState && hasApprover) && {readOnly: true};
                                }}
                            >
                                <FormElementDatePicker
                                    label="Datum End"
                                    attribute="endDateTime"
                                    minDateAttribute="startDateTime"
                                />
                            </FormElementContainer>
                        </FormElementContainer>
                        <FormElementContainer attribute="fullDays" conditionalRender={({value}) => !value}>
                            <FormElementContainer
                                attribute="*"
                                propsMapping={({value}) => {
                                    const notSickleave = value.kind !== 'SICKLEAVE';
                                    const correctState = !(value.status === 'OPEN' || (!isOwn && value.status === 'IN_REVIEW') || value.status === 'CANCELED');
                                    const hasApprover = value.approverId && value.approverId !== value.userId;
                                    return (notSickleave && correctState && hasApprover) && {readOnly: true};
                                }}
                            >
                                <FormElementDateTimePicker
                                    label="Datum End"
                                    attribute="endDateTime"
                                    minTimeAttribute="startDateTime"
                                    minutesStep={15}
                                />
                            </FormElementContainer>
                        </FormElementContainer>
                    </Grid>
                    <Grid item xs={12}>
                        <FormElementContainer
                            attribute="*"
                            propsMapping={({value}) => {
                                const notSickleave = value.kind !== 'SICKLEAVE';
                                const correctState = !(value.status === 'OPEN' || (!isOwn && value.status === 'IN_REVIEW') || value.status === 'CANCELED');
                                const hasApprover = value.approverId && value.approverId !== value.userId;
                                return (notSickleave && correctState && hasApprover) && {readOnly: true};
                            }}
                        >
                            <FormElementTextField rows={3} attribute="comment" label="Kommentar" />
                        </FormElementContainer>
                    </Grid>
                    {kind === 'SICKLEAVE' && (
                        <Grid item xs={12}>
                            <FormElementFilesUpload />
                        </Grid>
                    )}
                    {kind === 'ABSENCE' && createType && (
                        <>
                            <Grid item xs={12}>
                                <FormElementAutocomplete optionReference="user" readOnly={!createType} dataSchema={userDataSchema} attribute="userId" label="Benutzer" />
                            </Grid>
                            <Grid item xs={12}>
                                <FormElementAutocomplete attribute="kind" dataSchema={absenceKindDataSchema} />
                            </Grid>
                        </>
                    )}
                    <FormContext.Consumer>
                        {({get}) => {
                            const {value: status} = get('status');
                            const {value: judgable} = get('subGrants.judgable');
                            const approved = status === 'APPROVED';
                            let showSave = approved && (kind === 'SICKLEAVE' || !isOwn || judgable);
                            showSave ||= !status || status === 'OPEN' || !isOwn;
                            return (
                                <>
                                    {showSave && (!status || kind === 'SICKLEAVE') && (
                                        <Grid item xs={3}>
                                            <FormElementLoadingButton
                                                // startIcon={<Approval />}
                                                label="Speichern"
                                            />
                                        </Grid>
                                    )}
                                    {showSave && (status === 'OPEN' || (!isOwn && status === 'IN_REVIEW')) && (
                                        <Grid item xs={3}>
                                            <FormElementLoadingButton
                                                draft
                                                startIcon={<Approval />}
                                                label="Änderung nachreichen"
                                                color="success"
                                            />
                                        </Grid>
                                    )}
                                    {!showSave && (status === 'CANCELED' || status === 'REJECTED') && (
                                        <Grid item xs={3}>
                                            <FormElementLoadingButton
                                                draft
                                                startIcon={<Approval />}
                                                label="erneut einreichen"
                                                saveVariables={{status: 'OPEN'}}
                                                color="success"
                                            />
                                        </Grid>
                                    )}
                                    {isOwn && kind !== 'SICKLEAVE' && status && status !== 'CANCELED' && status !== 'OPEN_FOR_CANCELATION' && status !== 'REJECTED' && (
                                        <Grid item xs={3}>
                                            <FormElementLoadingButton
                                                draft
                                                startIcon={<DoDisturb />}
                                                label="Stornierung anfragen"
                                                saveVariables={{status: 'OPEN_FOR_CANCELATION'}}
                                                color="info"
                                            />
                                        </Grid>
                                    )}
                                </>
                            );
                        }}
                    </FormContext.Consumer>
                </Grid>
                {/* Conditional render over comparison of two attributes, requires passing conditional render via propsMapping */}
                <FormElementContainer
                    attribute="*"
                    conditionalRender={({value}) => value.kind !== 'SICKLEAVE' && value.status !== 'APPROVED' && value.approverId && value.approverId !== value.userId}
                >
                    {/* // conditionalRender={({value}) => value.status !== 'APPROVED' && value.approverId && value.approverId !== value.userId} */}
                    <hr style={{marginTop: '1rem'}} />
                    <Box margin="1rem">
                            Benötigt Genehmigung durch
                        {' '}
                        <FormElementText attribute="approver.contactFirstName" />
                        {' '}
                        <FormElementText attribute="approver.contactLastName" />
                        {' ('}
                        <FormElementText attribute="approver.contactEMailAddress" />
                            )
                    </Box>
                </FormElementContainer>
                <FormElementContainer attribute="subGrants.judgable" conditionalRender={({value}) => value}>
                    <hr style={{margin: '1rem 0'}} />
                    <Grid container spacing="1rem">
                        <FormElementContainer attribute="status" conditionalRender={({value}) => value !== 'APPROVED'}>
                            <Grid item xs={6}>
                                <FormElementContainer
                                    attribute="status"
                                    propsMapping={({value}) => {
                                        switch (value) {
                                        case 'OPEN_FOR_CANCELATION': return {label: 'Stornierung Bestätigen', saveVariables: {status: 'CANCELED'}};
                                        case 'OPEN': return {label: 'Zur Prüfung', saveVariables: {status: 'IN_REVIEW'}};
                                        default: return {label: 'Genehmigen', saveVariables: {status: 'APPROVED'}};
                                        }
                                    }}
                                >
                                    <FormElementLoadingButton startIcon={<Check />} color="success" />
                                </FormElementContainer>
                            </Grid>
                        </FormElementContainer>
                        <FormContext.Consumer>
                            {({get}) => {
                                const {value: status} = get('status');
                                const {value: approverId} = get('approver.id');
                                const {value: entryUserId} = get('userId');
                                if ((status === 'OPEN' || status === 'IN_REVIEW' || status === 'OPEN_FOR_CANCELATION') && approverId !== entryUserId) {
                                    return (
                                        <Grid item xs={6}>
                                            <FormElementContainer
                                                attribute="status"
                                                propsMapping={({value}) => (value === 'OPEN_FOR_CANCELATION'
                                                    ? {label: 'Ablehnen und genehmigt lassen', saveVariables: {status: 'APPROVED'}}
                                                    : {label: 'Ablehnen', saveVariables: {status: 'REJECTED'}})}
                                            >
                                                <FormElementLoadingButton startIcon={<DisabledByDefault />} color="info" label="Ablehnen" saveVariables={{status: 'REJECTED'}} />
                                            </FormElementContainer>
                                        </Grid>
                                    );
                                }
                                return '';
                            }}
                        </FormContext.Consumer>
                    </Grid>
                </FormElementContainer>
            </FormWrapper>
        </ItemData>
    );
}

export {WorkingTimeLogAbsenceFormular};
