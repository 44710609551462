import {listUserOptions} from 'graphql/beyondBuddy/User/queries';
import {listCemeteriesOptions} from 'graphql/peaceBuddy/Cemetery/queries';
import _ from 'lodash';

/**
 * @typedef {import('components/Form/FormElements/FormElementAutocomplete').DataSchema} AutoCompleteDataSchema
 * @typedef {AutoCompleteDataSchema & {options: (Pick<AutoCompleteDataSchema, "options"> & {divisionLabel?: string, subDivisionLabel?: string})[]}} GraveTypeDataSchema
 */

/**
 * @type {import('components/FilterElements/FilterElementAutocomplete').DataSchema}
 */
export const cemeteriesDataSchema = {
    query: listCemeteriesOptions,
    queryVariablesMask: {tenantId: true, filter: false},
    queryVariables: {global: {tenantId: 'tenantId'}},
    dataKey: 'id', // needed for merging
    getOptionLabel: (option) => `${option?.nr} ${option?.name}${option?.referenceId ? ` (${option?.referenceId})` : ''}`,
    getOptionValue: (option) => option?.id,
};

// /** @type {GraveKindDataSchema} */
// export const coffinGraveKindDataSchema = {
//     dataKey: 'value',
//     options: [{
//         value: 'SINGLE',
//         de: 'Einzelgrab',
//     }, {
//         value: 'FAMILY',
//         de: 'Familiengrab',
//     }, {
//         value: 'CRYPT',
//         de: 'Gruft',
//     }],
//     getOptionLabel: (option) => option.de,
//     getOptionValue: (option) => option.value,
// };

// /** @type {GraveKindDataSchema} */
// export const urnGraveKindDataSchema = {
//     dataKey: 'value',
//     options: [{
//         value: 'EARTH',
//         de: 'Erdgrab',
//     }, {
//         value: 'WALL',
//         de: 'Wandgrab',
//     }, {
//         value: 'CRYPT',
//         de: 'Gruft',
//     }, {
//         value: 'TREE',
//         de: 'Baum- oder Strauchgrab',
//         divisionLabel: 'Baumkreis',
//         subDivisionLabel: 'Zusatz',
//     }, {
//         value: 'FOREST',
//         de: 'Waldgrab',
//         divisionLabel: 'Baumkreis',
//         subDivisionLabel: 'Zusatz',
//     }, {
//         value: 'GARDEN',
//         de: 'Gartengrab',
//     }, {
//         value: 'ROCK',
//         de: 'Felsengrab',
//     }, {
//         value: 'COLUMBARIUM',
//         de: 'Kolumbarium',
//     }, {
//         value: 'MEADOW',
//         de: 'Wiesengrab',
//     }],
//     getOptionLabel: (option) => option.de,
//     getOptionValue: (option) => option.value,
// };

/**
 * https://www.benu.at/ratgeber/grab/
 * @type {AutoCompleteDataSchema}}
 */
export const graveUnitPositionTypeDataSchema = {
    dataKey: 'value',
    options: [{
        value: 'COFFIN',
        de: 'Sarggrab',
    }, {
        value: 'URN',
        de: 'Urnengrab',
    }, {
        value: 'OTHER',
        de: 'Andere',
    }],
    getOptionLabel: (option) => option.de,
    getOptionValue: (option) => option.value,
};

/**
 * https://www.benu.at/ratgeber/grab/
 * @type {GraveTypeDataSchema}
 */
export const graveTypeDataSchema = {
    dataKey: 'value',
    options: [{
        value: 'EARTH',
        de: 'Erdgrab',
    }, {
        value: 'WALL',
        de: 'Wandgrab',
    }, {
        value: 'CRYPT',
        de: 'Gruft',
    }, {
        value: 'TREE',
        de: 'Baum- oder Strauchgrab',
        divisionLabel: 'Baumkreis',
        subDivisionLabel: 'Zusatz',
    }, {
        value: 'FOREST',
        de: 'Waldgrab',
        divisionLabel: 'Baumkreis',
        subDivisionLabel: 'Zusatz',
    }, {
        value: 'GARDEN',
        de: 'Gartengrab',
    }, {
        value: 'ROCK',
        de: 'Felsengrab',
    }, {
        value: 'COLUMBARIUM',
        de: 'Kolumbarium',
    }, {
        value: 'MEADOW',
        de: 'Wiesengrab',
    }],
    getOptionLabel: (option) => option.de,
    getOptionValue: (option) => option.value,
};

/** @type {import('components/Form/FormElements/FormElementAutocomplete').DataSchema} */
export const userDataSchema = {
    query: listUserOptions,
    queryVariablesMask: {filter: false, tenantId: true},
    queryVariables: {global: {tenantId: 'tenantId'}},
    dataKey: 'id',
    getOptionLabel: (option) => _.compact([option?.contactFirstName, option?.contactLastName]).join(' '),
    getOptionValue: (option) => option?.id,
};
