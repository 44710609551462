import React, {
    useCallback, useMemo, useRef, useState,
} from 'react';
import {LayoutContainer} from 'assets/theme/layout/LayoutContainer/LayoutContainer';
import {WorkingTimeModelFormular} from 'applications/timebuddy/modules/workingtime/forms/workingTimeModel/WorkingTimeModelFormular';
import {WorkingTimeModelAssignmentFormular} from 'applications/timebuddy/modules/workingtime/forms/workingTimeModelAssignment/WorkingTimeModelAssignmentFormular';
import {WorkingTimeModelAssignmentsListFormular} from 'applications/timebuddy/modules/workingtime/forms/workingTimeModelAssignment/WorkingTimeModelAssignmentListFormular';
import {
    generatePath,
    useNavigate,
    useParams,
} from 'react-router-dom';
import {useCurrentRoute} from 'routes';

import {createWorkingTimeModel, createWorkingTimeModelRevision} from 'graphql/timeBuddy/WorkingTimeModel/mutations';
import {getWorkingTimeModel} from 'graphql/timeBuddy/WorkingTimeModel/queries';
import _ from 'lodash';
import {PermissionFormular} from 'applications/beyondbuddy/settings/forms/permissions/PermissionFormular';
import {createPermissionsObject} from 'applications/configs';
import {organizationEntityKeys} from 'applications/beyondbuddy/config';
import {readUpdateDeletePermissionTemplate} from 'components/Form/FormElements/FormElementEntityLinkPermissions';
import {
    Add,
    ArrowLeft,
    BlurOnOutlined, LockOutlined,
} from '@mui/icons-material';
import {TabFormsContainer} from 'components/Form/TabFormsContainer';
import {useCanAccess} from 'hooks/useCanAccess';
import {FormLink} from 'components/Form/FormElements/FormLink';
import {Box, Button} from '@mui/material';

/**
 * @param {Partial<import('applications/configuration').EntityFormularProps>} props the properties
 * @returns {React.ReactElement} the workplace formular
 */
const getWorkingTimeModelFormular = (props) => <WorkingTimeModelFormular {...props} />;

/**
 * @param {Partial<import('applications/configuration').EntityFormularProps>} props the properties
 * @returns {React.ReactElement} the permissions formular
 */
const getPermissionFormular = (props) => (
    <PermissionFormular
        disabled={props.readonly}
        isIncoming
        entityTypeId={`WorkingTimeModel#${props.id}`}
        actionButtonProps={{portalAnchorSelector: `#WorkingTimeModelForm${props.id}action-button-frame-permissions-actions`}}
        entityChooserProps={{
            guideId: '',
            availablePermissions: createPermissionsObject(organizationEntityKeys, ['read', 'updateGroup', 'createWorkingTimeModelAssignment', 'permissionGroup']),
            defaultPermissions: createPermissionsObject(organizationEntityKeys, ['read', 'createWorkingTimeModelAssignment']),
            permissionDependencies: {
                User: readUpdateDeletePermissionTemplate,
                Group: readUpdateDeletePermissionTemplate,
                OrganizationalUnit: readUpdateDeletePermissionTemplate,
                Tenant: readUpdateDeletePermissionTemplate,
            },
        }}
        {...props}
    />
);

const preProcess = (value) => {
    let returnVal = value;
    if (!value.partTime) {
        returnVal = _.omit(returnVal, 'agreedWorkingHours');
    }
    if (!value.flexibleTime) {
        returnVal = _.omit(returnVal, 'fixedWorkingHours');
    }
    return returnVal;
};
/** @type {import('components/Form/form').ItemSaveConfig} */
const createConfig = {
    mutation: createWorkingTimeModel,
    preProcess,
    mask: {
        name: true,
        templateId: false,
        normalWorkingHours: true,
        agreedWorkingHours: false,
        fixedWorkingHours: false,
        workHoursMultiplication: false,
        info: false,
        weeklyHours: false,
        weeklyDays: false,
        vacationClaimWeeks: true,
        dailyHours: true,
    },
};
/** @type {import('components/Form/form').ItemSaveConfig} */
const createRevisionConfig = {
    mutation: createWorkingTimeModelRevision,
    preProcess,
    mask: {
        name: true,
        templateId: true,
        crossRevId: true,
        revision: true,
        normalWorkingHours: true,
        agreedWorkingHours: false,
        fixedWorkingHours: false,
        workHoursMultiplication: false,
        info: false,
        weeklyHours: false,
        weeklyDays: false,
        vacationClaimWeeks: true,
        dailyHours: true,
    },
};

/**
 * This page shows a create and update form for a workplace.
 * Also other information as authorized customers, links and permissions are available.
 * @returns {React.ReactElement} The CemeteryPage component.
 */
function WorkingTimeModelPage() {
    const {id} = useParams();
    const isNewItem = id === 'create';
    const navigate = useNavigate();
    const [currentTab, setCurrentTab] = useState(0);
    const [modelAssignmentId, setModelAssignmentId] = useState('');
    const proRef = useRef();

    const canCreateWorkingTimeModelAssignments = useCanAccess('createWorkingTimeModelAssignment', 'readUser');
    const canCreate = useCanAccess('createWorkingTimeModel');

    /** @type {import('routeinfo').RoutePathInfo} */
    const route = useCurrentRoute();

    const onSave = useCallback((result) => {
        if (id === 'create' && result?.id) {
            navigate(`/${generatePath(route.path, {id: result?.id})}`);
        }
    }, [id, route, navigate]);

    /** @type {import('components/Form/form').ItemLoadConfig} */
    const loadConfig = useMemo(() => ({
        query: getWorkingTimeModel,
        postProcess: (data) => ({
            ...data,
            partTime: Boolean(_.get(data, 'agreedWorkingHours')),
            flexibleTime: Boolean(_.get(data, 'fixedWorkingHours')),
        }),
        variables: {direct: {id}},
        mask: {id: true},
    }), [id]);

    /** @type {import('components/Form/form').ItemSaveConfig} */
    const saveConfig = useMemo(() => (isNewItem ? createConfig : createRevisionConfig), [isNewItem]);

    const getWorkingTimeModelAssignmentFormular = useCallback((assignmentReadable) => (
        <>
            <Box style={{display: modelAssignmentId !== '' ? 'none' : 'inherit'}}>
                <Box display="flex" justifyContent="space-between">
                    {/* Span to avoid the actionbutton frame shifting to the right to take the buttons place */}
                    <span>
                        {canCreateWorkingTimeModelAssignments && (
                            <Button
                                data-test={`workingtime_model_${id}_create_assignment`}
                                startIcon={<Add />}
                                onClick={() => setModelAssignmentId('create')}
                            >
                            Neue Zuweisung Tätigen
                            </Button>
                        )}
                    </span>
                    <span style={{display: 'flex', gap: '1rem', marginRight: '1rem'}}>
                        {/* <QuickGuideLink id="driveBuddy_vehicle_protocolEntry" /> */}
                        <span id="workingtime-model-actionbutton-frame" style={{display: 'contents'}} />
                    </span>
                </Box>
                {assignmentReadable && (
                    <WorkingTimeModelAssignmentsListFormular
                        modelId={id}
                        listDataRef={proRef}
                    />
                )}
            </Box>
            {modelAssignmentId !== '' && (
                <Box>
                    <WorkingTimeModelAssignmentFormular
                        modelId={id}
                        id={modelAssignmentId}
                        onSave={(item) => {
                            if (item?.id) {
                                setModelAssignmentId(item.id);
                            }
                        }}
                        controls={(
                            <FormLink
                                data-test={`working_time_model_${id}_list_assignments`}
                                startIcon={<ArrowLeft />}
                                onClick={() => {
                                    setModelAssignmentId('');
                                    // @ts-ignore
                                    proRef.current?.reload?.();
                                }}
                            >
                                Zur Übersicht
                            </FormLink>
                        )}
                    />
                </Box>
            ) }
        </>
    ), [id, modelAssignmentId, canCreateWorkingTimeModelAssignments, proRef.current, setModelAssignmentId]);

    // eslint-disable-next-line function-paren-newline
    const tabFormsContainerProps = useMemo(
        /** @returns {import('components/Form/form').TabFormsContainerProps} container properties */
        () => ({
            identifier: `WorkingTimeModelForm${id}`,
            itemDataProps: {
                ...(!isNewItem) ? {loadConfig} : {},
                ...(canCreate ? {saveConfig} : {undefined}),
            },
            tabs: [{
                id: 'base',
                label: 'Basisdaten',
                icon: <BlurOnOutlined />,
                getChildren: () => getWorkingTimeModelFormular({id, onSave}),
                formWrapperId: `WorkingTimeModel#${id}Base`,
            }, {
                id: 'assignments',
                label: 'Zuweisungen',
                icon: <BlurOnOutlined />,
                enabledCondition: 'assignmentReadable',
                getChildren: (grants, subGrants) => !isNewItem && getWorkingTimeModelAssignmentFormular(subGrants?.assignmentReadable),
                formWrapperId: `WorkingTimeModel#${id}Base`,
            }, {
                id: 'permissions',
                label: 'Berechtigungen',
                icon: <LockOutlined />,
                enabledCondition: 'permissionsReadable',
                // !isNewItem is important, instead it will be some error shown when switching from a list view (e.g. permissions tab) to create
                getChildren: (grants) => !isNewItem && getPermissionFormular({id, readonly: !grants.permissionsUpdatable}),
                formWrapperId: `WorkingTimeModel#${id}Permissions`,
                quickGuideId: 'beyondbuddy_general_permissions',
            },
            ],
            currentTab,
            setCurrentTab,
        }), [id, isNewItem, loadConfig, saveConfig, currentTab,
            getWorkingTimeModelAssignmentFormular, getPermissionFormular, setCurrentTab, onSave]);

    return (
        <LayoutContainer>
            <TabFormsContainer {...tabFormsContainerProps} />
        </LayoutContainer>
    );
}
export {WorkingTimeModelPage};
